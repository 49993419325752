// @flow
import theme from 'global/theme';
import {NavLink} from 'react-router-dom';
import styled, {css} from 'styled-components';

const sharedStyle = css`
  font-size: 1.6rem;
  color: ${theme.color.primary};
  cursor: pointer;
  ${p =>
    p.underline &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const StyledHref = styled.a`
  ${sharedStyle};
`;

export const StyledNavLink = styled(NavLink)`
  ${sharedStyle};
`;
