// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const CenterWrap = styled.div`
  display: flex;
  margin: auto;
  width: 20rem;
  border-top: 0.4rem solid ${theme.color.primary};

  ${mqMax.tabletWide} {
    width: auto;
  }
`;

export const ImageWrap = styled.div`
  padding: 2rem;
  width: 50%;
  background: ${theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopWrap = styled.div`
  display: flex;
`;

export const BottomWrap = styled.div`
  padding: 1rem;
`;

export const Half = styled.div`
  width: 50%;
  padding: 4rem;
  display: flex;
  flex-direction: column;

  ${p =>
    p.white &&
    css`
      background: ${theme.color.white};

      &::after {
        position: fixed;
        left: 0;
        top: -20rem;
        height: 150vh;
        width: 100vw;
        background: #fff;
        content: '';
        z-index: -1;
      }
    `}
`;

export const StyledImage = styled.img`
  display: flex;
  max-width: 55%;
  max-height: 55%;
  margin: auto;
`;
