// @flow
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div`
  width: calc(100% / 2 - 1rem);
  margin-bottom: 0;
`;
