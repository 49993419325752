// @flow
import {path} from 'ramda';

import type {GraphqlError} from '../types';
import errorMap from './errorMap';

const getErrorMessage = (
  {graphQLErrors = []}: GraphqlError,
  fallBackErrorMessage?: string = 'Unknown error occured'
) => {
  const error = graphQLErrors.find(x => {
    const code = path(['data', 'code'], x);

    return code ? !!errorMap[code] : false;
  });

  if (error) {
    return errorMap[error.data.code];
  }

  return path(['0', 'message'], graphQLErrors) || fallBackErrorMessage;
};

export default getErrorMessage;
