// @flow
import {FieldTitle, Title} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const DateTileWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 12.5rem;
  flex-shrink: 0;
`;

export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Month = styled(FieldTitle)`
  margin-top: -1.2rem;
`;

export const Day = styled(Title)`
  font-size: 2.6rem;
`;
