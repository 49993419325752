// @flow
import * as React from 'react';

import DesktopLoggedOutNav from './DesktopLoggedOutNav';

const LoggedOutNav = () => (
  <React.Fragment>
    <DesktopLoggedOutNav />
  </React.Fragment>
);

export default LoggedOutNav;
