// @flow
import {onError} from 'apollo-link-error';
import {logout} from 'data/user/actions';
import {selectLoggedIn} from 'data/user/selectors';
import store from 'global/store';
import {path} from 'ramda';

import {purgeEverything} from './index';

const linkErrors = onError(({graphQLErrors, networkError}) => {
  if (networkError) {
    if (selectLoggedIn(store.getState())) {
      store.dispatch(logout());
    } else {
      purgeEverything(false);
    }
  }

  if (graphQLErrors && graphQLErrors.find(x => path(['data', 'code'], x) === 'E_AUTHORIZATION')) {
    store.dispatch(logout());
  }
});

export default linkErrors;
