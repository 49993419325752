// @flow
import Button from 'components/Button/index';
import {Container} from 'componentsStyled/Layout/Containers';
import {H1, Text} from 'componentsStyled/Typography';
import withRouter from 'hoc/withRouter';
import urls from 'pages/urls';
import React from 'react';
import type {HOC} from 'recompose';

const NotFoundPage = ({history}) => (
  <Container center>
    <H1>Sailing uncharted waters?</H1>
    <Text big center>
      This page was not found, never existed or was removed.
      <br />
    </Text>
    <Button onClick={() => history.push(urls.home)}>Return to homepage</Button>
  </Container>
);

const enhancer: HOC<*, {||}> = withRouter;

export default enhancer(NotFoundPage);
