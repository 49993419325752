// @flow
import {packingSlipQuery} from 'data/bookings/graphql/queries';
import {formatDateString} from 'data/units/date/formatters';
import {getAffiliateIdFromAccessToken} from 'global/apolloClient/crypto';
import withQuery from 'hoc/withQuery';
import tableDefinition from 'pages/Booking/PackingSlip/tableDefinition';
import React from 'react';
// $ReactHooks
import {useEffect} from 'react';
import type {HOC} from 'recompose';

import {
  AddressDetails,
  BookingDetailSection,
  ContactDetails,
  Container,
  Content,
  DeliveryDetailSection,
  Label,
  Logo,
  StyledTable,
  Title,
  TitleSection,
} from './styled';

const openPrintDialog = () => window.print();

const PackingSlip = ({data}) => {
  const {
    logo,
    bookingExternalId,
    contactEmail,
    contactPhone,
    dispatchDate,
    additionalContactPhone,
    additionalContactEmail,
    deliveryName,
    deliveryAddress,
    items,
  } = data;

  //There are three scenarios to make sure the print dialog is opened at the right time:
  // 1. logo is a valid image URL => This will be triggered by the onLoad of the Logo component. Doing it earlier
  //    will sometimes result in the image not being available in the print preview.
  // 2. logo is an invalid image URL => This will be triggered by the onError of the Logo component.
  // 3. logo is nully => this useEffect will capture this scenario.
  useEffect(() => {
    if (!logo) {
      openPrintDialog();
    }
  }, [logo]);

  return (
    <Container>
      <Content>
        <TitleSection>
          <Logo onLoad={openPrintDialog} onError={openPrintDialog} src={logo} />
          <Title>Packing slip</Title>
        </TitleSection>
        <BookingDetailSection>
          <Label>Booking code</Label>
          <p>{bookingExternalId}</p>
          <Label>Dispatch date</Label>
          <p>{dispatchDate ? formatDateString(dispatchDate, 'DD MMM YYYY') : '-'}</p>
        </BookingDetailSection>
        <DeliveryDetailSection>
          <AddressDetails>
            <Label>Delivery address</Label>
            <p>{deliveryName}</p>
            <p>{deliveryAddress}</p>
          </AddressDetails>
          <ContactDetails>
            <Label>Contact details</Label>
            <p>{contactEmail}</p>
            <p>{contactPhone}</p>
            {(additionalContactPhone || additionalContactEmail) && (
              <>
                <Label>Additional contact</Label>
                {additionalContactEmail && <p>{additionalContactEmail}</p>}
                {additionalContactPhone && <p>{additionalContactPhone}</p>}
              </>
            )}
          </ContactDetails>
        </DeliveryDetailSection>
        {!!(items && items.length) && (
          <StyledTable tableDefinition={tableDefinition} data={items} />
        )}
      </Content>
    </Container>
  );
};

type Outter = {|
  match: any,
|};

const enhancer: HOC<*, Outter> = withQuery(packingSlipQuery, {
  variables: ({match}) => ({
    bookingId: match.params.id,
    affiliateId: getAffiliateIdFromAccessToken(),
  }),
});

export default enhancer(PackingSlip);
