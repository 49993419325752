// @flow
import type {ProductItemStatus} from 'data/product/types';
import theme from 'global/theme';

// checks the status of a product
// returns 4 possible states and colors assigned to them
export const getStatus = (status: ProductItemStatus) => {
  switch (status) {
    case 'online':
      return {
        status: 'In stock',
        color: theme.color.positive,
      };

    case 'pre_processing':
      return {
        status: 'In use - Pre-processing',
        color: theme.color.neutralDark,
      };

    case 'outbound':
      return {
        status: 'In use - Outbound',
        color: theme.color.neutralDark,
      };

    case 'checked_out':
      return {
        status: 'In use - Rented',
        color: theme.color.positive,
      };

    case 'overdue':
      return {
        status: 'In use - Overdue',
        color: theme.color.negative,
      };

    case 'inbound':
      return {
        status: 'In use - Inbound',
        color: theme.color.neutralDark,
      };

    case 'delayed':
      return {
        status: 'In use - Delayed',
        color: theme.color.negative,
      };

    case 'post_processing':
      return {
        status: 'In use - Post-processing',
        color: theme.color.neutralDark,
      };

    default:
      return {
        status: 'Unknown',
        color: theme.color.darkGrey,
      };
  }
};
