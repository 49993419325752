// @flow
import {mq, mqMax} from 'common/mediaQuery';
import {Text} from 'componentsStyled/Typography/Texts';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const TileWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  height: 36rem;
  width: 36rem;
  outline: 0.1rem solid ${theme.color.ultralight};
  background: ${theme.color.white};
  transition: box-shadow 0.25s ease, transform 0.25s ease;

  ${mq.tabletWide} {
    &:hover {
      transform: translateY(-0.9rem);
      box-shadow: 0 3rem 6rem 0 ${theme.lighten(theme.color.black, 0.2)};
      position: relative;
      z-index: ${theme.zIndices.minimal};
    }
  }

  ${mq.tablet} {
    align-items: center;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  ${mqMax.tabletWide} {
    h4 {
      margin-bottom: -1rem;
      font-size: 1.25rem;
    }
  }
  width: 100%;
`;

export const Body = styled.div`
  padding: 0 1.6rem 9rem 1.6rem;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${p => `${p.width || 50}%`};
  height: 100%;
  padding-top: 1.25rem;
  margin: 0.1rem;
  position: relative;
`;

export const Col = styled.div`
  width: 100%;
  margin-top: ${p => `${p.marginTop || 0}rem`};
`;

export const Hr = styled.div`
  width: 100%;

  ${p =>
    p.top &&
    css`
      margin-top: 0.5rem;
      border-top: ${p.border && `1px solid ${theme.color.ultralight}`};
    `}

  ${p =>
    p.bottom &&
    css`
      margin-top: 0.5rem;
      border-bottom: ${p.border && `1px solid ${theme.color.ultralight}`};
    `}
`;

export const ImageWrap = styled.div`
  display: flex;
  background: ${theme.color.white};
  justify-content: center;
  flex-direction: column;
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  flex-shrink: 1;
  img {
    max-height: 19rem;
    max-width: 100%;
    top: 1rem;
    text-align: center;
  }
`;

export const AccessoriesWrap = styled.div`
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
  flex-shrink: 1;
  padding: 0.1rem 0;
  margin-top: 0.7rem;
  border-top: 1px solid ${theme.color.lightGrey};
  border-radius: 10px;

  img {
    flex: 1 0 1;
    flex-shrink: 2;
    width: ${p => (p.smallIcons ? `2.8rem` : `3.8rem`)};
    height: ${p => (p.smallIcons ? `2.8rem` : `3.8rem`)};
    padding: ${p => !p.smallIcons && `0.2rem`};
  }
`;

export const DimensionsWrap = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  right: 0.25rem;
  bottom: 4.5rem;
`;

export const StatusWrap = styled.div`
  background: ${p => theme.lighten(p.color, 0.1)};
  color: ${p => p.color};
  font-size: 1.2rem;
  border-radius: 0.2rem;
  padding: 0.5rem 0.8rem;
  margin-bottom: 3rem;

  ${p =>
    p.small &&
    css`
      padding: 0.3rem 0.8rem;
      font-size: 1.18rem;
      margin-bottom: 0;
      margin-left: 0.9rem;
    `}
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 0.3rem;
  line-height: 2rem;
`;

export const StatusLabel = styled.div`
  display: flex;
  margin-top: 1.6rem;
  gap: 0.8rem;

  ${StatusWrap} {
    margin: 0;
  }
`;
