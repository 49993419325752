// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const StyledSwitch = styled.div`
  display: inline-block;
  width: 6rem;
  height: 3rem;
  background: ${theme.color.lightGrey};
  position: relative;
  transition: background 0.25s ease;
  cursor: pointer;
  border-radius: 16px;

  ${props =>
    !!props.value &&
    css`
      background: ${theme.color.primary};
    `};

  &::after {
    content: '';
    height: 2rem;
    width: 2rem;
    background: ${theme.color.white};
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    transition: transform 0.25s ease;
    border-radius: 16px;

    ${props =>
      !!props.value &&
      css`
        transform: translateX(3rem);
      `};
  }

  ${props =>
    props.isSmall &&
    css`
      width: 4rem;
      height: 2rem;

      &::after {
        height: 1.6rem;
        width: 1.6rem;
        left: 0.2rem;
        top: 0.2rem;

        ${props =>
          !!props.value &&
          css`
            transform: translateX(2rem);
          `};
      }
    `};

  // do not used &:disabled as this & is a div
  &[disabled] {
    pointer-events: none;
    &:after {
      background-color: ${theme.color.darkGrey};
    }
  }
`;

export const StyledSwitchWrap = styled.div`
  display: flex;
  align-items: center;
`;
