// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const StyledSelect = styled.select`
  height: 5rem;
  border: solid 0.2rem ${theme.color.lightGrey};
  color: ${theme.color.darkGrey};
  border-radius: 0.4rem;
  background: ${theme.color.white};
  padding: 0 1.9rem;
  font-size: 1.6rem;
  font-weight: ${theme.fontWeights.book};
  width: 100%;
  outline: none;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;

  ${p =>
    p.empty &&
    css`
      color: ${theme.lighten(theme.color.darkGrey, 0.5)};
    `}
  &::-ms-expand {
    display: none;
  }

  &::-moz-focus-inner,
  &:-moz-focusring {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    background-image: none !important;
  }

  &:focus {
    border-color: ${theme.color.primary};
    z-index: ${theme.zIndices.minimal};
  }
`;

export const Option = styled.option`
  color: ${theme.color.darkGrey};
`;

export const EmptyOption = styled.option`
  color: ${theme.lighten(theme.color.darkGrey, 0.5)};
`;
