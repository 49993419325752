// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import theme from 'global/theme';
import styled from 'styled-components';

export const TextWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  & > div:first-child {
    margin-bottom: 24px;
  }
`;

export const Error = styled(Text)`
  font-size: 1.8rem;
  background: #FFF5F5;
  padding: 8px 13px
  color: ${theme.color.error};
`;
