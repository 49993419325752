// @flow
import {momentFromDateString} from 'data/units/date/helpers';
import type {DateString} from 'data/units/date/types';
import moment from 'moment';
import {path} from 'ramda';

export const isActive = (
  day: moment$Moment,
  range: {
    startDate: ?DateString,
    endDate: ?DateString,
  }
) => {
  if (!range || !range.startDate) return false;

  if (range.startDate && !range.endDate) {
    return momentFromDateString(range.startDate).isSame(day, 'day');
  }

  const startDate = momentFromDateString(range.startDate);

  if (!path(['endDate'], range)) return;
  const endDate = momentFromDateString(range.endDate);

  if (moment.utc(day).isBetween(moment.utc(startDate), moment.utc(endDate), 'day', '[]')) {
    return true;
  }

  return false;
};
