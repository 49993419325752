// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const TabSwitch = styled.div`
  display: flex;
  margin-top: 2.5rem;

  ${p =>
    p.centerSwitch &&
    css`
      justify-content: center;
    `}
`;

export const TabSwitchLink = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 2.5rem;
  font-size: 1.4rem;
  color: ${theme.color.darkGrey};
  font-weight: ${theme.fontWeights.book};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    left: 0;
    right: 0;
    bottom: -0.3rem;
    height: 0.2rem;
    background: ${theme.color.primary};
    transition: transform 0.25s ease;
    transform: scaleX(0);
    position: absolute;
  }

  ${p =>
    p.active &&
    css`
      color: ${theme.color.black};
      cursor: default;

      &::after {
        transform: scaleX(1);
      }
    `}

  ${mqMax.tablet} {
    font-size: 1.1rem;
  }
`;
