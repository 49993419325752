// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {Text} from 'componentsStyled/Typography/Texts';
import withOnMount from 'hoc/withOnMount';
import * as React from 'react';
import Collapse from 'react-collapse';
import {type HOC, compose, withStateHandlers} from 'recompose';

import {SubGroupWrap} from './styled';

// Like Collapsible Panel, however it is styled to better suit its application
const CollapsibleParagraph = ({
  group,
  toggleOpen,
  isOpened,
  children,
  maxHeight,
  showExpandText = true,
}) => {
  const rotationCondition = isOpened ? 'up' : undefined;

  const style = maxHeight ? {overflowY: 'scroll', maxHeight} : null;
  return (
    <React.Fragment>
      <SubGroupWrap onClick={toggleOpen}>
        <Text uppercase bold black>
          {group}
        </Text>
        <Text uppercase>
          {showExpandText && `${isOpened ? 'click to collapse' : 'click to expand'}`}
          <ArrowIcon marginLeft floatRight rotation={rotationCondition} />
        </Text>
      </SubGroupWrap>
      <Collapse style={style} isOpened={isOpened}>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

type Outter = {|
  children: React.Node,
  group: string,
  center?: boolean,
  open?: boolean,
  maxHeight?: string,
  showExpandText?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(() => ({isOpened: false}), {
    toggleOpen: props => () => ({isOpened: !props.isOpened}),
  }),
  //We should just be initialising isOpened to the value of props.open but there's a
  //weird bug with react-collapse that throws an error when setting overflowY style if
  //it is initially open
  withOnMount(props => {
    if (props.open) props.toggleOpen();
  })
);

export default enhancer(CollapsibleParagraph);
