// @flow
import {selectLoggedIn} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import urls from 'pages/urls';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import type {Component, HOC} from 'recompose';

const LoggedOutRoute = ({component, loggedIn, ...rest}) =>
  loggedIn ? <Redirect to={urls.upcoming} /> : <Route {...rest} component={component} />;

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

type Outter = {|
  component: Component<any>,
  path: string,
  exact?: boolean,
|};

const enhancer: HOC<*, Outter> = withConnect(mapStateToProps);

export default enhancer(LoggedOutRoute);
