// @flow
import {mq} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const Wrap = styled.div`
  border-top: 0.1rem solid ${theme.color.ultralight};
  padding: 2.4rem;
  background: ${theme.color.white};
  margin-bottom: 1rem;

  ${p =>
    p.stack &&
    css`
      margin-bottom: 0;
      border-top: 0;
    `}

  ${p =>
    !p.noBorder &&
    css`
      border-bottom: 0.1rem solid ${theme.color.ultralight};
    `}
`;

export const WrapNoDesktop = styled(Wrap)`
  ${mq.tabletWide} {
    padding: 0;
    border: 0;
  }
`;
