// @flow
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import CheckIcon from './CheckIcon';
import {CheckboxWrap} from './styled';

const Checkbox = ({value, handleChange, ...rest}) => (
  <CheckboxWrap checked={!!value} {...rest} onClick={handleChange}>
    {value && <CheckIcon />}
  </CheckboxWrap>
);

type Enhancer = {
  value: boolean,
  onChange: Function,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleChange: props => () => props.onChange(!props.value),
});

export default enhancer(Checkbox);
