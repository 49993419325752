// @flow
import {mqMax} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  outline: none;

  ${p =>
    !p.singleMonth &&
    css`
      min-width: 57.2rem;
    `}
  ${mqMax.tabletWide} {
    max-height: 50rem;
    position: relative;
    min-width: 100%;
  }
`;

export const DatePickerHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

export const DatePickerMonthsWrap = styled.div`
  display: flex;
  width: 100%;

  ${mqMax.tabletWide} {
    flex-direction: column;
    align-items: center;
  }
`;
