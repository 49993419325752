// @flow
import withLocation from 'hoc/withLocation';
import * as React from 'react';
import {type HOC} from 'recompose';

import HamburgerToggle from './HamburgerToggle';
import OverlayWrapper from './OverlayWrapper';
import Sidebar from './Sidebar';

const Hamburger = ({toggleOpen, close, isOpen}) => (
  <OverlayWrapper isOpen={isOpen} onClose={close}>
    <HamburgerToggle toggle={toggleOpen} />
    <Sidebar isOpen={isOpen} close={close} />
  </OverlayWrapper>
);

type Outter = {|
  isOpen: boolean,
  open: Function,
  close: Function,
  toggleOpen: Function,
|};

const enhancer: HOC<*, Outter> = withLocation(props => props.close());

export default enhancer(Hamburger);
