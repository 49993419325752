// @flow
import React from 'react';

import type {ColumnDef} from '../types';
import {StyledTd} from './styled';

type Props = {
  definition: ColumnDef<*>,
  item: any,
};

const Td = ({definition, item}: Props) => {
  if (definition.type) {
    const Component = definition.type;
    const props = definition.props ? definition.props(item) : {};
    const value = definition.value && definition.value(item);

    return (
      <StyledTd>
        <Component {...props} value={value} />
      </StyledTd>
    );
  }

  return <StyledTd>{definition.value(item)}</StyledTd>;
};

export default Td;
