// @flow
import styled, {css} from 'styled-components';

export const DesktopFilterContent = styled.div`
  padding: 1.6rem;
  ${p =>
    p.noXPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`;
