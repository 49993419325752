// @flow
import DatePicker from 'components/DatePicker';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {filterDates} from 'data/app/actions';
import {formatReadableDateRange} from 'data/units/date/formatters';
import type {DateRangeValue} from 'data/units/date/types';
import React from 'react';
import DesktopFilter from 'searchFilters/Shared/DesktopFilter';
import MobileFilter from 'searchFilters/Shared/MobileFilter';

import {DateFilterWrap} from './styled';

type Props = {|
  selectedDates?: ?DateRangeValue,
  filterDates: typeof filterDates,
|};

const DateFilter = ({selectedDates, filterDates}: Props) => (
  <DateFilterWrap>
    <Desktop>
      <DesktopFilter
        value={selectedDates}
        onChange={filterDates}
        formatter={formatReadableDateRange}
        component={DatePicker}
        title={'Dates'}
        withControls
        closeOnChange={false}
      />
    </Desktop>
    <Mobile>
      <MobileFilter
        value={selectedDates}
        onChange={filterDates}
        formatter={formatReadableDateRange}
        component={DatePicker}
        placeholder="Dates"
      />
    </Mobile>
  </DateFilterWrap>
);

export default DateFilter;
