// @flow
import {Title} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const LogoWrap = styled.div`
  width: 18.5rem;
  height: 6.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
`;

export const RightWrap = styled.div`
  margin-right: 2.4rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

export const AffiliateName = styled(Title)`
  font-size: 2.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
