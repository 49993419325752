// @flow
import ProductVariantNameFormat from 'components/ProductVariantNameFormat';
import ContactInfo from 'components/ReservationInfo/ContactInfo';
import {Br} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import {formatVariantParam} from 'data/product/formatters';
import type {Reservation} from 'data/reservations/types';
import type {Customer} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import {isEmpty, path} from 'ramda';
import React from 'react';

const getCustomer = (c: Customer) =>
  path(['user'], c)
    ? `${c.user.profiles[0].firstName} ${c.user.profiles[0].lastName}`
    : 'No customer data available';

type Props = {
  reservation: Reservation,
  appConfig: ?AppConfig,
};

const ReservationSummary = ({reservation, appConfig}: Props) => {
  const {productVariant} = reservation.productItem;
  // TODO: Handle multiple categories
  const binding = formatVariantParam('binding')(productVariant.parameters);
  const {phoneNumber} = reservation.booking.order.customer.user.profiles[0];
  const {email} = reservation.booking.order.customer.user;

  return (
    <React.Fragment>
      <Text medium>{getCustomer(reservation.booking.order.customer)}</Text>
      <br />
      <ContactInfo phoneNumber={phoneNumber} email={email} />
      <Text>{productVariant.product.manufacturer.name}</Text>
      <Title>{productVariant.product.name}</Title>
      {!isEmpty(binding) && <SubTitle>{binding}</SubTitle>}
      <ProductVariantNameFormat productVariant={productVariant} tile />
      <Br mb={3.5} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

export default withConnect(mapStateToProps)(ReservationSummary);
