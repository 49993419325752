// @flow
import {getSignedUrlQuery} from 'data/storage/queries';
import apolloClient from 'global/apolloClient';
import {path} from 'ramda';

const mimeTypes = {
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpeg',
  'image/png': 'png',
};

export const uploadImage = (file: any, bucket: string): Promise<string> =>
  apolloClient
    .query({
      query: getSignedUrlQuery.gql,
      variables: {
        bucket,
        mimeType: mimeTypes[file.type],
      },
      fetchPolicy: 'network-only',
    })
    .then(res => {
      const url = path(['data'].concat(getSignedUrlQuery.selector), res);
      return fetch(url.signedUrl, {
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
        body: file,
      }).then(() => url.futureUrl);
    });
