// @flow
export const preventDefault = (e: SyntheticInputEvent<*>) => e.preventDefault();

export const stopPropagation = (e: SyntheticInputEvent<*>) => e.stopPropagation();

export const isEvent = (e: SyntheticInputEvent<*>) =>
  Boolean(e && e.stopPropagation && e.preventDefault);

export const isScrollEnd = (e: any) => {
  const tolerance = 10;
  const isEnd = e.scrollHeight - e.scrollTop <= e.clientHeight + tolerance;

  return isEnd;
};
