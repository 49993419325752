// @flow
import urls from 'pages/urls';
import * as React from 'react';

import LogoIcon from './LogoIcon';
import LogoIconFull from './LogoIconFull';
import LogoIconSmall from './LogoIconSmall';
import {StyledLogoLink} from './styled';

type Props = {|
  type: 'small' | 'default' | 'full',
  onClick?: Function,
|};

const LogoLink = ({type, onClick}: Props) => {
  function renderDefaultLogo() {
    if (type === 'small') {
      return <LogoIconSmall />;
    } else if (type === 'default') {
      return <LogoIcon />;
    } else if (type === 'full') {
      return <LogoIconFull />;
    }
  }

  return (
    <StyledLogoLink to={urls.home} onClick={onClick} data-cy="header-home-link">
      {renderDefaultLogo()}
    </StyledLogoLink>
  );
};

export default LogoLink;
