// @flow
import theme from 'global/theme';
import styled from 'styled-components';

export const StyledForm = styled.form`
  .StripeElement {
    height: 5rem;
    border: solid 0.2rem ${theme.color.lightGrey};
    border-radius: 0.4rem;
    background: ${theme.color.white};
    padding: 1.34rem 1.9rem;
    position: relative;
  }
  #cardNumber {
    width: 256px
  }
  #expiry {
    width: 100px
    margin-right: 24px;
  }
  #cvc {
    width: 100px
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 2.4rem;
  display: block;
`;

export const CardNumberWrap = styled.div`
  margin-bottom: 2.4rem;
  display: block;
`;

export const LowerStripeElements = styled.div`
  display: flex;
`;

export const stripeStyle = {
  base: {
    color: theme.color.darkGrey,
    fontWeight: 400,
    fontFamily: 'roboto, sans-serif',
    fontSize: '1rem',

    '::placeholder': {
      color: theme.lighten(theme.color.darkGrey, 0.75),
    },
  },
  invalid: {
    color: theme.color.negative,
  },
};
