// @flow
import {mq} from 'common/mediaQuery';
import styled, {css} from 'styled-components';

export const FullMobileWidthContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 96rem;
  height: 100%;

  ${p =>
    p.center &&
    css`
      text-align: center;
    `}

  ${p =>
    p.narrow &&
    css`
      max-width: 59.2rem !important;
    `}
`;

export const WidthContainer = styled(FullMobileWidthContainer)`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 99.2rem;
`;

export const Container = styled(WidthContainer)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${mq.tabletWide} {
    padding-top: ${p => p.paddingTop || 8}rem;
    padding-bottom: 8rem;
  }
`;

export const FullMobileContainer = styled(FullMobileWidthContainer)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${mq.tabletWide} {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 96rem;

  ${p =>
    p.center &&
    css`
      text-align: center;
    `}

  ${p =>
    p.narrow &&
    css`
      max-width: 59.2rem;
    `}
`;
