// @flow
import React from 'react';
import {type HOC, compose, withHandlers, withProps} from 'recompose';

import type {ColumnDef} from '../types';
import {Indicator, StyledTh} from './styled';

const Th = ({isSortable, column, sortingBy, sortAscending, handleClick}) => (
  <StyledTh sortable={isSortable} onClick={handleClick}>
    {column.title}
    {isSortable && <Indicator desc={!sortAscending} active={sortingBy === column.title} />}
  </StyledTh>
);

type Enhancer = {
  column: ColumnDef<*>,
  sortingBy?: ColumnDef<*>,
  sortAscending: boolean,
  onSort: Function,
};

const enhancer: HOC<*, Enhancer> = compose(
  withProps(props => ({
    isSortable:
      props.column.isSortable != null ? props.column.isSortable : Boolean(props.column.value),
  })),
  withHandlers({
    handleClick: props => () => props.isSortable && props.onSort(props.column),
  })
);

export default enhancer(Th);
