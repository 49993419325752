// @flow
import theme from 'global/theme';
import React from 'react';

import {ArrowWrap} from './styled';

const rotations = {
  left: 90,
  up: 180,
  right: -90,
};

type Props = {
  rotation?: string,
  gray?: boolean,
  marginLeft?: boolean,
  floatRight?: boolean,
};

const ArrowIcon = ({rotation, gray, marginLeft, floatRight}: Props) => {
  return (
    <ArrowWrap
      rotation={rotation && rotations[rotation]}
      marginLeft={marginLeft}
      floatRight={floatRight}
    >
      <svg width="18" height="18">
        <path
          d="M8.839 7.374l5.303-5.303L12.374.303 7.071 5.607 1.768.303 0 2.071l7.071 7.071L8.84 7.374z"
          fill={gray ? theme.color.darkGrey : theme.color.black}
          fillRule="evenodd"
          transform="translate(2, 4)"
        />
      </svg>
    </ArrowWrap>
  );
};

export default ArrowIcon;
