// @flow
import {color} from 'componentsStyled/Typography/enhancers';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const DetailsWrap = styled.div`
  margin: 1rem 0;
  padding: 1rem 0.25rem;
  line-height: 2.4rem;
  text-align: ${p => p.align || 'justify'};

  ${p =>
    p.compact &&
    css`
      margin: 1rem 0 0 0;
      padding: 0.1rem 0.2rem;
      padding-bottom: 0.5rem;
      font-size: 1.3rem;
      line-height: 1.8rem;
    `}
`;

export const Hr = styled.div`
  margin: 0.25rem 0;
  border: 0.5px dotted ${theme.color.lightGrey};
`;

export const TextWrap = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 0 0.5rem;
`;

export const Stats = styled.div`
  font-weight: ${theme.fontWeights.book};
  color: ${theme.color.darkGrey};
  ${color};
`;
