// @flow
import {reservationDetailQuery} from 'data/reservations/graphql/queries';
import withLocation from 'hoc/withLocation';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';
import {type HOC, compose} from 'recompose';

import VerifySetupDetail from './VerifySetupDetail';

const VerifySetup = ({match, data}: any) => (
  <SubPage title="Verify Setup">
    <VerifySetupDetail formData={data.details} id={match.params.id} reservation={data} />
  </SubPage>
);

type Outter = {|
  id: number,
|};

const enhancer: HOC<*, Outter> = compose(
  withLocation(),
  withQuery(reservationDetailQuery, {
    variables: props => ({
      id: props.match.params.id,
    }),
    callOptions: {
      fetchPolicy: 'cache-first',
    },
  })
);

export default enhancer(VerifySetup);
