// @flow
import type {TableDefinition} from 'components/Table/types';
import {prop} from 'ramda';

export type PaySlipRow = {
  assetId: string,
  productName: string,
  variantName: string,
  vendor: string,
  stockLocation: string,
};

export const tableDefinition: TableDefinition<PaySlipRow> = [
  {
    title: 'Asset ID',
    value: prop('assetId'),
    isSortable: false,
  },
  {
    title: 'Product',
    //More defensive approach for styling as '<product name> - <variant name>' in case one
    //of the variables is somehow nully.
    value: data => [data.productName, data.variantName].filter(Boolean).join(' - '),
    isSortable: false,
  },
  {
    title: 'Vendor',
    value: prop('vendor'),
    isSortable: false,
  },
  {
    title: 'Stock Location',
    value: data => data.stockLocation || '-',
    isSortable: false,
  },
];

export default tableDefinition;
