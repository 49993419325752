// @flow
import NotificationIcon from 'assets/icons/NotificationIcon';
import type {Growl} from 'data/growls/types';
import React from 'react';

import {ActionWrap, Divider, GrowlTileWrap, MessageWrap, StyledButton, StyledText} from './styled';

type Props = {|
  growl: Growl,
  closing?: boolean,
  onClick?: Function,
|};

const GrowlTile = ({onClick, closing, growl}: Props) => (
  <GrowlTileWrap closing={closing}>
    <MessageWrap>
      <NotificationIcon />
      <StyledText>{growl.text}</StyledText>
    </MessageWrap>
    <Divider />
    <ActionWrap>
      <StyledButton onClick={onClick}>Go</StyledButton>
    </ActionWrap>
  </GrowlTileWrap>
);

export default GrowlTile;
