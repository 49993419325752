// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import {chargeLateFeeMutation, updateBookingStatusMutation} from 'data/bookings/graphql/mutations';
import {openModal} from 'data/modals/actions';
import {reservationStatuses} from 'data/reservations/constants';
import StripeInitialisation from 'forms/StripeInitialisation';
import withConnect from 'hoc/withConnect';
import {withCustomMutation} from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalHeader from 'modals/_Header';
import SingleButtonModal from 'modals/SingleButton';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose} from 'recompose';

import CardInput from './CardInput';
import {Error, TextWrap} from './styled';

const ChargeFailedModal = ({amount, close, loading, appConfig, submit, errorMessage}) => (
  <ModalBody maxWidth={70}>
    <ModalHeader title={'Charge Failed'} />
    <ModalContent>
      <Error>{errorMessage}</Error>
      <TextWrap>
        <Text black>Unable to charge ${amount}</Text>
        <Text black>Enter an alternative one-off payment, or cancel and try again.</Text>
      </TextWrap>
      <StripeInitialisation>
        <CardInput onSubmit={submit} close={close} />
      </StripeInitialisation>
    </ModalContent>
  </ModalBody>
);

type Outter = {|
  chargeLateFee(any): Promise<void>,
  returnBooking(any): Promise<void>,
  amount: number,
  bookingId: string,
  close: Function,
  returnDate?: string,
  comment?: string,
  errorMessage: string,
|};

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withSubmit({
    submit: props => async token => {
      await props.chargeLateFee({
        bookingId: props.bookingId,
        chargeAmount: props.amount * 100,
        cardToken: token,
      });

      const returnResult = await props.returnBooking({
        bookingId: props.bookingId,
        input: {
          newStatus: reservationStatuses.passed_to_finalization_worker,
          details: {
            comment: props.comment,
            returnDate: props.returnDate,
          },
        },
      });
      props.close();
      await new Promise(resolve => {
        props.openModal(
          SingleButtonModal,
          {
            title: 'Charge Successful',
            buttonText: 'Done',
            onConfirm: () => {
              resolve();
              return Promise.resolve();
            },
            message: `Successfully charged $${props.amount} to card ****`,
          },
          {
            onClose: () => {
              resolve();
            },
          }
        );
      });
      return returnResult;
    },
    redirect: props => urls.returns,
    successText: 'Returned',
    errorText: 'Failed to return, please try again.',
  })
);

export const ChargeFailedModalStory = enhancer(ChargeFailedModal);

export default compose(
  withCustomMutation(updateBookingStatusMutation, 'returnBooking'),
  withCustomMutation(chargeLateFeeMutation, 'chargeLateFee')
)(ChargeFailedModalStory);
