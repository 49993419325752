// @flow
import {uploadImage} from 'common/gcp';
import Button from 'components/Button';
import ToolTip from 'components/ToolTip';
import {reservationStatuses} from 'data/reservations/constants';
import {updateStatusMutation} from 'data/reservations/graphql/mutations';
import type {Reservation, ReservationDetails} from 'data/reservations/types';
import type {AffiliateEmployee} from 'data/user/types';
import SelectFromList from 'forms/Input/Select';
import SignatureBlock from 'forms/Input/SignatureBlock';
import TextInput from 'forms/Input/Text';
import {dinRequired, required} from 'forms/validators/common';
import withForm from 'forms/withForm';
import withAffiliate from 'hoc/withAffiliate';
import withEmployees from 'hoc/withEmployees';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import urls from 'pages/urls';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import {dinToolTipText} from './helpers.js';
import InfoHeader from './InfoHeader';
import {Left, SignatureBlockGroupWrap, VerifySetupWrap} from './styled';

//return a promise that resolves with a File instance
function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, {type: mimeType});
    });
}

const SetupVerifyDetail = ({reservation, values, affiliate, employees, loading}) => {
  const employeeList = employees && employees.map(x => ({id: x.id, value: x.code}));

  return (
    <VerifySetupWrap>
      <InfoHeader
        affiliate={affiliate}
        profile={reservation.booking.order.customer.user.profiles[0]}
        productName={reservation.productItem.productVariant.product.name}
        details={reservation.details}
      />
      <Left>
        <TextInput
          name="din"
          type="number"
          label="DIN"
          inputmode="numeric"
          placeholder=""
          errorPosition="absolute"
        />
        <ToolTip header="Possible DIN values: " text={dinToolTipText} top={-11} />
      </Left>
      <SignatureBlockGroupWrap>
        <SignatureBlock
          name="signatureMemberPad"
          label=""
          text={`Awayco Member ${reservation.booking.order.customer.user.profiles[0].firstName} ${reservation.booking.order.customer.user.profiles[0].lastName} Signature`}
        />
        <SignatureBlock
          name="signatureEmployeePad"
          label=""
          text={`${affiliate.name} Employee Signature`}
        />
      </SignatureBlockGroupWrap>
      <SelectFromList name="employeeId" label="Select employee code" list={employeeList} />
      <TextInput name="comment" label="Comment" />
      <Button
        large
        loading={loading}
        disabled={
          !values.employeeId ||
          !values.signatureEmployeePad ||
          !values.signatureMemberPad ||
          !values.din
        }
      >
        CHECK SKIS OUT
      </Button>
    </VerifySetupWrap>
  );
};

const schema = {
  employeeId: required,
  signatureEmployeePad: required,
  signatureMemberPad: required,
  din: dinRequired(0.25, 18),
};

type Outter = {|
  id: number,
  employees?: AffiliateEmployee[],
  formData: ReservationDetails,
  reservation: Reservation,
|};

const enhancer: HOC<*, Outter> = compose(
  withAffiliate(),
  withEmployees(),
  withMutation(updateStatusMutation),
  withSubmit({
    redirect: props => urls.upcoming,
  }),
  withForm({
    schema,
    onSubmit: props => values => {
      props.setLoading(true);

      const id = parseInt(values.employeeId, 10);
      const employee = props.employees && props.employees.find(e => e.id === id);
      const employeeCode = employee && employee.code;

      return Promise.all([
        urltoFile(values.signatureEmployeePad, 'sigempl.png', 'image/png'),
        urltoFile(values.signatureMemberPad, 'sigmem.png', 'image/png'),
      ])
        .then(([employeePadFile, memberPadFile]) =>
          Promise.all([
            uploadImage(employeePadFile, 'signatures'),
            uploadImage(memberPadFile, 'signatures'),
          ])
        )
        .then(([employeeSignatureUri, memberSignatureUri]) =>
          props.submit({
            id: props.id,
            input: {
              newStatus: reservationStatuses.checked_out,
              details: {
                employeeCode,
                employeeSignatureUri,
                memberSignatureUri,
                din: values.din,
                comment: values.comment,
              },
            },
          })
        )
        .catch(error => {
          props.setLoading(false);
          throw new Error(error);
        });
    },
  })
);

export default enhancer(SetupVerifyDetail);
