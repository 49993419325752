// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import withAffliate from 'hoc/withAffiliate';
import * as React from 'react';
import {type HOC} from 'recompose';

import DesktopLoggedInNav from './DesktopLoggedInNav';
import MobileLoggedInNav from './MobileLoggedInNav';

const LoggedInNav = ({affiliate, title}) => (
  <React.Fragment>
    <Mobile>
      <MobileLoggedInNav name={title || affiliate.name} />
    </Mobile>
    <Desktop>
      <DesktopLoggedInNav name={title || affiliate.name} />
    </Desktop>
  </React.Fragment>
);

type Outter = {|
  title?: string,
|};

const enhancer: HOC<*, Outter> = withAffliate();

export default enhancer(LoggedInNav);
