// @flow
import {DetailsWrap, Stats} from 'componentsStyled/Shared/ActivityInfo';
import React from 'react';

type Props = {
  compact?: boolean,
  phoneNumber: ?string,
  email: ?string,
};

const ContactInfo = ({phoneNumber, email, compact}: Props) => (
  <DetailsWrap compact={compact} align={'left'}>
    <Stats black>Email: &nbsp;</Stats>
    <Stats>{email}</Stats>
    {phoneNumber ? <Stats black>Phone: &nbsp;</Stats> : null}
    <Stats>{phoneNumber}</Stats>
  </DetailsWrap>
);

export default ContactInfo;
