// @flow
import ArrowIconSharp from 'assets/icons/ArrowIconSharp';
import Avatar from 'components/Avatar';
import ContextMenu from 'components/ContextMenu';
import OverlayWrapper from 'components/OverlayWrapper';
import UnitSelector from 'components/UnitSelector';
import {logout} from 'data/user/actions';
import {selectUser} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import withOpen from 'hoc/withOpen';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {MenuLink, MenuLinks, Toggle, UnitWrap} from './styled';

const AvatarMenu = ({close, toggleOpen, isOpen, user, logout}) => (
  <OverlayWrapper isOpen={isOpen} close={close}>
    <Toggle open={isOpen} onClick={toggleOpen} isSelected={isOpen}>
      <Avatar />
      <ArrowIconSharp />
    </Toggle>
    <ContextMenu isOpen={isOpen} close={close}>
      <MenuLinks>
        <UnitWrap>
          <UnitSelector group={'Unit'} close={close} />
        </UnitWrap>
        <MenuLink to={urls.customers}>Customers</MenuLink>
        <MenuLink onClick={logout}>Logout</MenuLink>
      </MenuLinks>
    </ContextMenu>
  </OverlayWrapper>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

const enhancer: HOC<*, {}> = compose(withOpen, withConnect(mapStateToProps, {logout}));

export default enhancer(AvatarMenu);
