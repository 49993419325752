// @flow
import BookingItem from 'components/BookingItem';
import Tile from 'components/Tile';
import type {Booking} from 'data/bookings/types';
import * as React from 'react';

type Props = {|
  booking: Booking,
  action: string,
|};

const BookingTile = ({booking}: Props) => (
  <Tile>
    <BookingItem booking={booking} />
  </Tile>
);

export default BookingTile;
