// @flow
import * as React from 'react';

import {ModalContentWrap} from './styled';

type Props = {|
  children: React.Node,
|};

const ModalContent = ({children}: Props) => <ModalContentWrap>{children}</ModalContentWrap>;

export default ModalContent;
