// @flow
import ProductVariantNameFormat from 'components/ProductVariantNameFormat';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import {isSurf} from 'data/app/activityHelpers';
import type {ProductItem} from 'data/product/types';
import React from 'react';

type Props = {|
  productItem: ProductItem,
  center?: boolean,
|};

const Detail = ({productItem, center}: Props) => (
  <React.Fragment>
    <Title>{productItem.productVariant.product.name}</Title>
    <SubTitle>{productItem.productVariant.product.manufacturer.name}</SubTitle>
    <ProductVariantNameFormat
      productVariant={productItem.productVariant}
      center={center}
      compact={!productItem.productVariant.product.categories.some(isSurf)}
    />
    <Text>Asset ID: {productItem.code}</Text>
    <Text>Shelf Location: {productItem.productVariant.shelfLocation}</Text>
  </React.Fragment>
);

export default Detail;
