// @flow
import ReservationInterval from 'components/ReservationInterval';
import {Space} from 'componentsStyled/Layout/Spacers';
import type {Reservation} from 'data/reservations/types';
import React from 'react';

import {CenterWrap} from '../styled';

type Props = {|
  reservation: Reservation,
|};

const ReservationDatesDetail = ({reservation}: Props) => (
  <React.Fragment>
    <Space />
    <CenterWrap />
    <ReservationInterval start={reservation.booking.start} end={reservation.booking.end} />
    <br />
  </React.Fragment>
);

export default ReservationDatesDetail;
