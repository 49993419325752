// @flow
import {damageTypeFragment} from 'data/damages/graphql/fragments';
import {productItemFragment} from 'data/product/graphql/fragments';
import {customerFragment} from 'data/user/graphql/fragments';
import gql from 'graphql-tag';

export const reservationFragment = gql`
  fragment reservationFragment on Reservation {
    id
    status
    externalId
    basketCode
    details {
      comment
    }
    pricingDetail {
      accessories {
        prices
        name
      }
    }
    productItem {
      ...productItemFragment
    }
    booking {
      bookingId
      start
      end
      order {
        orderId
        customer {
          ...customerFragment
        }
      }
    }
  }
  ${productItemFragment}
  ${customerFragment}
`;

export const reservationDetailFragment = gql`
  fragment reservationDetailFragment on Reservation {
    id
    status
    externalId
    basketCode
    booking {
      bookingId
      externalId
      start
      end
      order {
        orderId
        customer {
          ...customerFragment
        }
      }
    }
    details {
      comment
    }
    pricingDetail {
      currency
      totalPrice
      fullPrice
      prices
      accessories {
        prices
        name
      }
      discounts {
        percentage
        type
        detail
      }
      tax
    }
    productItem {
      ...productItemFragment
    }
    damages {
      id
      damageType {
        ...damageTypeFragment
      }
    }
  }
  ${damageTypeFragment}
  ${productItemFragment}
  ${customerFragment}
`;
