// @flow
import BookingPage from 'pages/Booking';
import PackingSlip from 'pages/Booking/PackingSlip';
import CalendarPage from 'pages/CalendarPage';
import CustomersPage from 'pages/Customers';
import DebugPage from 'pages/Debug';
import HomePage from 'pages/Home';
import MasqueradePage from 'pages/Masquerade';
import NotFoundPage from 'pages/NotFound';
import ProductActionPage from 'pages/ProductAction';
import QuiverPage from 'pages/Quiver';
import QuiverDetailPage from 'pages/QuiverDetail';
import ReservationPage from 'pages/Reservation';
import ReturnsPage from 'pages/Returns';
import UpcomingPage from 'pages/Upcoming';
import urls from 'pages/urls';
import VerifySetupPage from 'pages/VerifySetup';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import LoggedInRoute from './LoggedInRoute';
import LoggedOutRoute from './LoggedOutRoute';

const Routes = () => (
  <Switch>
    {/* Routes for logged in and logged out */}
    <Route exact path={urls.debug} component={DebugPage} />
    <Route path={urls.masqueradeClaim(':claimToken')} component={MasqueradePage} />

    {/* Routes for logged logged out only */}
    <LoggedOutRoute exact path={urls.home} component={HomePage} />

    {/* Routes for logged logged in only */}
    <LoggedInRoute path={urls.calendar} component={CalendarPage} />
    <LoggedInRoute path={urls.upcoming} component={UpcomingPage} />
    <LoggedInRoute path={urls.returns} component={ReturnsPage} />
    <LoggedInRoute path={urls.productItem(':id')} component={QuiverDetailPage} />
    <LoggedInRoute path={urls.inventory} component={QuiverPage} />
    <LoggedInRoute path={urls.reservation(':id')} component={ReservationPage} />
    <LoggedInRoute exact path={urls.booking(':id')} component={BookingPage} />
    <LoggedInRoute path={urls.bookingPackingSlip(':id')} component={PackingSlip} />
    <LoggedInRoute path={urls.customers} component={CustomersPage} />
    <LoggedInRoute path={urls.productAction(':id')} component={ProductActionPage} />
    <LoggedInRoute path={urls.verifySetup(':id')} component={VerifySetupPage} />

    {/* Redirects */}
    <Redirect from={urls.productItemBase} to={urls.inventory} />
    <Redirect from={urls.bookingBase} to={urls.upcoming} />

    {/* Catch all redirect */}
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
