// @flow
import type {Query} from 'common/graphql/types';
import gql from 'graphql-tag';

import type {AppConfig} from '../types';

export const appConfigQuery: Query<AppConfig> = {
  gql: gql`
    query appConfigQuery {
      app {
        getConfig {
          tenantName
          tenantTitle
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'network-only',
  }),
  selector: ['app', 'getConfig'],
};
