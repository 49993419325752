// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {Reservation} from 'data/reservations/types';
import React from 'react';

import ReservationCodeDetail from './ReservationCodeDetail';
import ReservationDatesDetail from './ReservationDatesDetail';
import ReservationSummary from './ReservationSummary';
import {BottomWrap, Half, ImageWrap, StyledImage, TopWrap} from './styled';

type Props = {|
  data: Reservation,
|};

const ReservationPreview = ({data}: Props) => {
  const indexImage = selectIndexImage(data.productItem.productVariant.product.images);
  const imageSize = minHeightImg(indexImage, 600);

  return (
    <React.Fragment>
      <Desktop>
        <ImageWrap key={imageSize.url}>
          <StyledImage src={imageSize.url} />
        </ImageWrap>
        <Half white>
          <ReservationSummary reservation={data} />
          <ReservationCodeDetail reservation={data} />
          <ReservationDatesDetail reservation={data} />
        </Half>
      </Desktop>
      <Mobile>
        <TopWrap>
          <ImageWrap key={imageSize.url}>
            <StyledImage src={imageSize.url} />
          </ImageWrap>
          <Half white>
            <ReservationSummary reservation={data} />
            <ReservationDatesDetail reservation={data} />
          </Half>
        </TopWrap>
        <BottomWrap>
          <ReservationCodeDetail reservation={data} />
        </BottomWrap>
      </Mobile>
    </React.Fragment>
  );
};

export default ReservationPreview;
