// @flow
import {notFixed} from 'data/damages/filters';
import type {ProductDamage} from 'data/damages/types';
import {type ID} from 'data/enums/types';
import * as React from 'react';

import DamageTile from './DamageTile';

type Props = {|
  damages: ProductDamage[],
  productId: ID,
|};

const DamageList = ({damages, productId}: Props) =>
  notFixed(damages).map((d, i) => <DamageTile key={i} damage={d} productId={productId} />);

export default DamageList;
