// @flow
import Button from 'components/Button';
import {Wrap} from 'componentsStyled/Layout/Wrap';
import withValue from 'hoc/withValue';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import * as React from 'react';
import {type Component, type HOC, compose, withHandlers} from 'recompose';

const LocalValueModal = ({close, title = 'Filters', component, value, setValue, handleSubmit}) => {
  const C = component;

  return (
    <ModalBody close={close} renderCross={false}>
      <ModalHeader close={close} title={title} clear={() => setValue()} />
      <ModalContent>
        <Wrap noBorder={true}>
          <C value={value} onChange={setValue} />
        </Wrap>
      </ModalContent>
      <ModalControls>
        <Button onClick={handleSubmit} fullwidth>
          Apply
        </Button>
      </ModalControls>
    </ModalBody>
  );
};

type ComponentProps<T> = {|
  onChange: T => mixed,
  value: T,
|};

type Outter<T> = {|
  title: string,
  component: Component<ComponentProps<T>>,
  close: Function,
  initialValue: T,
  onChange: T => mixed,
|};

const enhancer: HOC<*, Outter<*>> = compose(
  withValue,
  withHandlers({
    handleSubmit: props => () => {
      props.onChange(props.value);
      props.close();
    },
  })
);

export default enhancer(LocalValueModal);
