// @flow
import getErrorMessage from 'common/graphql/getErrorMessage';
import {push} from 'connected-react-router';
import {notificationError, notificationSuccess} from 'data/notifications/actions';
import withConnect from 'hoc/withConnect';
import {defaultSubmitName} from 'hoc/withMutation';
import {prop} from 'ramda';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

const mapDispatchToProps = {
  notificationSuccess,
  notificationError,
  push,
};

type Config<Props, Values, Result> = {|
  successText?: string,
  errorText?: string,
  onSuccess?: Props => Result => mixed,
  redirect?: (Props, Result) => string,
  submit?: Props => Values => Promise<Result>,
|};

type Added<Values, Result> = {|
  submit: Values => Promise<Result>,
  loading: boolean,
  setLoading: boolean => mixed,
|};

function withSubmit<Props, Values, Result>(
  c: Config<Props, Values, Result>
  // $ExpectError
): HOC<{...$Exact<Added<Values, Result>>, ...$Exact<Props>}, Props> {
  const {
    successText,
    onSuccess,
    errorText = 'There was a problem processing your request, please try again',
    redirect,
    submit = prop(defaultSubmitName),
  } = c;

  return compose(
    withStateHandlers(
      {
        loading: false,
      },
      {
        setLoading: () => (v: boolean) => ({loading: v}),
      }
    ),
    withConnect(() => ({}), mapDispatchToProps),
    withHandlers({
      submit: props => values => {
        props.setLoading(true);
        return submit(props)(values)
          .then(result => {
            props.setLoading(false);
            if (onSuccess) {
              onSuccess(props)(result);
            }
            if (successText) {
              props.notificationSuccess(successText);
            }
            if (redirect) {
              const url = redirect(props, result);
              props.push(url);
            }
          })
          .catch(e => {
            props.setLoading(false);
            const errorMessage = getErrorMessage(e);

            if (e.ignoreMessage) {
              return;
            }
            if (errorMessage) {
              props.notificationError(errorMessage);
            } else if (errorText) {
              props.notificationError(errorText);
            } else {
              throw e;
            }
          });
      },
    })
  );
}

export default withSubmit;
