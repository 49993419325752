// @flow
import gql from 'graphql-tag';

export const paginationFragment = gql`
  fragment paginationFragment on PaginationPageInfoNumber {
    hasNextPage
    nextOffset
    totalCount
  }
`;
