// @flow
import {AffiliateEmployeeQuery} from 'data/user/graphql/queries';
import type {AffiliateEmployee} from 'data/user/types';
import {getAffiliateIdFromAccessToken} from 'global/apolloClient/crypto';
import withQuery from 'hoc/withQuery';
import {omit} from 'ramda';
import {type HOC, compose, mapProps, withProps} from 'recompose';

type Added = {|
  affiliateEmployee: AffiliateEmployee,
|};

function withEmployees<T>(): HOC<
  // $ExpectError
  {...$Exact<Added>, ...$Exact<T>},
  T
> {
  return compose(
    withQuery(AffiliateEmployeeQuery, {
      variables: () => ({
        filter: {affiliateId: getAffiliateIdFromAccessToken()},
      }),
    }),
    withProps(props => {
      const employees: ?AffiliateEmployee = props.data;
      return {employees};
    }),
    // remove the default prop data from withQuery, and only carry this information in the user prop
    mapProps(omit(['data']))
  );
}

export default withEmployees;
