// @flow
import moment from 'moment';

const TIMESTAMP_INTERVAL = 30;
const TIMESTAMP_UNIT = 'minutes';

export const shouldNotify = (timestamp: moment$Moment): boolean => {
  if (!timestamp) {
    return true;
  }

  const timeInterval = timestamp.clone().add(TIMESTAMP_INTERVAL, TIMESTAMP_UNIT);

  return moment.utc().isSameOrAfter(timeInterval);
};
