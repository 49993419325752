// @flow
import {isEmpty, isNil, values} from 'ramda';
import * as React from 'react';

import {FilterInputWrap} from './styled';

type Props = {|
  value: any,
  placeholder: string,
  onClick: Function,
  formatter?: Function,
|};

const FilterInput = ({value, placeholder, onClick, formatter}: Props) => {
  const empty = isNil(value) || isEmpty(value) || values(value).every(isNil);
  const filterTitle = empty ? placeholder : value;

  return (
    <FilterInputWrap empty={empty} onClick={onClick}>
      {formatter ? formatter(filterTitle) : filterTitle}
    </FilterInputWrap>
  );
};

export default FilterInput;
