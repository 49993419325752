// @flow
import * as React from 'react';

import {ControlButton, DesktopFilterControlsWrap} from './styled';

type Props = {|
  apply: Function,
  clear: Function,
  hasValue: boolean,
|};

const DesktopFilterControls = ({clear, apply, hasValue}: Props) => (
  <DesktopFilterControlsWrap>
    <ControlButton primary onClick={clear} disabled={!hasValue}>
      Clear
    </ControlButton>
    <ControlButton primary onClick={apply}>
      Apply
    </ControlButton>
  </DesktopFilterControlsWrap>
);

export default DesktopFilterControls;
