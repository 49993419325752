import React from 'react';
const WrenchIcon = () => (
  <svg width="47" height="47">
    <g fill="#FFF" fillRule="nonzero" stroke="#242536" strokeWidth="2" transform="translate(1 1)">
      <circle cx="22.5" cy="22.5" r="22.5" />
      <path d="M24.4112 24.9389a2.9938 2.9938 0 0 1-.5758.8915l-6.82 7.3135c-1.13 1.2118-3.0283 1.278-4.24.148-1.2118-1.1299-1.278-3.0282-.1481-4.24l6.82-7.3135a2.992 2.992 0 0 1 .9127-.6675c-1.4158-2.8652-1.0354-6.381 1.2243-8.8043 2.2126-2.3727 5.5958-3.0212 8.5117-1.909l-3.631 3.8937c-1.159 1.2428-1.091 3.1898.1519 4.3488 1.2428 1.159 3.1898 1.091 4.3488-.1519l3.631-3.8938c1.3128 2.8313.9019 6.2515-1.3107 8.6242-2.305 2.4719-5.8807 3.0723-8.8748 1.7603z" />
    </g>
  </svg>
);
export default WrenchIcon;
