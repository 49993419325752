// @flow
import {connectRouter, routerMiddleware} from 'connected-react-router';
import history from 'global/history';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore} from 'redux-persist';

import epicMiddleware, {rootEpic} from './epicMiddleware';
import reducers from './reducers';

const middlewares = [epicMiddleware, routerMiddleware(history)];

const store = createStore(
  connectRouter(history)(reducers),
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
);

epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);

export const purgePersistor = (): void => persistor.purge();

export default store;
