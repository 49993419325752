// @flow
import gql from 'graphql-tag';

export const profileFragment = gql`
  fragment profileFragment on Profile {
    id
    firstName
    lastName
    birthdate
    phoneNumber
    height
    weight
    bootLength
    units
  }
`;

export const userFragment = gql`
  fragment userFragment on UserPublic {
    id
    email
    role
    customer
    lastLoginAt
    profiles {
      ...profileFragment
    }
  }
  ${profileFragment}
`;

export const customerFragment = gql`
  fragment customerFragment on Customer {
    user {
      profiles {
        ...profileFragment
      }
      email
      phoneNumber
    }
  }
  ${profileFragment}
`;

export const affiliateEmployeeFragment = gql`
  fragment affiliateEmployeeFragment on AffiliateEmployee {
    id
    code
  }
`;
