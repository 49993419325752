// @flow
import theme from 'global/theme';
import {css} from 'styled-components';

export const fontWeight = css`
  font-weight: ${theme.fontWeights.normal};

  ${p =>
    p.book &&
    css`
      font-weight: ${theme.fontWeights.book};
    `}
  ${p =>
    p.medium &&
    css`
      font-weight: ${theme.fontWeights.medium};
    `}
  ${p =>
    p.bold &&
    css`
      font-weight: ${theme.fontWeights.bold};
    `}
  ${p =>
    p.ultra &&
    css`
      font-weight: ${theme.fontWeights.ultra};
    `}
`;

export const color = css`
  ${p =>
    p.black &&
    css`
      color: ${theme.color.black};
    `}
  ${p =>
    p.white &&
    css`
      color: ${theme.color.white};
    `}
  ${p =>
    p.primary &&
    css`
      color: ${theme.color.primary};
    `}
  ${p =>
    p.danger &&
    css`
      color: ${theme.color.negative};
    `}
  ${p =>
    p.darkerGrey &&
    css`
      color: ${theme.color.darkerGrey};
    `}
`;

export const uppercase = css`
  ${p =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const display = css`
  ${p =>
    p.inline &&
    css`
      display: inline-block;
    `}
  ${p =>
    p.singleLine &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const align = css`
  ${p =>
    p.center &&
    css`
      text-align: center;
    `}
`;
