// @flow
import SkuSelect from 'components/SkuSelect';
import StyledLink from 'components/StyledLink';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectAppConfig} from 'data/app/selectors';
import {reservationStatuses} from 'data/reservations/constants';
import type {Reservation} from 'data/reservations/types';
import withConnect from 'hoc/withConnect';
import urls from 'pages/urls';
import React from 'react';
import {type HOC} from 'recompose';

type Outter = {|
  reservation: Reservation,
  hideBasketCode?: boolean,
|};

const ReservationCodeDetail = ({reservation, appConfig, hideBasketCode}) => (
  <React.Fragment>
    <StyledLink to={urls.booking(reservation.booking.bookingId)}>
      <Text uppercase bold black>
        Booking Code
      </Text>
      <Text black>{reservation.booking.externalId}</Text>
    </StyledLink>
    <br />
    <Text uppercase bold black>
      Reservation Code
    </Text>
    <Text black>{reservation.externalId}</Text>
    <br />
    {reservation.basketCode && !hideBasketCode && (
      <React.Fragment>
        <Text uppercase bold black>
          Basket Code
        </Text>
        <Text black>{reservation.basketCode}</Text>
        <br />
      </React.Fragment>
    )}
    <Text uppercase bold black>
      Asset ID
    </Text>
    {reservation.status !== reservationStatuses.cancelled && (
      <SkuSelect reservation={reservation} />
    )}
    <br />
    {reservation.productItem.productVariant.shelfLocation && (
      <div>
        <Text uppercase bold black>
          Shelf Location
        </Text>
        <Text black>{reservation.productItem.productVariant.shelfLocation}</Text>
      </div>
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = withConnect(mapStateToProps);

export default enhancer(ReservationCodeDetail);
