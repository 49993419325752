// @flow
import {StatusWrap} from 'components/Tile/styled';
import type {ProductItemStatus} from 'data/product/types';
import React from 'react';

import {getStatus} from './helpers';

type Props = {|
  status: ProductItemStatus,
  small?: boolean,
|};

const QuiverStatus = ({status, small}: Props) => {
  const statusObject = getStatus(status);

  return (
    <StatusWrap data-cy="item-status" color={statusObject.color} small={small}>
      {statusObject.status}
    </StatusWrap>
  );
};

export default QuiverStatus;
