// @flow
import {selectAppConfig} from 'data/app/selectors';
import {bookingQuery} from 'data/bookings/graphql/queries';
import type {Booking as BookingType} from 'data/bookings/types';
import withAffiliate from 'hoc/withAffiliate';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';
import {type HOC, compose} from 'recompose';

import BookingPreview from './BookingPreview';

const Booking = ({match, data, appConfig, affiliate}) => {
  return (
    <SubPage title={affiliate.name}>
      <BookingPreview data={data} />
    </SubPage>
  );
};

type Outter = {|
  match: any,
  data: BookingType,
|};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = compose(
  withAffiliate(),
  withConnect(mapStateToProps),
  withQuery(bookingQuery, {
    variables: ({match}) => match.params.id,
  })
);

export default enhancer(Booking);
