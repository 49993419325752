// @flow
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled from 'styled-components';

export const BackLink = styled(StyledLink)`
  font-size: 1.5rem;
  color: ${theme.color.darkGrey};
  font-weight: ${theme.fontWeights.book};
  position: absolute;
  left: 1.5rem;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  z-index: ${theme.zIndices.overlayMinimal};

  span {
    margin-left: 0.8rem;
    font-weight: ${theme.fontWeights.book};
  }
`;
