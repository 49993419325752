// @flow
import {mqMax} from 'common/mediaQuery';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import theme from 'global/theme';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  width: ${p => `${p.width || 80}%`};
  padding: 0 2rem;
  justify-content: space-between;
`;

export const Divisor = styled.div`
  display: flex;
  width: 65%;
  margin-bottom: 1rem;
  border: 0.5px solid ${theme.color.ultralight};
`;

export const ProductText = styled.span`
  color: ${theme.color.primary};
  font-weight: ${theme.fontWeights.bold};
  font-size: 2.2rem;
`;

export const ManufacturerText = styled.div`
  color: ${theme.color.midGrey};
  font-size: 1.25rem;
`;

export const DimensionText = styled.div`
  color: ${theme.color.darkGrey};
  font-size: 1.25rem;
  max-width: 23rem;
`;

export const ResCodeText = styled.div`
  color: ${theme.color.midGrey};
  font-weight: ${theme.fontWeights.book};
  font-size: 1.05rem;
`;

export const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 2rem;
`;

export const ReservationDates = styled.span`
  color: ${theme.color.midGrey};
  font-size: 2rem;
`;

export const DateWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0.12rem;
  right: 2rem;
`;

export const StartWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
`;

export const EndWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const Month = styled(SubTitle)`
  font-size: 1.3rem;
  margin-top: -1.2rem;
`;

export const Day = styled(Title)`
  font-size: 2.6rem;
`;

export const ActionBanner = styled.div`
  position: absolute;
  width: 100%;
  bottom: -0.2rem;
  align-items: center;
  justify-content: center;
  z-index: 0;

  ${mqMax.tabletWide} {
    bottom: 0.6rem;
  }
`;

export const ButtonWrap = styled(Text)`
  position: absolute;
  align-items: center;
  width: 100%;
`;
