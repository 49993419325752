// @flow
import type {ID} from 'data/enums/types';

const urls = {
  // non-auth
  home: '/',
  debug: '/debug',

  // TODO(MT): Fix terms and conditions link
  terms: '/terms',

  // auth only
  upcoming: '/upcoming',
  calendar: '/calendar',
  customers: '/customers',
  productAction: (id: ID) => `/product-action/${id}`,
  reservation: (id: ID) => `/reservation/${id}`,
  returns: '/returns',
  bookingBase: '/booking',
  booking: (id: ID) => `/booking/${id}`,
  bookingPackingSlip: (id: ID) => `/booking/${id}/packing-slip`,
  productItemBase: '/stock-item',
  productItem: (id: ID) => `/stock-item/${id}`,
  inventory: '/inventory',

  verifySetup: (id: ID) => `/verify-setup/${id}`,

  masqueradeClaim: (claimToken: ID) => `/masquerade/claim/${claimToken}`,
};

export default urls;
