// @flow
import {Wrap} from 'componentsStyled/Layout/Wrap';
import {Text} from 'componentsStyled/Typography/Texts';
import config from 'global/config';
import withOnMount from 'hoc/withOnMount';
import type {Element as ReactElements} from 'react';
import React from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

const stripeKeyExists = config.stripeKey || false;

/*
 * Component initialises stripe and provides Stripe Elements context to child
 * components passed through.
 */
const StripeIntialisation = ({data, stripe, children}) => {
  if (!stripeKeyExists) {
    return (
      <Wrap>
        <Text>Stripe failed to load</Text>
      </Wrap>
    );
  }

  return (
    <StripeProvider stripe={stripe}>
      <Elements>{children}</Elements>
    </StripeProvider>
  );
};

type Outter = {|
  children: ReactElements<*>,
|};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    {
      stripe: null,
    },
    {
      setStripe:
        () =>
        ({stripe}) => ({stripe}),
    }
  ),
  withHandlers({
    initStripe: props => () => {
      if (stripeKeyExists) {
        const stripe = window.Stripe(config.stripeKey);
        props.setStripe({stripe});
      }
    },
  }),
  withOnMount(props => {
    if (window.Stripe) {
      props.initStripe();
    } else {
      const el = document.querySelector('#stripe-js');
      el &&
        el.addEventListener('load', () => {
          props.initStripe();
        });
    }
  })
);

export default enhancer(StripeIntialisation);
