// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const MonthWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30rem;
  height: 100%;
  padding: 1rem 1rem;
  margin-bottom: 5rem;
  background-color: ${theme.color.white};
  background-clip: content-box;

  ${mqMax.tabletWide} {
    height: 100%;
    padding: 0;
    margin-top: 1rem;
  }
`;

export const WeekdaysWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  background: ${theme.color.ultralight};
  border: 1px solid ${theme.color.lightGrey};
`;

export const Weekday = styled.div`
  display: flex;
  width: calc(100% / 7);
  flex-direction: row;
  align-self: center;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  height: 2.8rem;
  padding: 1rem 0;
`;

export const Week = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10rem;

  ${mqMax.tabletWide} {
    height: 5rem;
  }
`;

export const DayWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  height: 10rem;
  color: ${theme.color.midGrey};
  border: 1px solid ${theme.color.ultralight};
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
  border-top: none;

  ${p =>
    p.isToday &&
    css`
      color: white;
    `}

  ${p =>
    p.isDisabled &&
    css`
      background: ${theme.color.ultralight};
    `}

  ${mqMax.tabletWide} {
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

export const Day = styled.div`
  position: absolute;
  display: flex;
  margin-top: -2.35rem;

  ${p =>
    p.isToday &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};
      border-radius: 5rem;
      padding: 0.5rem 0.85rem;
    `}

  ${mqMax.tabletWide} {
    font-size: 1.1rem;
    margin-top: -1.35rem;
    padding: 0.25rem 0.5rem;
  }
`;
