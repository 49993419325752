// @flow
import {StatusWrap} from 'components/Tile/styled';
import styled from 'styled-components';

export const ActionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: calc(50% - 14rem);
  gap: 3.2rem;

  ${StatusWrap} {
    margin-bottom: 0;
  }
`;

export const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  ${StatusWrap} {
    margin: 0;
  }

  margin-bottom: 3.2rem;
`;
