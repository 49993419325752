// @flow
import FulfilmentIndicator from 'components/BookingItem/FulfilmentIndicator';
import BookingStatus from 'components/Status/BookingStatus';
import {Body, Content, ContentWrap, ImageWrap} from 'components/Tile/styled';
import {Row} from 'componentsStyled/Layout/Grid.js';
import {Text} from 'componentsStyled/Typography/Texts';
import type {Booking} from 'data/bookings/types';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import * as React from 'react';

import {momentFromDateString} from '../../data/units/date/helpers';
import {
  AdditionalItemsCount,
  BookingTileHeader,
  ImageContainer,
  ImageRow,
  ProductImage,
} from './styled.js';

const MAX_IMAGES = 3;

type Props = {|
  booking: Booking,
|};

const BookingItem = ({booking}: Props) => {
  const {firstName, lastName} = booking.order.customer.user.profiles[0];
  const startDate = momentFromDateString(booking.start);
  const endDate = momentFromDateString(booking.end);

  const renderImages = booking => {
    // Deeply nested object may not be available on components first render
    if (!booking.reservations[0].productItem.productVariant) {
      return;
    }
    const indexImages = booking.reservations.map(reservation => {
      return selectIndexImage(reservation.productItem.productVariant.product.images);
    });

    const imageSizes = indexImages.map(image => {
      return minHeightImg(image, 160);
    });

    const itemCount = booking.reservations.length;
    const displayImages = imageSizes.slice(0, MAX_IMAGES);

    const isDarkFilterOn = itemCount > MAX_IMAGES;
    const applydarkFilter = (index: number) => isDarkFilterOn && index === MAX_IMAGES - 1;

    return (
      <ImageRow>
        {displayImages.map((imageSize, index) => (
          <ImageContainer>
            <ProductImage darkFilter={applydarkFilter(index)} key={index}>
              <img
                src={imageSize.url}
                alt={indexImages[index].alt}
                title={indexImages[index].alt}
              />
            </ProductImage>
          </ImageContainer>
        ))}
        {isDarkFilterOn && (
          <AdditionalItemsCount>{`+${itemCount - MAX_IMAGES}`}</AdditionalItemsCount>
        )}
      </ImageRow>
    );
  };

  return (
    <Body>
      <ContentWrap>
        <Content width={100}>
          <BookingTileHeader>
            <FulfilmentIndicator fulfilmentType={booking.fulfillmentType} />
            <BookingStatus booking={booking} small />
          </BookingTileHeader>
          <Row>
            <Text singleLine bold black>
              {firstName} {lastName}
            </Text>
          </Row>
          <ImageWrap>{renderImages(booking)}</ImageWrap>
          <Text black>
            {startDate.format('DD MMM')} - {endDate.format('DD MMM')}
          </Text>
          <Text darkerGrey>{booking.externalId}</Text>
        </Content>
      </ContentWrap>
    </Body>
  );
};

export default BookingItem;
