import {mq} from 'common/mediaQuery';
import {css} from 'styled-components';

import buttonShineEffect from './buttonShineEffect';

const getSize = props => {
  if (props.small)
    return css`
      height: 3.6rem;
      line-height: 2.6rem;
      min-width: 8rem;
      font-size: 1.2rem;
      padding: 0 1.25rem;

      ${mq.tabletWide} {
        ${buttonShineEffect};
        height: 4rem;
        line-height: 3.6rem;
        padding: 0 1.6rem;
      }
    `;

  if (props.large)
    return css`
      ${buttonShineEffect};
      height: 4.2rem;
      width: 28rem;
      line-height: 4.2rem;
      padding: 0 2.5rem;
    `;

  return css`
    ${buttonShineEffect};
    height: 5rem;
    line-height: 4.6rem;
    padding: 0 2.5rem;
    min-width: 20rem;
    font-size: 1.5rem;

    ${mq.tablet} {
      padding: 0 3.4rem;
    }
  `;
};

export default getSize;
