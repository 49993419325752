// @flow
import type {Selector} from 'common/redux/types';
import type {DateRangeValue} from 'data/units/date/types';
import {compose, prop} from 'ramda';

import type {AppState} from './reducer';
import type {AppConfig, Category} from './types';

const base: Selector<AppState> = prop('app');

export const selectAppConfig: Selector<?AppConfig> = compose(prop('appConfig'), base);

export const selectActivity: Selector<?Category> = compose(prop('activity'), base);

export const selectDates: Selector<?DateRangeValue> = compose(prop('dates'), base);

export const selectUnit: Selector<?string> = compose(prop('unit'), base);
