// @flow

import type {ReqOutter} from 'forms/withFormField';
import withFormField from 'forms/withFormField';
import moment from 'moment';
// $ReactHooks
import React, {useState} from 'react';
import {type HOC} from 'recompose';

import {CalendarIcon} from '../../../assets/icons/CalendarIcon';
import ContextMenu from '../../../components/ContextMenu';
import DatePicker from '../../../components/DatePicker';
import OverlayWrapper from '../../../components/OverlayWrapper';
import {fromDateString} from '../../../data/units/date/helpers';
import type {DateRangeValue, DateString} from '../../../data/units/date/types';
import {
  DatePickerWrapper,
  ReturnDatePickerText,
  ReturnDatePickerValue,
  ReturnDatePickerWrap,
} from './return-date-picker-styled';

type Props = {
  value: DateString,
  onChange(date: DateString): void,
  shouldDisableDate?: (date: moment) => boolean,
  datesToOutline?: (DateString | moment)[],
};

const ReturnDatePicker = ({value, onChange, shouldDisableDate, datesToOutline}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const handleReturnDateClick = (e: SyntheticInputEvent<*>) => {
    e.preventDefault();
    open();
  };

  const handleChange = (dateRange?: DateRangeValue) => {
    if (!dateRange || !dateRange.startDate) {
      return;
    }

    onChange(dateRange.startDate);
    handleClose();
  };

  return (
    <>
      <OverlayWrapper isOpen={isOpen} close={handleClose} transparent>
        <ReturnDatePickerValue onClick={handleReturnDateClick}>
          <CalendarIcon />
          <ReturnDatePickerText>{fromDateString(value)}</ReturnDatePickerText>
        </ReturnDatePickerValue>
        <ContextMenu isOpen={isOpen} close={handleClose} left>
          <DatePickerWrapper>
            <DatePicker
              selectionType={'single'}
              value={{startDate: value, endDate: value}}
              onChange={handleChange}
              shouldDisableDate={shouldDisableDate}
              datesToOutline={datesToOutline}
              singleMonth
            />
          </DatePickerWrapper>
        </ContextMenu>
      </OverlayWrapper>
    </>
  );
};

const enhancer: HOC<*, ReqOutter> = withFormField;

const Component = enhancer(ReturnDatePicker);

const WrappedComponent = (props: ReqOutter) => (
  <ReturnDatePickerWrap>
    <Component {...props} wrapperAsLabel={false} />
  </ReturnDatePickerWrap>
);

export default WrappedComponent;
