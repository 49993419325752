// @flow
import {chooseActivity} from 'data/app/actions';
import {activities} from 'data/app/constants';
import {showGrowl} from 'data/growls/actions';
import {shouldNotify} from 'data/growls/helpers';
import {selectTopmostGrowl} from 'data/growls/selectors';
import {dateStringFromMoment} from 'data/units/date/helpers';
import {getIsNearClosingTimeQuery} from 'data/user/graphql/queries';
import history from 'global/history';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import moment from 'moment';
import urls from 'pages/urls';
import {omit, path} from 'ramda';
import {type HOC, compose, lifecycle, mapProps, withHandlers, withStateHandlers} from 'recompose';

const mapStateToProps = state => ({
  growl: selectTopmostGrowl(state),
});

const mapDispatchToProps = {
  showGrowl,
  chooseActivity,
};

function withIsNearClosingHours<T>(): HOC<
  // $ExpectError
  {...$Exact<Added>, ...$Exact<T>},
  T
> {
  return compose(
    withConnect(mapStateToProps, mapDispatchToProps),
    withStateHandlers({timestamp: undefined}, {setTimestamp: props => timestamp => ({timestamp})}),
    withQuery(getIsNearClosingTimeQuery, {
      variables: () => ({
        amount: 15,
        unit: 'minutes',
        input: {
          start: dateStringFromMoment(moment.utc()),
          end: dateStringFromMoment(moment.utc()),
        },
      }),
      config: {
        skip: props => !path(['affiliate', 'allowed'], props),
      },
      noEmpty: true,
    }),
    withHandlers({
      handleGrowl: props => (returns: number) => {
        const {growl, showGrowl} = props;

        if (returns && !growl) {
          const returnsText = returns > 1 ? 'returns' : 'return';

          showGrowl(`${returns} pending ${returnsText} before closing hour!`, () => {
            props.chooseActivity(activities.ALL);
            return history.push(urls.returns);
          });

          props.setTimestamp(moment.utc());
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        if (this.props.data) {
          const {isNearClosingTime, returns} = this.props.data;

          if (isNearClosingTime) {
            this.props.handleGrowl(returns);
          }
        }
      },
      componentDidUpdate() {
        if (this.props.data) {
          const {isNearClosingTime, returns} = this.props.data;

          if (isNearClosingTime && shouldNotify(this.props.timestamp)) {
            this.props.handleGrowl(returns);
          }
        }
      },
    }),
    mapProps(omit(['data']))
  );
}

export default withIsNearClosingHours;
