// @flow
import type {Mutation} from 'common/graphql/types';
import {inventoryOverviewQuery} from 'data/product/graphql/queries';
import gql from 'graphql-tag';
import {path} from 'ramda';

import {affiliateReservationListQuery, reservationDetailQuery} from './queries';

const getProductGroupId = (props: any) =>
  path(['productGroupId'], props) ||
  path(['data', 'productItem', 'productVariant', 'product', 'productGroup', 'id'], props) ||
  path(['match', 'params', 'productGroupId'], props);

export const getConfig = (props: any, status: string) => {
  const productGroupId = getProductGroupId(props);
  return {
    filter: {
      status,
      categoryIds: productGroupId ? [productGroupId] : undefined,
    },
  };
};

export const updateStatusMutation: Mutation<{|id: number|}> = {
  gql: gql`
    mutation updateStatusMutation($id: Int!, $input: UpdateStatusInput!) {
      reservation {
        updateStatus(id: $id, input: $input) {
          id
        }
      }
    }
  `,
  refetch: [affiliateReservationListQuery, affiliateReservationListQuery, inventoryOverviewQuery],
  refetchVariables: [
    props => getConfig(props, 'confirmed'),
    props => getConfig(props, 'checked_out'),
  ],
  refetchOptions: [
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
  ],
};

export const updateBasketStatusMutation: Mutation<{|basketCode: string|}> = {
  gql: gql`
    mutation updateBasketStatusMutation($basketCode: String!, $input: UpdateStatusInput!) {
      reservation {
        updateBasketStatus(basketCode: $basketCode, input: $input)
      }
    }
  `,
  refetch: [affiliateReservationListQuery, affiliateReservationListQuery, inventoryOverviewQuery],
  refetchVariables: [
    props => getConfig(props, 'confirmed'),
    props => getConfig(props, 'checked_out'),
  ],
  refetchOptions: [
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
  ],
};

export const cancelReservationMutation: Mutation<{||}> = {
  gql: gql`
    mutation cancelReservationMutation($reservationId: Int!) {
      reservation {
        cancel(reservationId: $reservationId)
      }
    }
  `,
  refetch: [affiliateReservationListQuery, affiliateReservationListQuery, inventoryOverviewQuery],
  refetchVariables: [
    props => getConfig(props, 'confirmed'),
    props => getConfig(props, 'checked_out'),
  ],
  refetchOptions: [
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
  ],
};

export const updateProductItemMutation: Mutation<{|id: number, productItemId: number|}> = {
  gql: gql`
    mutation updateProductItemMutation($id: Int!, $productItemId: Int!) {
      reservation {
        updateProductItem(id: $id, productItemId: $productItemId) {
          id
        }
      }
    }
  `,
  refetch: [reservationDetailQuery],
  refetchVariables: [props => ({id: props.reservation.id})],
  refetchOptions: [{fetchPolicy: 'network-only'}],
};
