import {ModalTitle} from 'componentsStyled/Typography/Titles';
import ModalHeader from 'modals/_Header';
import styled from 'styled-components';

export const StyledModalHeader = styled(ModalHeader)`
  ${ModalTitle} {
    font-size: 2.4rem;
    text-transform: none;
  }
`;
