// @flow
import styled, {css} from 'styled-components';

export const Flex = styled.div`
  display: flex;
  height: 80%;
  width: 100%;
`;

export const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  ${p =>
    p.bottom &&
    css`
      align-items: flex-end;
    `}
  ${p =>
    p.margin &&
    css`
      margin-top: 1rem;
    `}
`;
