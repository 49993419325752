// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const DesktopFilterControlsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.6rem;
  border-top: 0.3rem solid ${theme.color.ultralight};
  background-color: #fff;
`;

export const ControlButton = styled(Text)`
  cursor: pointer;

  &:last-child {
    margin-left: auto;
  }

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `}
`;
