// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const StyledInput = styled.input`
  height: 5rem;
  border: solid 0.2rem ${theme.color.lightGrey};
  color: ${theme.color.darkGrey};
  border-radius: 0.4rem;
  background: ${theme.color.white};
  padding: 0 1.9rem;
  font-size: 1.6rem;
  font-weight: ${theme.fontWeights.normal};
  width: 100%;
  outline: none;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  position: relative;

  &:focus {
    border-color: ${theme.color.primary};
    z-index: ${theme.zIndices.minimal};
  }

  &::placeholder {
    color: ${theme.lighten(theme.color.darkGrey, 0.5)};

    ${mqMax.tabletWide} {
      font-size: 1.3rem;
    }
  }

  ::-webkit-search-cancel-button,
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    background: #f7fafc;
  }

  ${p =>
    p.hasError &&
    css`
      background-color: #fff5f5;
      border: 2px solid ${theme.color.error};
    `}
`;
