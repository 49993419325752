// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import {Space} from 'componentsStyled/Layout/Spacers';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {ProductItem} from 'data/product/types';
import React from 'react';

import Detail from './Detail';
import ProductItemActions from './ProductItemActions';
import ReservationSummary from './ReservationSummary';
import {DetailWrap, Half, ImageWrap, StyledImage, TopWrap} from './styled';

type Props = {|
  product: ProductItem,
|};

const ProductItemPreview = ({product}: Props) => {
  const indexImage = selectIndexImage(product.productVariant.product.images);
  const imageSize = minHeightImg(indexImage, 600);

  return (
    <React.Fragment>
      <Desktop>
        <ImageWrap key={imageSize.url}>
          <StyledImage src={imageSize.url} />
          <ProductItemActions productItem={product} />
        </ImageWrap>
        <Half white>
          <DetailWrap>
            <Detail productItem={product} />
            <Space />
            <ReservationSummary product={product} />
          </DetailWrap>
        </Half>
      </Desktop>
      <Mobile>
        <TopWrap>
          <ImageWrap key={imageSize.url}>
            <StyledImage src={imageSize.url} />
            <ProductItemActions productItem={product} />
          </ImageWrap>
          <Half white>
            <DetailWrap>
              <Detail productItem={product} />
              <Space />
            </DetailWrap>
          </Half>
        </TopWrap>
        <ReservationSummary product={product} />
      </Mobile>
    </React.Fragment>
  );
};

export default ProductItemPreview;
