// @flow
import ProductItemActionPreview from 'components/ProductItemActionPreview';
import {getProductItemDetailQuery} from 'data/product/graphql/queries';
import type {ProductItem} from 'data/product/types';
import withQuery from 'hoc/withQuery';
import * as React from 'react';
import type {HOC} from 'recompose';

type Props = {
  data: ProductItem,
};

const ReservationDetail = ({data}: Props) => <ProductItemActionPreview data={data} />;

const enhancer: HOC<*, {||}> = withQuery(getProductItemDetailQuery);

export default enhancer(ReservationDetail);
