// @flow
import {closeGrowl} from 'data/growls/actions';
import {selectTopmostGrowl} from 'data/growls/selectors';
import type {Growl as GrowlType} from 'data/growls/types';
import withConnect from 'hoc/withConnect';
import {path} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import GrowlTile from './GrowlTile';

type Props = {
  growl?: ?GrowlType,
  isClosing?: boolean,
};

const Growl = ({growl, isClosing, handleClose}) => {
  if (!growl) {
    return null;
  }

  return <GrowlTile closing={isClosing} growl={growl} onClick={handleClose} />;
};

const mapStateToProps = state => ({
  growl: selectTopmostGrowl(state),
});

const mapDispatchToProps = {
  closeGrowl,
};

const enhancer: HOC<*, Props> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    {
      isClosing: false,
    },
    {
      setIsClosing: () => (isClosing: boolean) => ({isClosing}),
    }
  ),
  withHandlers({
    handleClose: props => () => {
      if (path(['growl', 'callback'], props)) {
        props.growl.callback();
      }

      props.closeGrowl();
      props.setIsClosing(true);

      setTimeout(() => props.setIsClosing(false), 250);
    },
  })
);

export default enhancer(Growl);
