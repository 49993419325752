// @flow
import LoginForm from 'components/LoginForm';
import LogoLink from 'components/LogoLink';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import ForgotPassword from 'modals/ForgotPassword';
import React from 'react';
import {compose, withHandlers} from 'recompose';

import {HomeWrap, LoginContainer, LoginWrap, LogoSection} from './styled';

const HomePage = ({openForgotPassword}) => (
  <HomeWrap>
    <LogoSection>
      <LogoLink type="full" />
    </LogoSection>
    <LoginContainer>
      <LoginWrap data-cy="login-form">
        <LoginForm openForgotPassword={openForgotPassword} />
      </LoginWrap>
    </LoginContainer>
  </HomeWrap>
);

const mapDispatchToProps = {
  openModal,
};

const enhancer = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    openForgotPassword: props => () => {
      props.openModal(ForgotPassword, {});
    },
  })
);

export default enhancer(HomePage);
