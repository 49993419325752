// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const EventBar = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  margin-top: 0.2rem;
  padding: 0.2rem;
  opacity: 0.8;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: ${theme.fontWeights.book};
  transition: 0.3s all;

  ${p =>
    p.checkouts &&
    css`
      background: ${p => (p.isLate ? `${theme.color.negative}` : `${theme.color.positive}`)};
    `}

  ${p =>
    p.returns &&
    css`
      background: ${p => (p.isLate ? `${theme.color.negativeDark}` : `${theme.color.primary}`)};
    `}

  ${mqMax.tabletWide} {
    font-size: 0.9rem;
    width: 1.6rem;
    height: 1rem;
    opacity: 1;
    padding: 0;
  }

  &:hover {
    opacity: 1;
  }
`;

export const Text = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  margin: 0;
  opacity: 1 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

export const EventWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;

  ${mqMax.tabletWide} {
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1.25rem;
  }
`;
