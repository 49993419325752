// @flow
import urls from 'pages/urls';
import * as React from 'react';

import {Link, MainMenu} from './styled';

const Menu = () => (
  <MainMenu data-cy={'main-menu'}>
    <Link to={urls.calendar}>Calendar</Link>
    <Link to={urls.upcoming}>Upcoming</Link>
    <Link to={urls.returns}>Returns</Link>
    <Link to={urls.inventory}>Inventory</Link>
  </MainMenu>
);

export default Menu;
