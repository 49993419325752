// @flow
const mapDataToTable = (data: Array<Object>): Array<Array<any>> => {
  // Pick object keys as the columns
  const table = [Object.keys(data[0])];

  // Pick object values as the rows
  data.map(item => table.push(Object.values(item)));

  return table;
};

export const createCsvFromData = (
  data: Array<Object>,
  columnDelimiter?: string = ',',
  rowDelimiter?: string = '\n'
): string => {
  if (!data || !data.length) {
    return '';
  }

  const table = mapDataToTable(data);

  let content =
    'data:text/csv;charset=utf-8,' + table.map(row => row.join(columnDelimiter)).join(rowDelimiter);

  const encodedContent = encodeURI(content);

  return encodedContent;
};
