// @flow
import type {Query} from 'common/graphql/types';
import type {ID} from 'data/enums/types';
import type {DateString} from 'data/units/date/types';
import gql from 'graphql-tag';

import type {
  DailyReservationsOverview,
  Reservation,
  ReservationOverview,
  ReservationPricing,
} from '../types';
import {reservationDetailFragment, reservationFragment} from './fragments';

export const reservationDetailQuery: Query<Reservation> = {
  gql: gql`
    query reservationDetailQuery($id: Int!) {
      reservation {
        get(id: $id) {
          ...reservationDetailFragment
        }
      }
    }
    ${reservationDetailFragment}
  `,
  selector: ['reservation', 'get'],
  options: callOptions => ({
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};

export const basketDetailQuery: Query<Reservation[]> = {
  gql: gql`
    query basketDetailQuery($id: String!) {
      reservation {
        getBasket(id: $id) {
          ...reservationDetailFragment
        }
      }
    }
    ${reservationDetailFragment}
  `,
  selector: ['reservation', 'getBasket'],
  options: callOptions => ({
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};

export const affiliateReservationListQuery: Query<Reservation[]> = {
  gql: gql`
    query affiliateReservationListQuery($filter: ListAffiliateFilter) {
      reservation {
        listByAffiliate(filter: $filter) {
          ...reservationFragment
        }
      }
    }
    ${reservationFragment}
  `,
  selector: ['reservation', 'listByAffiliate'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
  debounceKey: 'reservationList',
};

export type ReservationPricingQueryInput = {|
  productVariantId: ID,
  affiliateId: ID,
  start: DateString,
  end: DateString,
|};

export const reservationPricingQuery: Query<
  ReservationPricing,
  {|
    input: ReservationPricingQueryInput,
  |}
> = {
  gql: gql`
    query reservationPricingQuery($input: PriceInput!) {
      reservation {
        getPrice(input: $input) {
          totalPrice
          fullPrice
          totalDays
          prices
          currency
          discounts {
            percentage
            type
            detail
          }
        }
      }
    }
  `,
  selector: ['reservation', 'getPrice'],
};

export const reservationOverviewQuery: Query<ReservationOverview> = {
  gql: gql`
    query reservationOverviewQuery {
      reservation {
        getReservationOverview {
          checkout
          returns
          overdue
        }
      }
    }
  `,
  selector: ['reservation', 'getReservationOverview'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};

export type DailyReservationsOverviewFilter = {
  start: DateString,
  end: DateString,
};

export const getDailyReservationOverviewsQuery: Query<
  DailyReservationsOverview[],
  {
    input: DailyReservationsOverviewFilter,
  }
> = {
  gql: gql`
    query getDailyReservationOverviews($input: DailyReservationsOverviewFilter!) {
      reservation {
        getDailyReservationOverviews(input: $input) {
          date
          checkouts
          returns
        }
      }
    }
  `,
  selector: ['reservation', 'getDailyReservationOverviews'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};
