// @flow
import {mqMax} from 'common/mediaQuery';
import {Relative} from 'componentsStyled/Layout/Position';
import styled from 'styled-components';

export const SearchWrap = styled(Relative)`
  flex-grow: 1;

  ${mqMax.tabletWide} {
    flex-grow: 0.8;
  }

  ${mqMax.phoneWide} {
    flex-grow: 0.5;
  }
`;
