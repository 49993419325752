// @flow
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import LocalValue from 'modals/LocalValue';
import React from 'react';
import {type Component, type HOC, compose, withHandlers} from 'recompose';
import FilterInput from 'searchFilters/Shared/FilterInput';

const MobileFilter = ({formatter, handleChange, placeholder, value}) => (
  <React.Fragment>
    <FilterInput
      placeholder={placeholder}
      value={value}
      formatter={formatter}
      onClick={handleChange}
    />
  </React.Fragment>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {
  component: Component<{|
    value: any,
    onChange: Function,
  |}>,
  value: any,
  onChange: Function,
  formatter: any,
  placeholder: string,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleChange: props => () =>
      props.openModal(LocalValue, {
        title: props.placeholder,
        component: props.component,
        initialValue: props.value,
        onChange: props.onChange,
      }),
  })
);

export default enhancer(MobileFilter);
