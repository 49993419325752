// @flow
import type {DamageType, ProductDamage} from './types';

export const repairable = (damageTypes: DamageType[]) =>
  damageTypes.filter(damageType => damageType.isRepairable && damageType.isReportable);

export const nonRepairable = (damageTypes: DamageType[]) =>
  damageTypes.filter(damageType => !damageType.isRepairable);

export const notFixed = (damages: ProductDamage[]) =>
  damages.filter(
    damage => damage.damageType.isRepairable && damage.damageType.isReportable && !damage.fixed
  );
