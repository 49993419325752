// @flow
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const SidebarWrap = styled.div`
  position: fixed;
  top: 0;
  right: 100%;
  width: 24rem;
  height: 100vh;
  transition: transform 0.25s ease;
  background: ${theme.color.white};
  min-height: 100%;
  padding: 3.2rem 7rem 4rem 4rem;

  ${p =>
    p.isOpen &&
    css`
      transform: translateX(100%);
    `}
`;

export const LogoWrap = styled.div`
  margin-bottom: 2rem;
`;

export const Link = styled(StyledLink)`
  display: block;
  width: 100%;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.book};
  text-align: left;
  color: ${theme.color.black};
  margin: 1.5rem 0;

  &.active {
    opacity: 0.5;
  }
`;
