// @flow
import withLocation from 'hoc/withLocation';
import * as React from 'react';
import type {HOC} from 'recompose';

import {StyledHref, StyledNavLink} from './styled';

const StyledLink = ({children, to, className, onClick, exact, 'data-cy': dataCy}) => {
  // mails and phones
  if (!to || to.startsWith('mailto:') || to.startsWith('tel:'))
    return (
      <StyledHref href={to} className={className} onClick={onClick} data-cy={dataCy}>
        {children}
      </StyledHref>
    );

  // external links
  if (to.startsWith('http') || to.startsWith('www.'))
    return (
      <StyledHref
        href={to}
        rel="noreferrer noopener"
        target="_blank"
        className={className}
        onClick={onClick}
        data-cy={dataCy}
      >
        {children}
      </StyledHref>
    );

  // everything else
  return (
    <StyledNavLink
      to={to}
      className={className}
      activeClassName="active"
      onClick={onClick}
      exact={exact}
      data-cy={dataCy}
    >
      {children}
    </StyledNavLink>
  );
};

type Outter = {|
  children: React.Node,
  to?: string,
  onClick?: Function,
  exact?: boolean,
  // injected from styled components
  className?: string,
  'data-cy'?: string,
|};

// to ensure a link is rerendered on location change
const enhancer: HOC<*, Outter> = withLocation();

export default enhancer(StyledLink);
