// @flow
import {mqMax} from 'common/mediaQuery';
import Button from 'components/Button';
import {Text} from 'componentsStyled/Typography/Texts';
import theme from 'global/theme';
import styled, {keyframes} from 'styled-components';

const appear = keyframes`
  0% {
    transform: translate(0%, 100%);
  }
  100% {
    transform: translate(0%, 0);
  }
`;
const disappear = keyframes`
  0% {
    transform: translate(0%, 0);
  }
  100% {
    transform: translate(0%, 100%);
  }
`;

export const GrowlTileWrap = styled.div`
  position: fixed;
  top: 10%;
  right: 0;
  font-size: 1.6rem;
  font-weight: ${theme.fontWeights.book};
  background-color: ${theme.color.primary};
  min-height: 8rem;
  padding: 0 1rem;
  max-width: 40%;
  box-shadow: 0 2rem 3rem 0 ${theme.lighten(theme.color.black, 0.3)};
  color: ${theme.color.white};
  z-index: ${theme.zIndices.overlayContent};
  animation: ${p => (p.closing ? disappear : appear)} 0.25s linear;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${mqMax.tabletWide} {
    z-index: ${theme.zIndices.overlay};
    max-width: 100%;
    top: 0;
  }
`;

export const ActionWrap = styled.div`
  display: flex;
`;

export const MessageWrap = styled.div`
  display: flex;

  svg {
    transform: scale(0.8);
    width: 30%;

    ${mqMax.phoneWide} {
      width: 40%;
    }
  }
`;

export const StyledText = styled(Text)`
  color: white;
`;

export const StyledButton = styled(Button)`
  min-width: auto;
  max-width: auto;
  padding: 0 1rem;
  margin: 0 1rem;
  background: ${theme.lighten(theme.color.primaryDark, 0.5)};
`;

export const Divider = styled.div`
  background: ${theme.lighten(theme.color.primaryDark, 0.5)};
  height: 100%;
  min-height: 8rem;
  width: 0.15rem;
  margin: 0.5rem;
`;
