// @flow
import {isToday} from 'data/calendar/helpers';
import {getDailyReservationOverviewsQuery} from 'data/reservations/graphql/queries';
import {dateStringFromMoment} from 'data/units/date/helpers';
import withQuery from 'hoc/withQuery';
import moment from 'moment';
import * as React from 'react';
import type {HOC} from 'recompose';

import Events from '../Events';
import {createEvents} from './helpers';
import {Day, DayWrap, MonthWrap, Week, Weekday, WeekdaysWrap} from './styled';

type Props = {
  month: moment$Moment,
  weeks: [], // array of moments
};

const Month = ({data, weeks}) => {
  const events = createEvents(data);

  return (
    <MonthWrap>
      <WeekdaysWrap>
        {moment.weekdays().map((weekday, weekdayIndex) => (
          <Weekday key={weekdayIndex}>{weekday.substr(0, 3)}</Weekday>
        ))}
      </WeekdaysWrap>
      {weeks.map((week, weekIndex) => (
        <Week key={weekIndex}>
          {week.map((day, dayIndex) => (
            <DayWrap key={dayIndex} isDisabled={day === undefined}>
              <Day isToday={isToday(day)}>{day && day.date()}</Day>

              {day &&
                events.map((event, index) => {
                  if (moment(event.day).utc().isSame(day.clone().format('YYYYMMDD'))) {
                    return <Events event={event} key={index} />;
                  }
                  return null;
                })}
            </DayWrap>
          ))}
        </Week>
      ))}
    </MonthWrap>
  );
};

const enhancer: HOC<*, Props> = withQuery(getDailyReservationOverviewsQuery, {
  variables: props => ({
    input: {
      start: dateStringFromMoment(props.month.startOf('month')),
      end: dateStringFromMoment(props.month.endOf('month')),
    },
  }),
});

export default enhancer(Month);
