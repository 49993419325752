// @flow
import Mobile from 'components/Media/Mobile';
import {selectLoggedIn} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import * as React from 'react';
import type {HOC} from 'recompose';

import MainNav from '../MainNav';
import {HeaderWrap, PageContent} from '../styled';
import BackButton from './BackButton';

const SubPage = ({loggedIn, children, title, backUrl}) => {
  return (
    <React.Fragment>
      <HeaderWrap>
        {backUrl && (
          <Mobile>
            <BackButton to={backUrl}>Back</BackButton>
          </Mobile>
        )}
        <MainNav loggedIn={loggedIn} title={title} />
      </HeaderWrap>
      <PageContent>{children}</PageContent>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
});

type Outter = {|
  children: React.Node,
  title?: string,
  backUrl?: string,
|};

const enhancer: HOC<*, Outter> = withConnect(mapStateToProps);

export default enhancer(SubPage);
