// @flow
import {Title} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const LeftWrap = styled.div`
  display: flex;
  position: relative;
  width: 10%;
`;

export const AffiliateNameWrap = styled.div`
  display: flex;
  position: relative;
  width: 90%;
  justify-content: center;
`;

export const AffiliateName = styled(Title)`
  font-size: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 4rem;
`;
