// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const TipWrap = styled.div`
  top: ${p => `${p.top || -8}rem`};
  position: absolute;
  display: none;
  min-width: 30rem;
  max-height: 20rem;
  background: ${theme.color.white};
  box-shadow: -0.5rem 0.5rem 1rem 0.5rem ${theme.lighten(theme.color.black, 0.1)};
  border-radius: 0.4rem;
  padding: 1rem;
  flex-shrink: 0;

  &::after {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    bottom: -1rem;
    content: '';
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid ${theme.color.white};
  }

  ${p =>
    p.active &&
    css`
      display: block;
    `}
`;

export const ToolTipContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
`;
