// @flow
import {mq, mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css, keyframes} from 'styled-components';

const modalFadeDuration = 250;

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: ${fadeIn} ${modalFadeDuration}ms ease;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  background-color: ${theme.lighten(theme.color.black, 0.6)};
  transition: opacity 0.5s;

  ${p =>
    p.open &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`;

export const ModalBodyWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: ${theme.zIndices.modal};
`;

export const Body = styled.div`
  position: relative;
  width: 100%;

  ${p =>
    p.showOverflow
      ? css`
          overflow: visible;
        `
      : css`
          overflow: auto;
        `}
  display: flex;
  flex-direction: column;
  background: ${theme.color.white};
  padding: 3.8rem;

  ${mq.tabletWide} {
    max-width: ${p => p.maxWidth || 43}rem;
    max-height: 90vh;
    margin: auto;
    min-height: initial;
  }
  ${mqMax.phoneWide} {
    padding: 2rem;
  }
`;

export const Cross = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: ${theme.zIndices.minimal};
  top: 1rem;
  left: 1.2rem;

  svg {
    transition: 0.25s ease fill;
  }
`;
