// @flow
import type {ActivityGroups, Category} from './types';

export const productGroupId: {
  [
    | 'SURF'
    | 'SKI'
    | 'SNOWBOARD'
    | 'SPLITBOARD'
    | 'SUP'
    | 'ROAD_BIKE'
    | 'MOUNTAIN_BIKE'
    | 'TECHNICAL_APPAREL'
    | 'ALL']: string,
} = {
  SURF: 'surf',
  SKI: 'ski',
  SNOWBOARD: 'snowboard',
  SPLITBOARD: 'splitboard',
  SUP: 'sup',
  ROAD_BIKE: 'roadBike',
  MOUNTAIN_BIKE: 'mountainBike',
  TECHNICAL_APPAREL: 'technicalApparel',
  ALL: 'all',
};

export const activities: {
  [
    | 'ALL'
    | 'SURF'
    | 'SKI'
    | 'SNOWBOARD'
    | 'SPLITBOARD'
    | 'SUP'
    | 'ROAD_BIKE'
    | 'MOUNTAIN_BIKE'
    | 'TECHNICAL_APPAREL']: Category,
} = {
  ALL: {
    categoryId: productGroupId.ALL,
    name: 'all',
  },
  SURF: {
    categoryId: productGroupId.SURF,
    name: 'surfs',
  },
  SKI: {
    categoryId: productGroupId.SKI,
    name: 'skis',
  },
  SNOWBOARD: {
    categoryId: productGroupId.SNOWBOARD,
    name: 'snowboards',
  },
  SPLITBOARD: {
    categoryId: productGroupId.SPLITBOARD,
    name: 'splitboards',
  },
  SUP: {
    categoryId: productGroupId.SUP,
    name: 'sups',
  },
  ROAD_BIKE: {
    categoryId: productGroupId.ROAD_BIKE,
    name: 'road_bikes',
  },
  MOUNTAIN_BIKE: {
    categoryId: productGroupId.MOUNTAIN_BIKE,
    name: 'mountain_bikes',
  },
  TECHNICAL_APPAREL: {
    categoryId: productGroupId.TECHNICAL_APPAREL,
    name: 'technical_apparel',
  },
};

export const activityGroups: ActivityGroups[] = [
  {
    name: 'Ocean',
    activities: [activities.SURF, activities.SUP],
  },
  {
    name: 'Mountains',
    activities: [activities.SKI, activities.SNOWBOARD, activities.SPLITBOARD],
  },
  {
    name: 'Wheels',
    activities: [activities.ROAD_BIKE, activities.MOUNTAIN_BIKE],
  },
];
