// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import {chooseUnit} from 'data/app/actions';
import {selectUnit} from 'data/app/selectors';
import {updateProfileMutation} from 'data/user/graphql/mutations';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withUser from 'hoc/withUser';
import {path} from 'ramda';
import * as React from 'react';
import {Collapse} from 'react-collapse';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import {Link, SubGroupWrap, UnitLabel, UnitSelectorTitle, UnitWrap} from './styled';

const setUnit = (values: Object, chooseUnit: Function) => {
  if (!values['units']) return;

  if (values['units'].id === 'metric') {
    chooseUnit('metric');
  } else if (values['units'].id === 'imperial') {
    chooseUnit('imperial');
  }
};

const UnitSelector = ({
  group,
  toggleCollapse,
  isCollapsed,
  center,
  units,
  chooseUnit,
  values,
  handleClick,
}) => {
  const rotationCondition = isCollapsed ? 'up' : undefined;
  setUnit(values, chooseUnit);
  const isMetric = units === 'metric';

  return (
    <React.Fragment>
      <SubGroupWrap onClick={toggleCollapse} center={center} isOpened={!isCollapsed}>
        <UnitSelectorTitle>
          <Link>{group}</Link>
        </UnitSelectorTitle>
        <ArrowIcon marginLeft rotation={rotationCondition} />
      </SubGroupWrap>
      <Collapse isOpened={isCollapsed}>
        <UnitWrap>
          <UnitLabel onClick={() => handleClick('imperial')} active={!isMetric}>
            Imperial
          </UnitLabel>
          <UnitLabel onClick={() => handleClick('metric')} active={isMetric}>
            Metric
          </UnitLabel>
        </UnitWrap>
      </Collapse>
    </React.Fragment>
  );
};

type Outter = {|
  group: string,
  center?: boolean,
  chooseUnit?: typeof chooseUnit,
  handleClick?: Function,
  close?: void => mixed,
|};

const mapStateToProps = state => ({
  units: selectUnit(state),
});

const mapDispatchToProps = {
  chooseUnit,
};

const enhancer: HOC<*, Outter> = compose(
  withUser(),
  withStateHandlers(
    {isCollapsed: false},
    {toggleCollapse: props => () => ({isCollapsed: !props.isCollapsed})}
  ),
  withConnect(mapStateToProps, mapDispatchToProps),
  withMutation(updateProfileMutation),
  withForm({schema: {}}),
  withHandlers({
    handleClick: props => (units: string) => {
      if (props.units === units) {
        if (path(['close'], props)) {
          props.close();
        }
        return;
      }

      props.chooseUnit(units);
      // Store current units in profile
      const id = path(['user', 'profiles', 0, 'id'], props);
      if (id) {
        const input = {id, units};
        props.submitMutation({input}).then(() => {
          if (path(['close'], props)) {
            props.close();
          }
        });
      }
    },
  })
);

export default enhancer(UnitSelector);
