// @flow
export const showGrowl = (text: string, callback: Function) => ({
  type: 'GROWL/SHOW',
  payload: {
    text,
    callback,
  },
});

export const closeGrowl = () => ({
  type: 'GROWL/CLOSE',
});
