// @flow
import type {Query} from 'common/graphql/types';
import {paginationFragment} from 'data/app/graphql/fragments';
import type {Booking} from 'data/bookings/types';
import type {EnumValue} from 'data/enums/types';
import gql from 'graphql-tag';
import {map, uniqBy} from 'ramda';

import type {InventoryOverview, ProductItem} from '../types';
import {
  productItemDetailFragment,
  productItemFragment,
  productItemInventoryFragment,
} from './fragments';

const prepareEnumValue = product => ({
  id: product.id,
  name: `${product.code}${product.deletedAt ? ' (Currently offline)' : ''}`,
});

export const searchInInventoryQuery: Query<ProductItem[]> = {
  gql: gql`
    query searchInInventoryQuery(
      $query: String
      $limit: Int
      $offset: Int
      $categoryIds: [String!]
      $sorting: String
    ) {
      product {
        searchInInventory(
          filter: {
            sorting: $sorting
            limit: $limit
            categoryIds: $categoryIds
            query: $query
            offset: $offset
          }
        ) {
          data {
            ...productItemInventoryFragment
          }
          pageInfo {
            ...paginationFragment
          }
        }
      }
    }
    ${productItemInventoryFragment}
    ${paginationFragment}
  `,
  selector: ['product', 'searchInInventory', 'data'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
  debounceKey: 'inventory',
  pagination: ['product', 'searchInInventory'],
};

export const getProductItemDetailQuery: Query<ProductItem> = {
  gql: gql`
    query getProductItemDetailQuery($id: Int!) {
      product {
        getProductItemDetail(id: $id) {
          ...productItemDetailFragment
        }
      }
    }
    ${productItemDetailFragment}
  `,
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  selector: ['product', 'getProductItemDetail'],
};

export const inventoryOverviewQuery: Query<InventoryOverview> = {
  gql: gql`
    query inventoryOverviewQuery {
      product {
        getInventoryOverview {
          online
          offline
          inRepair
          damaged
          total
        }
      }
    }
  `,
  selector: ['product', 'getInventoryOverview'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};

export const listAvailableProductItemsByReservationIdQuery: Query<EnumValue[]> = {
  gql: gql`
    query listAvailableProductItemsByReservationIdQuery($id: Int!) {
      product {
        listAvailableProductItemsByReservationId(id: $id) {
          id
          code
          deletedAt
        }
      }
    }
  `,
  selector: ['product', 'listAvailableProductItemsByReservationId'],
  transform: map(prepareEnumValue),
};

// Returns a list of unique product items that share a common post-processing booking.
export const getAssociatedPostProcessingProductItems: Query<ProductItem[]> = {
  gql: gql`
    query getAssociatedPostProcessingProductItems($id: Int!) {
      product {
        getPostProcessingBookingsForProductItem(id: $id) {
          reservations {
            productItem {
              ...productItemFragment
            }
          }
        }
      }
    }
    ${productItemFragment}
  `,
  selector: ['product', 'getPostProcessingBookingsForProductItem'],
  transform: (bookings: Booking[]) => {
    const affectedItems = bookings.reduce((accumItems, booking) => {
      return [...accumItems, ...booking.reservations.map(reservation => reservation.productItem)];
    }, []);
    return uniqBy(productItem => productItem.id, affectedItems);
  },
};
