// @flow
import type {DailyReservationsOverview} from 'data/reservations/types';

type Event = {|
  day: string,
  events: {
    checkouts: number,
    returns: number,
  },
|};

export const createEvents = (data: DailyReservationsOverview[]): Event[] => {
  const events = data.map(element => ({
    day: element.date,
    events: {
      checkouts: element.checkouts,
      returns: element.returns,
    },
  }));

  return events;
};
