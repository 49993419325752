// @flow
import {mqMax} from 'common/mediaQuery';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import theme from 'global/theme';
import styled from 'styled-components';

export const ContentWrap = styled.div`
  display: flex;
  margin: 0.5rem;
  padding: 2rem;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${mqMax.tabletWide} {
    flex-direction: column;
  }
`;

export const StyledSubTitle = styled(SubTitle)`
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: ${theme.color.darkGrey};

  ${mqMax.tabletWide} {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const TableWrap = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;
