// @flow
import CSVExport from 'components/CSVExport';
import Table from 'components/Table';
import {customerListQuery} from 'data/customers/graphql/queries';
import withQuery from 'hoc/withQuery';
import withValue from 'hoc/withValue';
import Page from 'pages/_Page';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {ContentWrap, Header, StyledSubTitle as SubTitle} from './styled';
import {tableDefinition} from './tableDefinition';

const CustomersPage = ({onQueryChange, value, data}) => (
  <Page onChange={onQueryChange} value={value} initialValue="" overviewType="customer">
    <ContentWrap>
      <Header>
        <SubTitle>Customers Data</SubTitle>
        <CSVExport data={data} />
      </Header>
      {data ? <Table data={data} tableDefinition={tableDefinition} /> : <div>No data found.</div>}
    </ContentWrap>
  </Page>
);

const enhancer: HOC<*, {||}> = compose(
  withValue,
  withHandlers({
    onQueryChange: props => props.setValue,
  }),
  withQuery(customerListQuery, {
    noEmpty: true,
  })
);

export default enhancer(CustomersPage);
