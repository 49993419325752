// @flow
import ArrowIconSharp from 'assets/icons/ArrowIconSharp';
import {Text} from 'componentsStyled/Typography/Texts';
import type {Reservation} from 'data/reservations/types';
import type {Customer} from 'data/user/types';
import * as React from 'react';

import DateTile from './DateTile';
import {ArrowWrap, Body, ReservationTileWrap} from './styled';

type Props = {|
  reservation: Reservation,
|};

const ReservationTile = ({reservation}: Props) => {
  const customer = (c: Customer) => {
    if (!c || !c.user) {
      return 'No customer data available';
    }
    return `${c.user.profiles[0].firstName} ${c.user.profiles[0].lastName}`;
  };
  return (
    <ReservationTileWrap stack>
      <DateTile reservation={reservation} />
      <Body>
        <Text black book>
          Member: {customer(reservation.booking.order.customer)}
        </Text>
        <ArrowWrap>
          <ArrowIconSharp />
        </ArrowWrap>
      </Body>
    </ReservationTileWrap>
  );
};

export default ReservationTile;
