// @flow
import type {Query} from 'common/graphql/types';
import {userFragment} from 'data/user/graphql/fragments';
import type {User} from 'data/user/types';
import gql from 'graphql-tag';
import type {CustomerTableRow} from 'pages/Customers/tableDefinition';
import {path} from 'ramda';

export const customerListQuery: Query<CustomerTableRow[]> = {
  gql: gql`
    query customerListQuery($filter: AffiliateCustomersFilter) {
      affiliate {
        listCustomers(filter: $filter) {
          data {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  // $Ramda
  transform: (users: User[]): CustomerTableRow[] =>
    users.map(user => ({
      // $Ramda
      name: `${path(['profiles', 0, 'firstName'], user)} ${path(
        ['profiles', 0, 'lastName'],
        user
      )}`,
      email: path(['email'], user) || '',
      phoneNumber: path(['profiles', 0, 'phoneNumber'], user) || '',
    })),
  selector: ['affiliate', 'listCustomers', 'data'],
};

export default customerListQuery;
