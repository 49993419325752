// @flow

import {cancelBookingMutation} from 'data/bookings/graphql/mutations';
import {type Booking} from 'data/bookings/types';
import withMutation from 'hoc/withMutation';
import withRouter from 'hoc/withRouter';
import * as R from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {openModal} from '../../../data/modals/actions';
import type {Reservation, ReservationTransaction} from '../../../data/reservations/types';
import withConnect from '../../../hoc/withConnect';
import SingleButtonModal from '../../../modals/SingleButton';
import {StyledCancelButton} from './styled';

const BookingCancelButton = ({booking, handleCancel, loading}) => (
  <StyledCancelButton onClick={handleCancel} large secondary>
    Cancel Booking
  </StyledCancelButton>
);

type Outter = {|
  onClick?: Function,
  booking: Booking,
|};

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withMutation(cancelBookingMutation),
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleCancel: props => async () => {
      const reservationIds = props.booking.reservations.map(r => r.id);
      const payload = {
        bookingId: props.booking.bookingId,
      };
      const result: [Reservation] = await props.submitMutation(payload);

      const cancelledReservations = result.filter(reservation =>
        reservationIds.some(id => id === reservation.id)
      );

      // NOTE(Barry): The cancel booking mutation does not fail if the refund fails, it does however, return the latest
      // reservation transactions. We look through those and find the most recent one to determine if the refund failed.
      // If it did, inform the user, but don't stop anything else happening
      const refundFailed = cancelledReservations.some(reservation => {
        // Note(Harry): If there are no reservation transactions returned in the response, we take this to mean that there
        // was never any payments made against the booking. This may occur if an item is checked out earlier than the 48 hour
        // window from when the booking was intended to start.
        if (reservation.reservationTransactions.length === 0) {
          return false;
        }
        const sorted = R.reverse(
          R.sortBy(
            (rt: ReservationTransaction) => rt.createdAt,
            reservation.reservationTransactions
          )
        );
        return sorted[0].status === 'failed';
      });

      if (refundFailed) {
        await new Promise(resolve => {
          props.openModal(SingleButtonModal, {
            title: 'Refund Failed',
            buttonText: 'Ok',
            onConfirm: () => {
              resolve();
              return Promise.resolve();
            },
            message:
              'The booking has been cancelled, but a refund could not be completed successfully.',
          });
        });
      }

      props.history.push('/upcoming');
    },
  })
);
export default enhancer(BookingCancelButton);
