// @flow
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled from 'styled-components';

export const StyledLogoLink = styled(StyledLink)`
  flex-shrink: 0;

  svg {
    fill: ${theme.color.black};
    transition: fill 0.25s ease;
  }
`;

export const StyledLogo = styled.img`
  width: 97px;
  height: 37px;
  object-fit: contain;
`;
