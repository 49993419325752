// @flow
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './introspectionQueryResultData';

// needed for dynamic params in products
// source: https://www.apollographql.com/docs/react/recipes/fragment-matching.html
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Needed so that objects that have an id under a name other than 'id' or '_id' are cached properly.
// https://www.apollographql.com/docs/react/v2/caching/cache-configuration/#assigning-unique-identifiers
const dataIdFromObject = object => {
  switch (object.__typename) {
    case 'Booking':
      return object.bookingId;
    default:
      return defaultDataIdFromObject(object);
  }
};

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject,
});

export default cache;
