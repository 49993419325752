// @flow
import * as React from 'react';

import {TileWrap} from './styled';

type Props = {|
  children: React.Node,
|};

const Tile = ({children}: Props) => <TileWrap data-cy="booking-tile">{children}</TileWrap>;

export default Tile;
