// @flow
import {isScrollEnd} from 'common/events';
import Loader from 'components/Loader';
import {pathOr, reverse, sortBy} from 'ramda';
import React from 'react';
import {type HOC, compose, withHandlers, withProps, withStateHandlers} from 'recompose';

import {defaultSort} from './helpers';
import {StyledTable, TableWrap} from './styled';
import Td from './Td';
import Th from './Th';
import type {ColumnDef, TableDefinition} from './types';

const Table = ({
  handleLoadMore,
  sortingBy,
  sortAscending,
  handleSort,
  sortedData,
  loading,
  tableDefinition,
  className,
}) => (
  <React.Fragment>
    <TableWrap className={className} onScroll={handleLoadMore}>
      <StyledTable>
        <thead>
          <tr>
            {tableDefinition.map(def => (
              <Th
                key={def.title}
                column={def}
                // $Ramda
                sortingBy={sortingBy}
                sortAscending={sortAscending}
                onSort={handleSort}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {tableDefinition.map((def, colIndex) => (
                <Td key={colIndex} item={item} definition={def} />
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      {loading && <Loader />}
    </TableWrap>
  </React.Fragment>
);

type Enhancer<T> = {
  loading?: boolean,
  data: T[],
  tableDefinition: TableDefinition<T>,
  loadMore?: Function,
  className?: string,
};

type State = {
  sortingBy: ?ColumnDef<*>,
  sortAscending: boolean,
};

const enhancer: HOC<*, Enhancer<*>> = compose(
  withStateHandlers(
    ({
      sortingBy: null,
      sortAscending: true,
    }: State),
    {
      handleSort: props => (columnDefinition: ColumnDef<*>) => ({
        sortingBy: columnDefinition,
        sortAscending: props.sortingBy === columnDefinition ? !props.sortAscending : true,
      }),
    }
  ),
  withProps(props => {
    const data = props.data || [];
    // $Ramda
    const sort = pathOr(defaultSort, ['sortingBy', 'sortFunction'], props);
    const sorted = props.sortingBy ? sortBy(sort(props.sortingBy), data) : data;

    return {
      sortedData: props.sortAscending ? sorted : reverse(sorted),
      tableDefinition: props.tableDefinition,
    };
  }),
  withHandlers({
    handleLoadMore: props => e => {
      if (isScrollEnd(e) && props.loadMore) {
        props.loadMore();
      }
    },
  })
);

export default enhancer(Table);
