import * as sanitizeHtml from 'sanitize-html';

const allowedTags = [
  // Meta
  'style',
  // Formatting
  'b',
  'i',
  'strong',
  'em',
  'strike',
  'abbr',
  'code',
  'pre',
  // Structuring
  'p',
  'div',
  'hr',
  'br',
  'div',
  'span',
  // Linking
  'a',
  // Headings
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // Lists
  'ul',
  'ol',
  'nl',
  'li',
  // Tables
  'table',
  'thead',
  'caption',
  'tbody',
  'tr',
  'th',
  'td',
  // Media
  'img',
];

const allowedAttributes = {
  '*': ['style', 'id', 'class', 'align', 'width', 'height'],
  a: ['href'],
  img: ['src'],
  table: ['border', 'cellpadding', 'cellspacing'],
};

const RawHtml = ({html}: {html: string}) => (
  <div dangerouslySetInnerHTML={{__html: sanitizeHtml(html, {allowedTags, allowedAttributes})}} />
);

export default RawHtml;
