// @flow
import {Relative} from 'componentsStyled/Layout/Position';
import {FieldTitle} from 'componentsStyled/Typography/Titles';
import * as React from 'react';

import {DivWrap, Error, LabelWrap} from './styled';

type Props = {|
  children: React.Node,
  error: ?string,
  label: ?string,
  errorPosition?: string,
  wrapperAsLabel?: boolean,
|};

const InputWrap = ({
  children,
  label,
  error,
  errorPosition = 'relative',
  wrapperAsLabel = true,
}: Props) => {
  const WrapperComponent = wrapperAsLabel ? LabelWrap : DivWrap;
  return (
    <WrapperComponent>
      {label && <FieldTitle>{label}</FieldTitle>}
      <Relative>{children}</Relative>
      {error && <Error errorPosition={errorPosition}>{error}</Error>}
    </WrapperComponent>
  );
};

export default InputWrap;
