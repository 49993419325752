// @flow
import {getDataFromSelector} from 'common/graphql/helpers';
import type {Mutation} from 'common/graphql/types';
import withRouter from 'hoc/withRouter';
import {identity} from 'ramda';
import {graphql} from 'react-apollo';
import {type HOC, compose} from 'recompose';

const getRefetchQueries = (refetch, props, refetchVariables, refetchOptions) => {
  if (!refetch.length) return [];

  return refetch.map((query, index) => ({
    query: query.gql,
    variables: refetchVariables[index] && refetchVariables[index](props),
    callOptions: refetchOptions[index],
  }));
};

export const defaultSubmitName = 'submitMutation';

type Added<Variables, Result> = {
  submitMutation: Variables => Promise<Result>,
};

/**
 * Shorthand for withCustomMutation that names the mutation function submitMutation
 */
function withMutation<Variables, Result, Outter>(
  m: Mutation<Variables, Result>
): HOC<{...$Exact<Added<Variables, Result>>, ...$Exact<Outter>}, Outter> {
  return withCustomMutation(m, defaultSubmitName);
}

export default withMutation;

/**
 * Creates a HOC that provides a function that will call a mutation and returns a promise with the result. Adds the
 * mutation function to the props of the component using the name provided in mutationFunctionName
 * @param m The mutation to call
 * @param mutationFunctionName The name of the function to be passed to the child component which will call the mutation
 */
export function withCustomMutation<Variables, Result, Outter, CustomAdded>(
  m: Mutation<Variables, Result>,
  mutationFunctionName: string
): HOC<CustomAdded, Outter> {
  const {
    gql,
    refetch = [],
    selector,
    transform = identity,
    /**
     * Variables to pass to the refetch query. Callback is provided with the
     * component props.
     */
    refetchVariables = [],
    refetchOptions = [],
    optimisticResponse,
  } = m;

  return compose(
    withRouter,
    graphql(gql, {
      props: ({mutate, ownProps}) => ({
        [mutationFunctionName]: variables =>
          mutate({
            variables,
            refetchQueries: getRefetchQueries(refetch, ownProps, refetchVariables, refetchOptions),
            optimisticResponse: optimisticResponse
              ? optimisticResponse({props: ownProps, variables})
              : undefined,
          })
            .then(getDataFromSelector(selector || []))
            .then(transform),
      }),
    })
  );
}
