// @flow
import {ToggleLabel} from 'components/ProductItemPreview/ProductItemActions/InServiceToggle/styled';
import Switch from 'components/Switch';
import {notificationError} from 'data/notifications/actions';
import {setProductItemStatusMutation} from 'data/product/graphql/mutations';
import type {ProductItem} from 'data/product/types';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import React from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

type Outter = {|
  productItem: ProductItem,
|};

const InServiceToggle = ({productItem, submitMutation, notificationError}) => {
  //isDeleted is equivalent to NOT in service
  const {isDeleted, id} = productItem;

  const toggleService = async inService => {
    try {
      if (inService) {
        //Mark as in service
        await submitMutation({input: {id, isOnline: true}});
      } else {
        //Mark as out of service
        await submitMutation({input: {id, reason: 'not_available', isOnline: false}});
      }
    } catch (e) {
      notificationError(
        `We could not mark the item as ${inService ? 'in' : 'out of'} service, please try again`
      );
    }
  };

  return (
    <ToggleLabel>
      <Switch value={!isDeleted} onChange={toggleService} data-cy="in-service-switch" />
      In Service
    </ToggleLabel>
  );
};

const mapDispatchToProps = {
  notificationError,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => {}, mapDispatchToProps),
  withMutation(setProductItemStatusMutation)
);

export default enhancer(InServiceToggle);
