// @flow
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled from 'styled-components';

export const Toggle = styled(StyledLink)`
  display: flex;
  align-items: center;
  margin-left: 3rem;
  cursor: pointer;

  svg {
    margin-left: 1rem;
    transform: rotate(90deg);
  }
`;
export const MenuLinks = styled.div`
  padding: 1rem 0;
`;

export const MenuLink = styled(StyledLink)`
  display: block;
  font-size: 1.6rem;
  color: ${theme.color.black};
  line-height: 4rem;
  padding: 0 2rem;
  cursor: pointer;

  &:hover {
    background: ${theme.color.lightGrey};
  }
`;

export const UnitWrap = styled.div`
  display: block;
  font-size: 1.6rem;
  color: ${theme.color.black};
  line-height: 4rem;
  padding: 0 2rem;
  cursor: pointer;
`;
