// @flow
import type {Mutation} from 'common/graphql/types';
import type {StripeTokenId} from 'data/stripe/types';
import type {User} from 'data/user/types';
import gql from 'graphql-tag';

import {userFragment} from './fragments';
import {getPaymentMethodQuery} from './queries';

export const getRecoveryEmailMutation: Mutation<{|email: string, appType: string|}, empty> = {
  gql: gql`
    mutation getRecoveryEmailMutation($email: LowercaseString!, $appType: String) {
      auth {
        resetPasswordCode(email: $email, appType: $appType)
      }
    }
  `,
};

export const loginMutation: Mutation<
  {|
    email: string,
    password: string,
  |},
  User
> = {
  gql: gql`
    mutation loginMutation($email: LowercaseString!, $password: String!) {
      auth {
        login(email: $email, password: $password) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'login', 'user'],
};

export const updateProfileMutation: Mutation<{|
  input: {|
    id: number,
    units: string,
  |},
|}> = {
  gql: gql`
    mutation updateProfileMutation($input: ProfileUpdateInput) {
      profile {
        update(input: $input) {
          firstName
        }
      }
    }
  `,
};

export const editCreditCardMutation: Mutation<{|token: StripeTokenId|}> = {
  gql: gql`
    mutation editCreditCardMutation($token: String!) {
      payment {
        updateCustomer(token: $token) {
          id
        }
      }
    }
  `,
  refetch: [getPaymentMethodQuery],
};
