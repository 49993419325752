// @flow
import {StatusWrap} from 'components/Tile/styled';
import theme from 'global/theme';
import React from 'react';

type Props = {|
  small?: boolean,
|};

const OutOfServiceStatus = ({small}: Props) => {
  return (
    <StatusWrap data-cy="out-of-service-status" color={theme.color.negative} small={small}>
      Out Of Service
    </StatusWrap>
  );
};

export default OutOfServiceStatus;
