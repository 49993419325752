// @flow
import withRouter from 'hoc/withRouter';
import * as React from 'react';
import {type HOC, compose, withStateHandlers} from 'recompose';

import Hamburger from './Hamburger';
import {withHamburguer} from './helpers';
import {AffiliateName, AffiliateNameWrap, LeftWrap} from './styled';

type Outter = {
  name: string,
};

const MobileLoggedInNav = ({
  name,
  hamburgerOpen,
  openHamburger,
  toggleHamburger,
  closeHamburger,
  location,
}) => (
  <React.Fragment>
    <LeftWrap>
      {withHamburguer(location.pathname) && (
        <Hamburger
          isOpen={hamburgerOpen}
          open={openHamburger}
          close={closeHamburger}
          toggleOpen={toggleHamburger}
        />
      )}
    </LeftWrap>
    <AffiliateNameWrap>
      <AffiliateName>{name}</AffiliateName>
    </AffiliateNameWrap>
  </React.Fragment>
);

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withStateHandlers(
    {
      hamburgerOpen: false,
    },
    {
      closeHamburger: () => () => ({hamburgerOpen: false}),
      openHamburger: () => () => ({hamburgerOpen: true}),
      toggleHamburger:
        ({hamburgerOpen}) =>
        () => ({
          hamburgerOpen: !hamburgerOpen,
        }),
    }
  )
);

export default enhancer(MobileLoggedInNav);
