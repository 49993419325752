// @flow
import ArrowIconPrimary from 'assets/icons/ArrowIconPrimary';
import CrossIcon from 'assets/icons/CrossIcon';
import StyledLink from 'components/StyledLink';
import {ModalTitle} from 'componentsStyled/Typography/Titles';
import * as React from 'react';

import {LeftIconWrap, ModalHeaderWrap, RightIconWrap} from './styled';

type Props = {|
  close?: Function,
  back?: Function,
  title: string,
  clear?: Function,
  children?: React.Node,
  rightCross?: boolean,
  className?: string,
|};

const ModalHeader = ({close, rightCross, back, title, clear, children, className}: Props) => (
  <ModalHeaderWrap className={className}>
    {close &&
      (!rightCross ? (
        <LeftIconWrap onClick={close}>
          <CrossIcon />
        </LeftIconWrap>
      ) : (
        <RightIconWrap onClick={close}>
          <CrossIcon />
        </RightIconWrap>
      ))}
    {back && (
      <LeftIconWrap onClick={back}>
        <ArrowIconPrimary />
      </LeftIconWrap>
    )}
    <ModalTitle>{title}</ModalTitle>
    {clear && (
      <RightIconWrap>
        <StyledLink onClick={clear}>Clear</StyledLink>
      </RightIconWrap>
    )}
    {children && <RightIconWrap>{children}</RightIconWrap>}
  </ModalHeaderWrap>
);

export default ModalHeader;
