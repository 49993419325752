import {noopAction} from 'common/redux/actions';
import {push} from 'connected-react-router';
import {purgeEverything} from 'global/apolloClient';
import urls from 'pages/urls';
import {ofType} from 'redux-observable';
import {from, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {logout, logoutFinished} from './actions';

const allowedRoles = ['affiliate', 'affiliate_employee', 'admin'];

const loginEpic = (action$, store) =>
  action$.pipe(
    ofType('USER/LOGIN'),
    map(({payload}) => (allowedRoles.includes(payload.role) ? noopAction() : logout())),
    catchError(() => of(noopAction()))
  );

const logoutEpic = action$ =>
  action$.pipe(
    ofType('USER/LOGOUT'),
    switchMap(() => from(purgeEverything(true))),
    switchMap(() => of(push(urls.home), logoutFinished())),
    catchError(() => of(push(urls.home), logoutFinished()))
  );

const claimMasqueradeEpic = (action$, store) =>
  action$.pipe(
    ofType('USER/MASQUERADE'),
    switchMap(({payload}) => of(allowedRoles.includes(payload.role) ? noopAction() : logout())),
    switchMap(() => of(push(urls.upcoming))),
    catchError(() => of(noopAction()))
  );

const exports = [loginEpic, logoutEpic, claimMasqueradeEpic];
export default exports;
