// @flow
import {mqMax} from 'common/mediaQuery';
import {center} from 'componentsStyled/Shared';
import theme from 'global/theme';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';

import getSize from './sizes';
import getTheme from './theme';

const buttonStyles = css`
  text-align: center;
  border-radius: 0.2rem;
  text-transform: capitalize;
  font-weight: ${theme.fontWeights.bold};
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
  outline: none;
  letter-spacing: 0.05rem;
  user-select: none;
  border-width: 0.2rem;
  border-style: solid;
  flex-shrink: 0;
  transition: border 0.25s ease, background-color 0.25s ease, color 0.25s ease;

  ${getTheme}
  ${getSize}

  ${p =>
    p.fullwidth &&
    css`
      width: 100%;
    `}

  ${p =>
    p.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}

  ${mqMax.tabletWide} {
    max-width: 90%;
    margin: 1rem 2rem;
  }

  ${p =>
    p.banner &&
    css`
      display: flex;
      width: 90%;
      height: 1.9rem;
      line-height: 0;
      margin: auto;
    `}
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export const StyledLinkButton = styled(Link)`
  ${buttonStyles}
`;

// IMPORTANT! do NOT put these styles to StyledButton, it won't work
// in older Safari <button> element can't use flexbox
// way to come around is to wrap content in another container
export const ButtonContent = styled.span`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonLabel = styled.span`
  ${p =>
    p.isHidden &&
    css`
      visibility: hidden;
    `}
`;

export const CenterWrap = styled.div`
  ${center};
`;
