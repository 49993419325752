// @flow
import theme from 'global/theme';
import React from 'react';

const IconWarn = () => (
  <svg width="22" height="19" viewBox="0 0 22 19">
    <path
      fill={theme.color.white}
      fillRule="evenodd"
      d="M10.1237841.59311983c.0913878-.1661597.2281374-.3029093.3942972-.39429716.4839206-.26615637 1.0919782-.08962352 1.3581346.39429716l9.3087288 16.92496147c.0812047.1476449.1237841.3134157.1237841.4819187 0 .5522847-.4477153 1-1 1H1.69127122c-.16850297 0-.33427377-.0425794-.48191875-.1237841-.48392068-.2661564-.66045353-.874214-.39429716-1.3581346L10.1237841.59311983zM10 6v7h2V6h-2zm0 8v2h2v-2h-2z"
    />
  </svg>
);

export default IconWarn;
