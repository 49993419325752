// @flow
import type {User} from './types';

export const login = (user: User) => ({
  type: 'USER/LOGIN',
  payload: user,
});

export const updateUser = (user: User) => ({
  type: 'USER/UPDATE',
  payload: user,
});

export type Logout = {|
  type: 'USER/LOGOUT',
|};
export const logout = (): Logout => ({
  type: 'USER/LOGOUT',
});

export const logoutFinished = () => ({
  type: 'USER/LOGOUT_FINISHED',
});

export const claimMasquerade = (user: User) => ({
  type: 'USER/MASQUERADE',
  payload: user,
});
