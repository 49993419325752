// @flow
import Button from 'components/Button';
import {selectAppConfig} from 'data/app/selectors';
import type {Booking} from 'data/bookings/types';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import BookingCheckOutModal from 'modals/BookingCheckOutModal';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const BookingCheckoutButton = ({handleCheckout}) => (
  <Button data-cy="checkout-button" onClick={handleCheckout} large>
    Check out
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

type Outter = {|
  onClick?: Function,
  booking: Booking,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleCheckout: props => () => {
      props.openModal(BookingCheckOutModal, {
        booking: props.booking,
      });
    },
  })
);
export default enhancer(BookingCheckoutButton);
