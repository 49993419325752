// @flow
import theme from 'global/theme';
import React from 'react';

type Props = {|
  onClick?: Function,
|};

const CrossIcon = ({onClick}: Props) => (
  <svg width="15" height="15" onClick={onClick}>
    <path
      d="M7.5 6.292L13.541.25A.854.854 0 1 1 14.75 1.46L8.708 7.5l6.042 6.041a.854.854 0 1 1-1.209 1.209L7.5 8.708 1.459 14.75A.854.854 0 1 1 .25 13.54L6.292 7.5.25 1.459A.854.854 0 1 1 1.46.25L7.5 6.292z"
      fill={theme.color.midGrey}
      fillRule="evenodd"
    />
  </svg>
);

export default CrossIcon;
