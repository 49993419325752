import type {Query} from 'common/graphql/types';
import {productItemFragment} from 'data/product/graphql/fragments';
import type {DateString} from 'data/units/date/types';
import gql from 'graphql-tag';
import {path, pathOr} from 'ramda';

import type {Booking} from '../types';
import {bookingInfoFragment} from './fragments';

export const bookingsByAffiliate: Query<Booking[]> = {
  gql: gql`
    query bookingsByAffiliate($filter: ListAffiliateFilter) {
      booking {
        listByAffiliate(filter: $filter) {
          ...bookingInfoFragment
          fulfillmentType
          dispatchDate
          expectedReturnDate
          deliveryDestination {
            name
            company
            address
            instructions
            additionalContact {
              email
              phone
            }
          }
          reservations {
            productItem {
              ...productItemFragment
            }
            status
          }
        }
      }
    }
    ${productItemFragment}
    ${bookingInfoFragment}
  `,
  selector: ['booking', 'listByAffiliate'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
  debounceKey: 'bookingList',
};

export const bookingQuery: Query<Booking> = {
  gql: gql`
    query bookingQuery($id: String) {
      booking {
        get(id: $id) {
          ...bookingInfoFragment
          totalPaid
          fulfillmentType
          deliveryDestination {
            name
            company
            address
            instructions
            additionalContact {
              email
              phone
            }
          }
          dispatchDate
          markedInboundAt
          expectedReturnDate
          reservations {
            id
            checkedOutAt
            productItem {
              ...productItemFragment
            }
            status
            pricingDetail {
              prices
              lateFee
              accessories {
                prices
                name
              }
            }
            details {
              comment
            }
          }
        }
      }
    }
    ${productItemFragment}
    ${bookingInfoFragment}
  `,
  selector: ['booking', 'get'],
  options: callOptions => ({
    pollInterval: 600000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
  debounceKey: 'bookingList',
};

export type BookingSlipItem = {
  assetId: string,
  productName: string,
  variantName: string,
  vendor: string,
  stockLocation: string,
};

export type BookingSlipData = {
  logo: string,
  bookingExternalId: string,
  contactEmail: string,
  contactPhone: string,
  dispatchDate: DateString,
  additionalContactPhone: string,
  additionalContactEmail: string,
  deliveryName: string,
  deliveryAddress: string,
  items: BookingSlipItem,
};

export const packingSlipQuery: Query<BookingSlipData> = {
  gql: gql`
    query getPackingSlipInfo($affiliateId: Int!, $bookingId: String!) {
      affiliate {
        get(id: $affiliateId) {
          logo
        }
      }
      booking {
        get(id: $bookingId) {
          externalId
          order {
            customer {
              user {
                email
                profiles {
                  phoneNumber
                }
              }
            }
          }
          fulfillmentType
          deliveryDestination {
            name
            company
            address
            instructions
            additionalContact {
              email
              phone
            }
          }
          dispatchDate
          markedInboundAt
          expectedReturnDate
          reservations {
            productItem {
              code
              productVariant {
                shelfLocation
                name
                product {
                  name
                  manufacturer {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  transform: data => {
    // Intermediary objects
    const booking = path(['booking', 'get'], data);
    const contactInfo = path(['order', 'customer', 'user'], booking);
    const reservations = pathOr([], ['reservations'], booking);
    const deliveryDetails = path(['deliveryDestination'], booking);

    // Returned values
    const logo = path(['affiliate', 'get', 'logo'], data);
    const bookingExternalId = path(['externalId'], booking);
    const contactEmail = path(['email'], contactInfo);
    const contactPhone = path(['profiles', 0, 'phoneNumber'], contactInfo);
    const additionalContactEmail = path(['additionalContact', 'email'], deliveryDetails);
    const additionalContactPhone = path(['additionalContact', 'phone'], deliveryDetails);
    const dispatchDate = path(['dispatchDate'], booking);
    const deliveryName = path(['name'], deliveryDetails);
    const deliveryAddress = path(['address'], deliveryDetails);

    const items = reservations.map(({productItem}) => {
      const assetId = path(['code'], productItem);
      const productName = path(['productVariant', 'product', 'name'], productItem);
      const variantName = path(['productVariant', 'name'], productItem);
      const vendor = path(['productVariant', 'product', 'manufacturer', 'name'], productItem);
      const stockLocation = path(['productVariant', 'shelfLocation'], productItem);

      return {
        assetId,
        productName,
        variantName,
        vendor,
        stockLocation,
      };
    });

    return {
      logo,
      bookingExternalId,
      contactEmail,
      contactPhone,
      dispatchDate,
      additionalContactPhone,
      additionalContactEmail,
      deliveryName,
      deliveryAddress,
      items,
    };
  },
};
