// @flow
import Loader from 'components/Loader';
import {setAppConfig} from 'data/app/actions';
import {appConfigQuery} from 'data/app/graphql/queries';
import {selectLoggingOut} from 'data/user/selectors';
import {query} from 'global/apolloClient/helpers';
import withConnect from 'hoc/withConnect';
import withOnMount from 'hoc/withOnMount';
import withOnNextProps from 'hoc/withOnNextProps';
import withRouter from 'hoc/withRouter';
import withUser from 'hoc/withUser';
import ErrorPage from 'pages/Error';
import * as React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import Growl from './Growl';
import MasterModal from './MasterModal';
import Notifications from './Notifications';
import Routes from './Routes';

const Content = ({loggingOut, error, loading}) => {
  if (error) return <ErrorPage />;

  if (loading || loggingOut) return <Loader />;

  return (
    <React.Fragment>
      <Routes />
      <Notifications />
      <Growl />
      <MasterModal />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggingOut: selectLoggingOut(state),
});

const mapDispatchToProps = {
  setAppConfig,
};

type Outter = {|
  error: ?string,
|};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withUser(),
  withStateHandlers(
    {
      loading: true,
    },
    {
      setLoading: () => v => ({loading: v}),
    }
  ),
  withHandlers({
    prefetchQueries: props => () => {
      props.setLoading(true);
      Promise.all([query(appConfigQuery)])
        .then(([appConfig]) => {
          console.log('Loaded app config:', appConfig);
          props.setAppConfig(appConfig);
          props.setLoading(false);
        })
        .catch(() => props.setLoading(false));
      props.setLoading(false);
    },
  }),
  withOnMount(props => {
    if (!props.loggingOut) {
      props.prefetchQueries();
    }
  }),
  withOnNextProps((prev, next) => {
    if (prev.loggingOut && !next.loggingOut) {
      prev.prefetchQueries();
    }
  })
);

export default enhancer(Content);
