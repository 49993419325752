// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled from 'styled-components';

export const VerifySetupWrap = styled.div`
  padding: 3rem;
  margin-top: 5rem;
  background: ${theme.color.white};
  width: 550px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqMax.tablet} {
    margin-top: 1rem;
    padding: 1rem;
    position: initial;
    width: 100%;
    transform: translate(0%, 0%);
  }
`;

export const SignatureBlockGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 75%;

  ${mqMax.tablet} {
    margin-right: 50%;
  }
`;
