import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 3rem;
  padding: 2.4rem 4rem;
  display: flex;
  flex-direction: column;
  background: ${theme.color.white};
`;

export const DeliveryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  button {
    margin-left: 0;
    margin-right: 0;

    ${mqMax.phoneWide} {
      width: 100%;
      max-width: 100%;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: normal;
  }

  svg {
    height: 2.4rem;
    width: 3rem;
    flex-shrink: 0;
  }
`;

export const DeliveryBody = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 2fr 1fr 1fr;

  margin-top: 1.6rem;

  h4 {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: normal;
    margin: 0;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin: 0;
  }
`;
