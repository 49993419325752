// @flow
import gql from 'graphql-tag';

export const bookingInfoFragment = gql`
  fragment bookingInfoFragment on Booking {
    status
    bookingId
    externalId
    createdAt
    updatedAt
    affiliateId
    start
    end
    order {
      customer {
        user {
          profiles {
            phoneNumber
            firstName
            lastName
          }
          email
        }
      }
    }
    affiliate {
      inTimezone
    }
  }
`;
