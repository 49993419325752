// @flow
import decode from 'jwt-decode';
import store from 'store';

const ACCESS_TOKEN_KEY = 'CRYPTO/ACCESS_TOKEN';
const REFRESH_TOKEN_KEY: string = 'CRYPTO/REFRESH_TOKEN';

export const getEmailFromAccessToken = (): ?string => {
  try {
    const result = decode(store.get(ACCESS_TOKEN_KEY));
    return result.email;
  } catch (e) {
    return null;
  }
};

export const getAffiliateIdFromAccessToken = (): ?number => {
  try {
    const result = decode(store.get(ACCESS_TOKEN_KEY));
    return result.affiliateId;
  } catch (e) {
    return null;
  }
};

export const purgeAccessToken = () => store.remove(ACCESS_TOKEN_KEY);

export const purgeTokens = () => {
  purgeAccessToken();
  store.remove(REFRESH_TOKEN_KEY);
};

export const setAccessToken = (token: string): void => store.set(ACCESS_TOKEN_KEY, token);

export const setRefreshToken = (token: string): void => store.set(REFRESH_TOKEN_KEY, token);

export const getAccessToken = (): ?string => store.get(ACCESS_TOKEN_KEY);

export const getRefreshToken = (): ?string => store.get(REFRESH_TOKEN_KEY);
