// @flow
import type {TableDefinition} from 'components/Table/types';
import {prop} from 'ramda';

export type CustomerTableRow = {
  name: string,
  email: string,
  phoneNumber: string,
};

export const tableDefinition: TableDefinition<CustomerTableRow> = [
  {
    title: 'name',
    value: prop('name'),
  },
  {
    title: 'email',
    value: prop('email'),
  },
  {
    title: 'phone',
    value: prop('phoneNumber'),
  },
];

export default tableDefinition;
