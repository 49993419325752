// @flow
import type {ProductDamage} from 'data/damages/types';
import React from 'react';

import {Damage, DamageStatusWrap, DamageWrap, Status} from './styled';

type Props = {|
  damage: ProductDamage,
|};

const formatStatus = damage => {
  if (damage.fixed) return <Status> Resolved</Status>;
  return <Status danger> Unresolved</Status>;
};

const DamageStatus = ({damage}: Props) => (
  <DamageStatusWrap>
    <DamageWrap>
      <Damage>{damage.damageType.name}</Damage>
      {formatStatus(damage)}
    </DamageWrap>
  </DamageStatusWrap>
);

export default DamageStatus;
