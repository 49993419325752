// @flow
import BookingTile from 'components/BookingTile';
import TileList from 'components/TileList';
import {TileItemLink} from 'components/TileList/styled';
import {listAccessories} from 'data/accessories/graphql/queries';
import {selectAppConfig} from 'data/app/selectors';
import type {AppConfig} from 'data/app/types';
import type {Booking} from 'data/bookings/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import urls from 'pages/urls';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

type Props = {|
  bookings: Booking[],
  action: 'check out' | 'return',
  appConfig?: AppConfig,
|};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const BookingsList = ({bookings, action, appConfig}: Props) => (
  <React.Fragment>
    <TileList>
      {bookings.map(b => (
        <TileItemLink key={b.bookingId} to={urls.booking(b.bookingId)}>
          <BookingTile booking={b} action={action} />
        </TileItemLink>
      ))}
    </TileList>
  </React.Fragment>
);

const enhancer: HOC<*, Props> = compose(withConnect(mapStateToProps), withQuery(listAccessories));

export default enhancer(BookingsList);
