// @flow
import Button from 'components/Button';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import {ButtonSpread} from 'modals/_Controls/styled';
import {StyledModalHeader} from 'modals/MarkInbound/styled';
import React from 'react';
import type {HOC} from 'recompose';
import {compose} from 'recompose';

type Outter = {|
  close: Function,
  onConfirm: () => Promise<mixed>,
  bookingId: string,
|};

type Props = {|
  ...Outter,
  submit: any => Promise<mixed>,
  loading: boolean,
|};

const MarkInboundModal = ({submit, close}: Props) => {
  return (
    <ModalBody close={close} maxWidth={70}>
      <StyledModalHeader title="Has the renter sent this booking back?" />
      <ButtonSpread>
        <Button onClick={close} secondary>
          CANCEL
        </Button>
        <Button onClick={submit}>YES, MARK AS INBOUND</Button>
      </ButtonSpread>
    </ModalBody>
  );
};

const enhancer: HOC<*, Outter> = compose(
  withSubmit({
    submit: props => async () => props.onConfirm(),
    onSuccess: props => props.close,
    errorText: 'Something went wrong!',
  })
);

export default enhancer(MarkInboundModal);
