// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import type {ReservationDetails} from 'data/reservations/types';
import type {Affiliate, Profile} from 'data/user/types';
import * as React from 'react';

type Props = {
  profile: Profile,
  productName: string,
  affiliate: Affiliate,
  details: ReservationDetails,
};

const InfoHeader = ({profile, productName, affiliate, details}: Props) => (
  <React.Fragment>
    <Text>
      A {affiliate.name} employee and {profile.firstName} {profile.lastName} confirm that they have
      verified the ski setup and confirm that it is correct.
    </Text>
    <br />
    <Text bold black center>
      {productName}
    </Text>
  </React.Fragment>
);

export default InfoHeader;
