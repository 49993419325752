// @flow
import {center} from 'componentsStyled/Shared';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const CheckboxWrap = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border: 0.2rem solid ${theme.color.lightGrey};
  border-radius: 0.4rem;
  position: relative;
  flex-shrink: 0;

  ${p =>
    p.checked &&
    css`
      background: ${theme.color.primary};
      border-color: ${theme.color.primary};
    `}

  svg {
    ${center};
  }
`;
