// @flow
import {filterDates} from 'data/app/actions';
import {selectDates} from 'data/app/selectors';
import {openModal} from 'data/modals/actions';
import withAffliate from 'hoc/withAffiliate';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, compose} from 'recompose';

import DateFilter from './DateFilter';
import Search from './Search';
import {FiltersWrap, MainFilterWrap} from './styled';

const MainFilter = ({affiliate, onChange, value, filterDates, selectedDates}) => {
  return (
    <MainFilterWrap>
      <FiltersWrap>
        <DateFilter selectedDates={selectedDates} filterDates={filterDates} />
      </FiltersWrap>
      <Search onChange={onChange} value={value} />
    </MainFilterWrap>
  );
};

const mapStateToProps = state => ({
  selectedDates: selectDates(state),
});

const mapDispatchToProps = {
  filterDates,
  openModal,
};

type Outter = {|
  onChange: (?string) => mixed,
  value: ?string,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withAffliate()
);

export default enhancer(MainFilter);
