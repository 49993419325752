// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {Select} from './styled';

type Outter = {
  ...$Exact<ReqOutter>,
  title?: string,
  list: any,
  width?: number,
  header?: string,
  defaultValue?: number,
};

const SelectFromList = ({
  title,
  list,
  defaultValue = 0,
  handleChange,
  width,
  header = 'Choose...',
}) => (
  <div>
    <Text>{title}</Text>
    <Select className="select" defaultValue={defaultValue} onChange={handleChange} width={width}>
      <option value="0" disabled>
        {header}
      </option>
      {list.map((index, i) => (
        <option key={i} value={index.id}>
          {index.value}
        </option>
      ))}
    </Select>
  </div>
);

const enhancer: HOC<*, Outter> = compose(
  withFormField,
  withHandlers({
    handleChange: props => event => props.onChange(event.target.value),
  })
);

export default enhancer(SelectFromList);
