// @flow
import type {Reservation} from 'data/reservations/types';
import urls from 'pages/urls';
import * as React from 'react';

import ReservationTile from './ReservationTile';
import {ReservationItemLink} from './styled';

type Props = {|
  reservations: Reservation[],
|};

const ReservationListSmall = ({reservations}: Props) => (
  <React.Fragment>
    {reservations.map(r => (
      <ReservationItemLink key={r.id} to={urls.reservation(r.id)}>
        <ReservationTile reservation={r} />
      </ReservationItemLink>
    ))}
  </React.Fragment>
);

export default ReservationListSmall;
