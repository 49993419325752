// @flow
import theme from 'global/theme';
import React from 'react';

const ArrowIconSharp = () => (
  <svg width="9" height="14">
    <path
      d="M7.303 5.232L2-.07.232 1.697 5.536 7 .232 12.303 2 14.071 9.071 7 7.303 5.232z"
      fill={theme.color.black}
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowIconSharp;
