// @flow
import ProductItemPreview from 'components/ProductItemPreview';
import {getProductItemDetailQuery} from 'data/product/graphql/queries';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';
import type {HOC} from 'recompose';

const QuiverDetail = ({data}) => (
  <SubPage title="item detail">
    <ProductItemPreview product={data} />
  </SubPage>
);

type Outter = {|
  match: {
    params: {
      id: number,
    },
  },
|};

const enhancer: HOC<*, Outter> = withQuery(getProductItemDetailQuery, {
  variables: ({match}) => ({
    id: match.params.id,
  }),
});

export default enhancer(QuiverDetail);
