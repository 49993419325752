// @flow
import Button from 'components/Button';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import {ButtonSpread, StyledModalHeader} from 'modals/MarkInStock/styled';
import React from 'react';
import {type HOC, compose} from 'recompose';

type Outter = {|
  close: Function,
  onConfirm: () => Promise<mixed>,
  productItemId: number,
|};

type Props = {|
  ...Outter,
  submit: any => Promise<mixed>,
  loading: boolean,
|};

const MarkInStockModal = ({submit, close}: Props) => {
  return (
    <ModalBody close={close} maxWidth={70}>
      <StyledModalHeader title="Has this item finished post-processing?" />
      <ButtonSpread>
        <Button onClick={close} secondary>
          CANCEL
        </Button>
        <Button onClick={submit}>YES, MARK IN STOCK</Button>
      </ButtonSpread>
    </ModalBody>
  );
};

const mapDispatchToProps = {
  openModal,
};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withSubmit({
    submit: props => async () => props.onConfirm(),
    onSuccess: props => props.close,
    errorText: 'Something went wrong!',
  })
);

export default enhancer(MarkInStockModal);
