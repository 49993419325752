// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const MenuWrap = styled.div`
  display: flex;

  ${mqMax.tabletWide} {
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderWrap = styled.header`
  position: fixed;
  width: 100%;
  z-index: ${theme.zIndices.header};
  background: ${theme.color.white};
  border-bottom: 0.1rem solid ${theme.color.ultralight};
`;

export const Line = styled.div`
  border-bottom: 0.1rem solid ${theme.color.ultralight};
`;

export const PageContent = styled.main`
  display: flex;
  padding-top: 6rem;
  ${props =>
    props.isMasquerading &&
    css`
      padding-top: 12rem;
    `}
  justify-content: center;

  ${mqMax.tabletWide} {
    flex-direction: column;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20.9rem);
  margin-bottom: 24rem;
  height: 100%;

  ${mqMax.tabletWide} {
    display: block;
    align-items: center;
    width: 100%;
  }
`;
