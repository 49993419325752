// @flow
import {closeModal} from 'data/modals/actions';
import {selectModal} from 'data/modals/selectors';
import withConnect from 'hoc/withConnect';
import React from 'react';
import {type HOC, branch, compose, renderNothing} from 'recompose';

const MasterModal = ({modal, closeModal}) => {
  if (!modal) {
    return;
  }

  const onClose = () => {
    if (modal.opts && modal.opts.onClose) {
      modal.opts.onClose();
    }

    closeModal();
  };

  const Component = modal.type;

  return <Component {...modal.data} close={onClose} />;
};

const mapStateToProps = state => ({
  modal: selectModal(state),
});

const mapDispatchToProps = {
  closeModal,
};

const enhancer: HOC<*, {||}> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  branch(props => !props.modal, renderNothing)
);

export default enhancer(MasterModal);
