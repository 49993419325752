// @flow
import theme from 'global/theme';
import styled from 'styled-components';

export const TableWrap = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tr {
    border-bottom: 0.2rem solid ${theme.color.ultralight};
  }
`;
