// @flow
import type {Query} from 'common/graphql/types';
import type {Affiliate, AffiliateEmployee, User} from 'data/user/types';
import gql from 'graphql-tag';
import {path} from 'ramda';

import {affiliateEmployeeFragment, userFragment} from './fragments';

export const getUserQuery: Query<User> = {
  gql: gql`
    query getUserQuery {
      user {
        getUser {
          ...userFragment
        }
      }
    }
    ${userFragment}
  `,
  selector: ['user', 'getUser'],
};

export const getPaymentMethodQuery: Query<{|
  brand: string,
  last4: string,
|}> = {
  gql: gql`
    query getPaymentMethod {
      payment {
        getCustomerPaymentMethod {
          card {
            brand
            last4
          }
        }
      }
    }
  `,
  selector: ['payment', 'getCustomerPaymentMethod', 'card'],
};

export const getAffiliateAllowedStatusQuery: Query<Affiliate> = {
  gql: gql`
    query getAffiliateAllowedStatusQuery($id: Int!) {
      affiliate {
        get(id: $id) {
          allowed
        }
      }
    }
  `,
  selector: ['affiliate', 'get'],
  options: callOptions => ({
    pollInterval: 60000,
    fetchPolicy: callOptions.fetchPolicy || 'cache-and-network',
  }),
};

export const getAffiliateQuery: Query<Affiliate> = {
  gql: gql`
    query getAffiliateQuery($id: Int!) {
      affiliate {
        get(id: $id) {
          name
          allowed
          location {
            country {
              currency
            }
          }
          categories {
            categoryId
            name
          }
          enableDeliveryBuffers
          inboundBufferDays
          inStoreFulfilmentInstructions
          deliveryFulfilmentInstructions
        }
      }
    }
  `,
  selector: ['affiliate', 'get'],
};

export const AffiliateEmployeeQuery: Query<AffiliateEmployee[]> = {
  gql: gql`
    query AffiliateEmployeeQuery($filter: AffiliateEmployeeListFilter) {
      affiliateEmployee {
        list(filter: $filter) {
          ...affiliateEmployeeFragment
        }
      }
    }
    ${affiliateEmployeeFragment}
  `,
  selector: ['affiliateEmployee', 'list'],
  options: () => ({
    fetchPolicy: 'network-only',
  }),
};

export const affiliateEmployeePinCodeQuery: Query<AffiliateEmployee[]> = {
  gql: gql`
    query AffiliateEmployeePinCodeQuery($id: Int!, $pinCode: String!) {
      affiliateEmployee {
        validatePinCode(id: $id, pinCode: $pinCode)
      }
    }
  `,
};

export const getIsNearClosingTimeQuery: Query<{
  isNearClosingTime: boolean,
  returns: number,
}> = {
  gql: gql`
    query getIsNearClosingTimeQuery(
      $amount: Int!
      $unit: String!
      $input: DailyReservationsOverviewFilter
    ) {
      affiliate {
        isNearClosingTime(amount: $amount, unit: $unit)
      }
      reservation {
        getDailyReservationOverviews(input: $input) {
          returns
        }
      }
    }
  `,
  transform: data => ({
    // $Ramda
    isNearClosingTime: path(['affiliate', 'isNearClosingTime'], data),
    // $Ramda
    returns: path(['reservation', 'getDailyReservationOverviews', 0, 'returns'], data),
  }),
  options: () => ({
    fetchPolicy: 'network-only',
    pollInterval: 900000,
  }),
};

export const claimMasquerade: Query<User> = {
  gql: gql`
    query claimMasquerade($claimToken: String!) {
      auth {
        claimMasquerade(claimToken: $claimToken) {
          user {
            ...userFragment
          }
        }
      }
    }
    ${userFragment}
  `,
  selector: ['auth', 'claimMasquerade', 'user'],
};
