// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import * as React from 'react';

import {ActiveMonth, Controller, Divisor, Title, Wrap} from './styled';

type Props = {
  prev: number => number,
  prevMonth: moment$Moment,
  month: moment$Moment,
  next: number => number,
  nextMonth: moment$Moment,
};

const CalendarControllers = ({prev, prevMonth, month, next, nextMonth}: Props) => (
  <React.Fragment>
    <Desktop>
      <Controller onClick={prev}>
        <ArrowIcon rotation={'left'} />
        {prevMonth.format('MMMM YYYY')}
      </Controller>

      <ActiveMonth>
        <Title>{month.format('MMMM YYYY')}</Title>
        <Divisor />
      </ActiveMonth>

      <Controller onClick={next}>
        {nextMonth.format('MMMM YYYY')}
        <ArrowIcon rotation={'right'} />
      </Controller>
    </Desktop>

    <Mobile>
      <Wrap>
        <Controller onClick={prev}>
          <ArrowIcon rotation={'left'} />
        </Controller>

        <Title>{month.format('MMMM YYYY')}</Title>

        <Controller onClick={next}>
          <ArrowIcon rotation={'right'} />
        </Controller>
      </Wrap>
    </Mobile>
  </React.Fragment>
);

export default CalendarControllers;
