// @flow
import {stopPropagation} from 'common/events';
import * as React from 'react';

import {Content, Overlay, OverlayWrapperWrap} from './styled';

type Props = {|
  isOpen: boolean,
  onClose: Function,
  children: React.Node,
|};

const handleClick = fn => e => {
  e.preventDefault();
  fn(e);
};

const OverlayWrapper = ({isOpen, onClose, children}: Props) => (
  <OverlayWrapperWrap onClick={stopPropagation} isOpen={isOpen}>
    <Overlay open={isOpen} onClick={handleClick(onClose)} />
    <Content>{children}</Content>
  </OverlayWrapperWrap>
);

export default OverlayWrapper;
