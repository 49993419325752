// @flow
import type {ModalType} from './types';

type NotNeeded = {|
  close: Function,
|};

export const openModal = <T>(
  type: ModalType<T>,
  // $ExpectError
  data: $Diff<T, NotNeeded>,
  opts?: $Exact<{onClose?: Function}>
) => ({
  type: 'MODALS/OPEN_MODAL',
  payload: {type, data, opts},
});

export const closeModal = () => ({
  type: 'MODALS/CLOSE_MODAL',
});
