// @flow
import ReservationPreview from 'components/ReservationPreview';
import type {Reservation} from 'data/reservations/types';
import * as React from 'react';

type Props = {|
  reservation: Reservation,
|};

const ReservationDetail = ({reservation}: Props) => <ReservationPreview data={reservation} />;

export default ReservationDetail;
