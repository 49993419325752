// @flow
import OverlayWrapper from 'components/OverlayWrapper';
import {Br} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import withOpen from 'hoc/withOpen';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import {TipWrap, ToolTipContainer} from './styled';
import TipIcon from './TipIcon';

const ToolTip = ({header, text, isOpen, toggleOpen, top}) => {
  if (!Array.isArray(text)) text = [text];

  return (
    <OverlayWrapper isOpen={isOpen} close={toggleOpen} transparent>
      <ToolTipContainer onClick={toggleOpen}>
        <TipWrap active={isOpen} header={header} text={text} top={top}>
          {header && (
            <Text small bold lh={'1.8'}>
              {header}
              <Br mb={0.9} />
            </Text>
          )}
          {text.map((i, key) => (
            <Text small key={key} lh={'1.8'}>
              {i}
              <Br mb={0.6} />
            </Text>
          ))}
        </TipWrap>
        <TipIcon />
      </ToolTipContainer>
    </OverlayWrapper>
  );
};

type Outter = {
  header?: string,
  text: Array<string> | string,
  top?: number,
};

const enhancer: HOC<*, Outter> = compose(withOpen);

export default enhancer(ToolTip);
