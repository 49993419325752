// @flow
import React from 'react';

import LoggedInNav from './LoggedInNav';
import LoggedOutNav from './LoggedOutNav';
import {MainNavWrap} from './styled';

type Props = {|
  loggedIn: boolean,
  title?: string,
|};

const MainNav = ({loggedIn, title}: Props) => (
  <MainNavWrap>{loggedIn ? <LoggedInNav title={title} /> : <LoggedOutNav />}</MainNavWrap>
);

export default MainNav;
