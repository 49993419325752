// @flow
import {breakpoints} from 'common/mediaQuery';
import MediaQuery from 'components/MediaQuery';
import * as React from 'react';

type Props = {|
  children: React.Node,
|};

const Desktop = ({children}: Props) => (
  <MediaQuery breakpoint={breakpoints.tabletWide}>{matches => !matches && children}</MediaQuery>
);

export default Desktop;
