// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const FilterInputWrap = styled.div`
  font-size: 1.4rem;
  line-height: 3.2rem;
  padding: 0 1.2rem;
  white-space: nowrap;
  margin-right: 0.6rem;
  border-radius: 0.2rem;
  color: ${theme.color.darkGrey};
  background: ${theme.color.lightGrey};
  cursor: pointer;

  ${p =>
    !p.empty &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};
    `}
`;
