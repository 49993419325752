// @flow
import moment from 'moment';
import {range, splitEvery} from 'ramda';

export const getWeeks = (month: moment$Moment): any => {
  // Get first weekday
  const thisMonthStartsWith = month.startOf('month').weekday();

  // Get information about previous month
  const lastMonth = month.clone().subtract(1, 'months');
  const daysInLastMonth = lastMonth.daysInMonth();

  // Get the number of days from the previous month that
  // we still render as empty boxes in the current month view
  const daysFromLastMonth = range(
    daysInLastMonth + 1 - thisMonthStartsWith,
    daysInLastMonth + 1
  ).map(d => undefined);

  // Get day count of this month
  const daysFromThisMonth = range(1, month.daysInMonth() + 1).map(d => month.clone().date(d));

  // Gives back an array of week rows
  // where each day is a column
  const weeks = splitEvery(7, daysFromLastMonth.concat(daysFromThisMonth));

  return weeks;
};

export const isToday = (day: moment$Moment) => day && day.isSame(moment.utc(), 'day');

export const isBeforeToday = (day: moment$Moment) => day && day.isBefore(moment.utc(), 'day');
