// @flow
import type {FeetInch, InchFraction} from './types';

export const mmToFeetInch = (mm: number): FeetInch => {
  const feet = Math.floor(mm / 304.8);
  const inches = Math.round(((mm / 304.8) % 1) * 12);

  // because of decimal precision or rounding there there can be 12 inches which is 1 feet
  if (inches === 12) {
    return {
      feet: feet + 1,
      inches: 0,
    };
  }

  return {
    feet,
    inches,
  };
};

export const imperialToMm = (feet: number, inches: number) => feet * 304.8 + inches * 25.4;

// source https://stackoverflow.com/questions/38891250/convert-to-fraction-inches
export const mmToInchFractions = (mm: number): InchFraction => {
  const inches = mm / 25.4;

  const integer = Math.floor(inches);
  let denominator = 16;
  let numerator = Math.round((inches % 1) * denominator);

  while (numerator % 2 === 0 && denominator % 2 === 0) {
    numerator /= 2;
    denominator /= 2;
  }

  return {
    integer,
    numerator,
    denominator,
  };
};

export const mmToCm = (mm: number) => mm / 10;

export const cmToMm = (cm: number) => cm * 10;
