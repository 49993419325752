//@flow
//$ReactHooks
import {useCallback, useEffect, useRef} from 'react';

type Options = {
  root?: ?Node,
  rootMargin?: string,
  threshold?: Array<number> | number,
};

export function useIntersectionObserver(action: Function, options: Options) {
  const ref = useRef();
  const callback = useCallback(
    entries => {
      if (entries[0].isIntersecting) {
        action(); //do some action
      }
    },
    [action]
  );

  useEffect(() => {
    const scroll = new IntersectionObserver(callback, options);

    if (ref.current) {
      scroll.observe(ref.current);
    }
    return () => {
      scroll.disconnect();
    };
  }, [callback, options, ref]);
  return ref;
}
