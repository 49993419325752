// @flow
import {mq} from 'common/mediaQuery';
import {injectGlobal} from 'styled-components';
import styledNormalize from 'styled-normalize';

import theme from './theme';

/* eslint-disable flowtype/no-unused-expressions, no-unused-expressions */
injectGlobal`
  ${styledNormalize};
  html {
    font-size: 62.5%;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-size: 1.6rem;
    color: ${theme.color.black};
    background: ${theme.color.tone};

    &, button, input, option, select, textarea {
      font-family: 'Arial', sans-serif;
    }

    &.fontLoaded {
      &, button, input, option, select, textarea {
        font-family: 'Poppins', 'Poppins', sans-serif;
      }
    }
  }

  .overflowHidden {
    overflow: hidden;
    position: fixed;

    ${mq.tabletWide} {
      overflow: initial;
      position: initial;
    }
  }

  a[class] {
    text-decoration: none;
  }

  button {
    background: none;
    padding: 0;

    /* Additional button normalization missing from normalize CSS */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    padding: 0;
    list-style: none;
  }
`;
