// @flow
import {StatusText} from 'components/ProductItemActionPreview/Detail/styled';
import {getStatus} from 'components/Status/QuiverStatus/helpers';
import {Text} from 'componentsStyled/Typography/Texts';
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import type {ProductItem} from 'data/product/types';
import React from 'react';

type Props = {|
  productItem: ProductItem,
|};

const Detail = ({productItem}: Props) => (
  <React.Fragment>
    <Title>{productItem.productVariant.product.name}</Title>
    <SubTitle>{productItem.productVariant.product.manufacturer.name}</SubTitle>
    <Text>
      is <StatusText>{getStatus(productItem.status).status}</StatusText>
    </Text>
    <Text>Asset ID: {productItem.code}</Text>
    <Text>Shelf Location: {productItem.productVariant.shelfLocation}</Text>
  </React.Fragment>
);

export default Detail;
