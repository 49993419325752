// @flow
import {FieldTitle, Title} from 'componentsStyled/Typography/Titles';
import styled from 'styled-components';

export const DamageStatusWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 12rem;
  flex-shrink: 0;
  padding: 1rem;
`;

export const DamageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Status = styled(FieldTitle)`
  margin-top: -0.8rem;
  font-weight: normal;
`;

export const Damage = styled(Title)`
  font-size: 1.5rem;
`;
