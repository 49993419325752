// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

const StyledTextArea = styled.textarea`
  border: solid 0.2rem ${theme.color.lightGrey};
  color: ${theme.color.darkGrey};
  border-radius: 0.4rem;
  background: ${theme.color.white};
  padding: 0.8rem;
  font-size: 1.6rem;
  font-weight: ${theme.fontWeights.normal};
  width: 100%;
  outline: none;

  &:focus {
    border-color: ${theme.color.primary};
    z-index: ${theme.zIndices.minimal};
  }

  &::placeholder {
    color: ${theme.lighten(theme.color.darkGrey, 0.5)};

    ${mqMax.tabletWide} {
      font-size: 1.3rem;
    }
  }

  &:disabled {
    background: #f7fafc;
  }

  ${p =>
    p.resize &&
    css`
      resize: ${p.resize};
    `}

  ${p =>
    p.hasError &&
    css`
      background-color: #fff5f5;
      border: 2px solid ${theme.color.error};
    `}
`;

export default StyledTextArea;
