// @flow
import theme from 'global/theme';
import React from 'react';

const SearchIcon = () => (
  <svg width="17" height="17">
    <path
      fill={theme.color.midGrey}
      fillRule="evenodd"
      d="M12.613 11.183l3.398 3.399a1 1 0 0 1 0 1.414l-.015.015a1 1 0 0 1-1.414 0l-3.399-3.398a7 7 0 1 1 1.43-1.43zM7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10z"
    />
  </svg>
);

export default SearchIcon;
