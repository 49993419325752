// @flow
import Desktop from 'components/Media/Desktop';
import {Text} from 'componentsStyled/Typography/Texts';
import {inventoryOverviewQuery} from 'data/product/graphql/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';

import {Line, Link, Number} from '../styled';

const InventoryOverview = ({data}) => (
  <React.Fragment>
    <Link>
      <Number>{data.online}</Number>
      <Text black book>
        Online
      </Text>
    </Link>
    <Line />
    <Link>
      <Number>{data.offline}</Number>
      <Text black book>
        Out of Service
      </Text>
    </Link>
    <Line />
    <Link>
      <Number>{data.inRepair}</Number>
      <Text black book>
        In Repair
      </Text>
    </Link>
    <Line />
    <Link>
      <Number>{data.total}</Number>
      <Text black book>
        Total
      </Text>
    </Link>
    <Desktop>
      <Line />
    </Desktop>
  </React.Fragment>
);

const enhancer: HOC<*, {||}> = withQuery(inventoryOverviewQuery);

export default enhancer(InventoryOverview);
