// @flow
import {mq, mqMax} from 'common/mediaQuery';
import {ModalTitle} from 'componentsStyled/Typography/Titles';
import ModalHeader from 'modals/_Header';
import styled from 'styled-components';

export const StyledModalHeader = styled(ModalHeader)`
  ${ModalTitle} {
    font-size: 2.4rem;
    text-transform: none;
  }
`;

export const ButtonSpread = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: wrap;

  ${mqMax.tablet} {
    flex-direction: column;
    width: 100%;
  }

  //Override default spacing for buttons
  button {
    margin: 0;

    ${mq.tablet} {
      min-width: initial;
    }
  }
`;
