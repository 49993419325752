// @flow
import Button from 'components/Button';
import {markBookingInbound} from 'data/bookings/graphql/mutations';
import type {Booking} from 'data/bookings/types';
import {openModal} from 'data/modals/actions';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import MarkInboundModal from 'modals/MarkInbound';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const MarkInbound = ({onClick, disabled}) => (
  <Button disabled={disabled} onClick={onClick} secondary>
    Mark as inbound
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  booking: Booking,
  disabled: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(markBookingInbound),
  withSubmit({
    errorText: 'Failed to mark booking as inbound, please try again.',
    successText: 'Booking marked as inbound',
  }),
  withHandlers({
    onClick:
      ({booking, submit, openModal}) =>
      () => {
        const {bookingId} = booking;

        return openModal(MarkInboundModal, {
          bookingId,
          onConfirm: () => submit({bookingId}),
        });
      },
  })
);
export default enhancer(MarkInbound);
