// @flow
import styled from 'styled-components';

export const VariantVariableWrap = styled.div`
  display: flex;
  align-items: center;
`;
export const VariantVariableLabel = styled.div`
  min-width: 5rem;
`;

export const VariantVariableOption = styled.div`
  margin-left: 0.5rem;
`;
