// @flow
import DamageList from 'components/DamageList';
import ReservationListSmall from 'components/ReservationListSmall';
import Tabs from 'components/Tabs';
import {Offset} from 'componentsStyled/Layout/Offset';
import type {ProductItem} from 'data/product/types';
import {
  activeReservationStatuses,
  cancelledReservationStatuses,
  completedReservationStatuses,
  preCheckoutReservationStatuses,
} from 'data/reservations/constants';
import type {Reservation, ReservationStatus} from 'data/reservations/types';
import {groupBy, mapObjIndexed, reverse, values} from 'ramda';
import React from 'react';

type Props = {|
  product: ProductItem,
|};

const groupReservationsByStatus = (reservations: Reservation[], statuses: ReservationStatus[]) => {
  const groupedData = groupBy(x => x.status, reservations);
  const groupedReservations = [];
  statuses.forEach(status => {
    if (groupedData.hasOwnProperty(status)) {
      groupedData[status].map(reservation => groupedReservations.push(reservation));
    }
  });
  return groupedReservations;
};

const ReservationSummary = ({product}: Props) => {
  const {reservations, damages} = product;
  const upcomingReservations = groupReservationsByStatus(
    reservations,
    preCheckoutReservationStatuses
  );
  const pastReservations = groupReservationsByStatus(reservations, completedReservationStatuses);
  const currentReservations = groupReservationsByStatus(reservations, activeReservationStatuses);
  const cancelledReservations = groupReservationsByStatus(
    reservations,
    cancelledReservationStatuses
  );
  const groups = {
    current: currentReservations || [],
    upcoming: reverse(upcomingReservations || []),
    past: pastReservations || [],
    cancelled: cancelledReservations || [],
    damages: damages || [],
  };
  const currentEmpty = groups.current.length;
  const nonEmptyIndex = currentEmpty
    ? values(groups).findIndex(x => x.length > 0)
    : // if possible, prefer current reservations
      1;

  const tabs = mapObjIndexed(
    (v, k, obj) => (
      <Offset>
        {k === 'damages' ? (
          <DamageList damages={obj[k]} productId={product.id} />
        ) : (
          <ReservationListSmall reservations={obj[k]} />
        )}
      </Offset>
    ),
    groups
  );

  return <Tabs tabs={tabs} startingIndex={nonEmptyIndex === -1 ? 0 : nonEmptyIndex} centerSwitch />;
};

export default ReservationSummary;
