// @flow
import StyledLink from 'components/StyledLink';
import {SubTitle} from 'componentsStyled/Typography/Titles';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const SubGroupWrap = styled.div`
  display: flex;
  align-items: center;
  ${p =>
    p.isOpened &&
    css`
      margin-bottom: 2rem;
    `}

  ${p =>
    p.center &&
    css`
      justify-content: center;
    `}

  &:hover {
    cursor: pointer;
  }
`;

export const UnitSelectorTitle = styled(SubTitle)`
  line-height: 1;
`;

export const Link = styled(StyledLink)`
  display: row;
  width: 100%;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.book};
  text-align: left;
  border: 0;
  color: ${theme.color.black};
  margin-bottom: 1.5rem;

  &.active {
    opacity: 0.5;
  }
`;

export const UnitLabel = styled.div`
  color: ${theme.color.midGrey};
  padding: 0;
  font-size: 1.35rem;
  font-weight: ${theme.fontWeights.normal};
  align-self: center;
  outline: none;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  ${p =>
    p.active &&
    css`
      color: ${theme.color.primaryDark};
    `}
`;

export const UnitWrap = styled.div`
  display: block;
  font-size: 1.6rem;
  color: ${theme.color.black};
  line-height: 4rem;
  padding: 0 2rem;
  cursor: pointer;
`;
