// @flow
import Desktop from 'components/Media/Desktop';
import {Text} from 'componentsStyled/Typography/Texts';
import {selectAppConfig} from 'data/app/selectors';
import {reservationOverviewQuery} from 'data/reservations/graphql/queries';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose} from 'recompose';

import {Line, Link, Number} from '../styled';

const ReservationOverview = ({data, appConfig}) => (
  <React.Fragment>
    <Link to={urls.upcoming}>
      <Number>{data.checkout}</Number>
      <Text black book>
        Upcoming
      </Text>
    </Link>
    <Line />
    <Link to={urls.returns}>
      <Number>{data.returns}</Number>
      <Text black book>
        Returns
      </Text>
    </Link>
    <Line />
    <Link to={urls.returns}>
      <Number alert={data.overdue > 0}>{data.overdue}</Number>
      <Text black book>
        Overdue
      </Text>
    </Link>
    <Desktop>
      <Line />
    </Desktop>
  </React.Fragment>
);

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, {||}> = compose(
  withConnect(mapStateToProps),
  withQuery(reservationOverviewQuery)
);

export default enhancer(ReservationOverview);
