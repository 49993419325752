// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const DateFilterWrap = styled.div`
  display: flex;
  margin: 0 1rem;

  ${mqMax.phoneWide} {
    margin-left: 0rem;
  }
`;
