import {Row} from 'componentsStyled/Layout/Grid';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  height: 19rem;

  img {
    top: 1rem;
    text-align: center;
  }

  ${p =>
    p.darkFilter &&
    css`
      filter: brightness(60%);
    `}
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  background: ${theme.color.ultralight};
  width: 100%;
`;

export const AdditionalItemsCount = styled.div`
  position: absolute;
  margin-top: 8rem;
  margin-left: 22rem;
  font-size: 4rem;
  color: #ffffff;
`;

export const BookingTileHeader = styled(Row)`
  //Padding + margin to indent content but not border
  padding: 0 1.6rem 0.8rem 1.6rem;
  margin: 0 -1.6rem 1.6rem -1.6rem;
  border-bottom: 1px solid ${theme.color.lightGrey};
`;
