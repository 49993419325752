// @flow
import {Wrap} from 'componentsStyled/Layout/Wrap';
import theme from 'global/theme';
import styled from 'styled-components';

export const ReservationTileWrap = styled(Wrap)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  border-top: 0.2rem solid ${theme.color.ultralight};
  border-bottom: 0.2rem solid ${theme.color.ultralight};
`;

export const Body = styled.div`
  padding: 2.5rem 2.5rem 2.5rem 1.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 0.2rem solid ${theme.color.ultralight};
  position: relative;
`;

export const ArrowWrap = styled.div`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
`;
