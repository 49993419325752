// @flow
import {DimensionText} from 'components/Tile/Shared/Actions';
import {Text} from 'componentsStyled/Typography/Texts';
import type {ProductVariant} from 'data/product/types';
import React from 'react';

type Props = {|
  productVariant: ProductVariant,
  compact?: boolean,
  tile?: boolean,
  center?: boolean,
|};

const ProductDimension = ({productVariant, compact, tile, center = false}: Props) => {
  if (tile) {
    return (
      <div>
        <DimensionText>{productVariant.name}</DimensionText>
        <DimensionText>{productVariant.shelfLocation}</DimensionText>
      </div>
    );
  }

  if (compact) {
    return (
      <div>
        <Text center={center} book small>
          {productVariant.name}
        </Text>
        <Text center={center} book small>
          {productVariant.shelfLocation}
        </Text>
      </div>
    );
  }

  return (
    <div>
      <Text center={center} bigOnDesktop>
        {productVariant.name}
      </Text>
      <Text center={center} bigOnDesktop>
        {productVariant.shelfLocation}
      </Text>
    </div>
  );
};

export default ProductDimension;
