// @flow
import withUser from 'hoc/withUser';
import React from 'react';

import {AvatarWrap} from './styled';

const Avatar = ({big, user}) => {
  const {email} = user;
  const text = `${email[0]}${email[1]}`;
  const aria = email;

  return (
    <AvatarWrap big={big} aria-label={aria} title={aria}>
      {text}
    </AvatarWrap>
  );
};

export default withUser()(Avatar);
