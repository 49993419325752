// @flow
import {emify} from 'common/mediaQuery';
import * as React from 'react';
import ReactMediaQuery from 'react-responsive';

type Props = {|
  children: boolean => React.Node,
  breakpoint: number,
|};

const MediaQuery = ({breakpoint, children}: Props) => (
  <ReactMediaQuery minWidth={emify(breakpoint)}>
    {(matches: boolean) => children(!matches)}
  </ReactMediaQuery>
);

export default MediaQuery;
