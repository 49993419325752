// @flow
import {selectAppConfig} from 'data/app/selectors';
import {reservationDetailQuery} from 'data/reservations/graphql/queries';
import type {Reservation as ReservationType} from 'data/reservations/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import SubPage from 'pages/_Page/SubPage';
import React from 'react';
import {type HOC, compose} from 'recompose';

import ReservationDetail from './ReservationDetail';

const Reservation = ({match, data, appConfig}) => (
  <SubPage title={'Reservation'}>
    <ReservationDetail reservation={data} />
  </SubPage>
);

type Outter = {|
  match: any,
  data: ReservationType,
|};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withQuery(reservationDetailQuery, {
    variables: ({match}) => match.params.id,
  })
);

export default enhancer(Reservation);
