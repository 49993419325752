// @flow
import errorMap from 'common/graphql/getErrorMessage/errorMap';
import Button from 'components/Button';
import {Text} from 'componentsStyled/Typography/Texts';
import {notificationError} from 'data/notifications/actions';
import {isPrepaid} from 'data/stripe/helpers';
import type {StripeTokenId} from 'data/stripe/types';
import withConnect from 'hoc/withConnect';
import withInjectStripe from 'hoc/withInjectStripe';
import ModalControls from 'modals/_Controls';
import {CancelButtonWrap} from 'modals/BookingReturn/styled';
import React from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement} from 'react-stripe-elements';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import {LowerStripeElements, stripeStyle, StyledForm, StyledLabel} from './styled';

// Component needs to be wrapped with the StripeInitialisation form component
// for stripe authentication requests to work
const CardInput = ({close, loading, handleSubmit}) => (
  <StyledForm onSubmit={handleSubmit} autoComplete="new-cc">
    <StyledLabel>
      <Text black>Card number</Text>
      <CardNumberElement id="cardNumber" style={stripeStyle} />
    </StyledLabel>
    <LowerStripeElements>
      <StyledLabel>
        <Text black>Expiry</Text>
        <CardExpiryElement id="expiry" style={stripeStyle} />
      </StyledLabel>
      <StyledLabel>
        <Text black>CVC</Text>
        <CardCVCElement id="cvc" style={stripeStyle} />
      </StyledLabel>
    </LowerStripeElements>
    <ModalControls>
      <CancelButtonWrap>
        <Button secondary onClick={close} type="button">
          CANCEL
        </Button>
      </CancelButtonWrap>
      <Button loading={loading}>CHARGE</Button>
    </ModalControls>
  </StyledForm>
);

type Outter = {|
  onSubmit: StripeTokenId => Promise<mixed>,
  close?: Function,
|};

const mapDispatchToProps = {
  notificationError,
};

const enhancer: HOC<*, Outter> = compose(
  withInjectStripe,
  withConnect(() => ({}), mapDispatchToProps),
  withStateHandlers(
    {
      loading: false,
    },
    {
      setLoading: () => loading => ({loading}),
    }
  ),
  withHandlers({
    handleError: props => (errorMessage: any) => {
      props.setLoading(false);
      props.notificationError(errorMessage);
    },
  }),
  withHandlers({
    handleSubmit: props => event => {
      // We don't want to let default form submission happen here, which would refresh the page.
      event.preventDefault();

      const {setLoading, stripe, onSubmit, handleError} = props;

      if (stripe) {
        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        setLoading(true);
        stripe.createToken().then(({token, error}) => {
          if (error) {
            return handleError(error.message);
          }

          if (!token) {
            return handleError(errorMap.E_PAYMENT_NO_TOKEN);
          }

          if (isPrepaid(token)) {
            return handleError(errorMap.E_PAYMENT_PREPAID_CARD);
          }

          onSubmit(token.id).then(() => {
            setLoading(false);
          });
        });
      } else {
        handleError('Stripe has not been initialised');
      }
    },
  })
);

export default enhancer(CardInput);
