// @flow
import {isEvent} from 'common/events';
import {Field} from 'formik';
import {is, path} from 'ramda';
import React from 'react';
import type {Component} from 'recompose';

import InputWrap from './InputWrap';

const handleBlur = props => () => {
  props.form.setFieldTouched(props.field.name, true);
};

const handleChange = props => eventOrValue => {
  const value = isEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;

  props.form.setFieldValue(props.field.name, value);
  props.form.setStatus({...props.form.status, error: null});

  if (is(Function, props.onChange)) {
    props.onChange(eventOrValue);
  }
};

type Added<Value> = {
  value: Value,
  onChange: Value => mixed,
  // onBlur: void => mixed,
  // error?: ?string,
};

export type ReqOutter = {
  name: string,
  label?: string,
  errorPosition?: string,
  wrapperAsLabel?: boolean,
};

function withField<Outter: ReqOutter, Value>(
  ComposedComponent: Component<{|
    ...$Exact<$Diff<Outter, ReqOutter>>,
    ...$Exact<Added<Value>>,
  |}>
): Component<Outter> {
  return props => (
    <Field
      {...props}
      render={fieldProps => {
        const showError =
          path(['form', 'status', 'submitted'], fieldProps) ||
          fieldProps.form.touched[fieldProps.field.name] === true;
        const error = showError && showError && fieldProps.form.errors[fieldProps.field.name];
        return (
          <InputWrap
            error={error}
            label={props.label}
            errorPosition={props.errorPosition}
            wrapperAsLabel={props.wrapperAsLabel}
          >
            <ComposedComponent
              {...props}
              {...fieldProps.field}
              // error={error}
              onChange={handleChange({...props, ...fieldProps})}
              onBlur={handleBlur({...props, ...fieldProps})}
            />
          </InputWrap>
        );
      }}
    />
  );
}

export default withField;
