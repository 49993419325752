import type {Mutation} from 'common/graphql/types';
import {inventoryOverviewQuery} from 'data/product/graphql/queries';
import {getConfig} from 'data/reservations/graphql/mutations';
import gql from 'graphql-tag';

import {bookingsByAffiliate} from './queries';

export const updateBookingStatusMutation: Mutation<{|bookingId: string|}> = {
  gql: gql`
    mutation updateBookingStatusMutation($bookingId: String!, $input: UpdateStatusInput!) {
      booking {
        updateBookingStatus(bookingId: $bookingId, input: $input) {
          bookingId
        }
      }
    }
  `,
  refetch: [bookingsByAffiliate, bookingsByAffiliate, inventoryOverviewQuery],
  refetchVariables: [
    props => getConfig(props, 'confirmed'),
    props => getConfig(props, 'checked_out'),
  ],
  refetchOptions: [
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
  ],
};

export const chargeLateFeeMutation: Mutation<{|
  bookingId: string,
  chargeAmount: number,
  cardToken?: string,
|}> = {
  gql: gql`
    mutation chargeLateFee($bookingId: String!, $chargeAmount: Int!, $cardToken: String) {
      booking {
        chargeLateFee(bookingId: $bookingId, chargeAmount: $chargeAmount, cardToken: $cardToken) {
          bookingId
        }
      }
    }
  `,
};

export const refundBookingMutation: Mutation<$Exact<{bookingId: string, refundAmount: number}>> = {
  gql: gql`
    mutation refundBooking($bookingId: String!, $refundAmount: Int!) {
      booking {
        refundBooking(bookingId: $bookingId, refundAmount: $refundAmount) {
          bookingId
        }
      }
    }
  `,
};

export const cancelBookingMutation: Mutation<{||}> = {
  gql: gql`
    mutation cancelBookingMutation($bookingId: String!) {
      booking {
        cancelBooking(bookingId: $bookingId) {
          bookingId
          reservations(withDeleted: true) {
            id
            reservationTransactions {
              createdAt
              status
              amount
              isRefund
            }
          }
        }
      }
    }
  `,
  refetch: [bookingsByAffiliate, bookingsByAffiliate, inventoryOverviewQuery],
  refetchVariables: [
    props => getConfig(props, 'confirmed'),
    props => getConfig(props, 'checked_out'),
  ],
  selector: ['booking', 'cancelBooking', 'reservations'],
  refetchOptions: [
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
    {fetchPolicy: 'network-only'},
  ],
};

export const markBookingInbound: Mutation<Booking> = {
  gql: gql`
    mutation markBookingInboundMutation($bookingId: String!) {
      booking {
        markInbound(bookingId: $bookingId) {
          bookingId
          status
          markedInboundAt
        }
      }
    }
  `,
};
