// @flow
import styled from 'styled-components';

export const ModalContentWrap = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  padding-bottom: 2rem;
`;
