// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
  height: 100vh;

  ${mqMax.tablet} {
    width: 100%;
    height: auto;
  }
`;

export const HomeWrap = styled.div`
  display: flex;

  ${mqMax.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const LogoSection = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 34%;
  height: 100vh;
  border-right: 1px solid ${theme.color.ultralight};

  ${mqMax.tablet} {
    width: 100%;
    height: auto;
    padding: 5rem 0;
    margin-bottom: 5rem;
  }
`;

export const LoginWrap = styled.div`
  width: 85%;

  ${mqMax.tablet} {
    width: auto;
  }
`;
