// @flow
import {preventDefault} from 'common/events';
import {EmptyOption, Option, StyledSelect} from 'componentsStyled/Forms/Select';
import type {EnumValue, ID} from 'data/enums/types';
import React from 'react';

type Props = {|
  options: EnumValue[],
  onChange: ID => mixed,
  placeholder: string,
  value: ID,
  'data-cy'?: string,
|};

const Select = ({
  options,
  placeholder = 'None',
  value,
  allowEmpty,
  onChange,
  'data-cy': dataCy,
}: Props) => (
  <StyledSelect
    data-cy={dataCy}
    empty={value === ''}
    value={value}
    onClick={preventDefault}
    onChange={e => onChange(e.target.value)}
  >
    <EmptyOption value="" disabled>
      {placeholder}
    </EmptyOption>
    {options.map(o => (
      <Option key={o.id} value={o.id}>
        {o.name}
      </Option>
    ))}
  </StyledSelect>
);

export default Select;
