// @flow
import Button from 'components/Button';
import theme from 'global/theme';
import styled from 'styled-components';

export const StyledCancelButton = styled(Button)`
  border: 0;
  color: ${theme.color.error};
  background-color: transparent;
  text-transform: uppercase;
`;
