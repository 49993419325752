// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import Menu from 'components/Menu';
import Overview from 'components/Overview';
import {selectIsMasquerading, selectLoggedIn, selectUser} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import MainFilter from './MainFilter';
import MainNav from './MainNav';
import MasqueradeBanner from './MasqueradeBanner';
import {MenuWrap} from './styled';
import {ContentWrap, HeaderWrap, PageContent} from './styled';

const Page = ({isMasquerading, user, loggedIn, children, value, onChange, overviewType}) => {
  const withFilter = overviewType !== 'calendar';

  return (
    <React.Fragment>
      <HeaderWrap data-cy={'logged-in-header'}>
        <MasqueradeBanner isMasquerading={isMasquerading} user={user} />
        <MainNav loggedIn={loggedIn} />
      </HeaderWrap>
      <Desktop>
        <PageContent isMasquerading={isMasquerading}>
          <Overview reservation={overviewType === 'reservation'} />
          <ContentWrap>
            <MenuWrap>
              <Menu />
              {withFilter && <MainFilter onChange={onChange} value={value} />}
            </MenuWrap>
            {children}
          </ContentWrap>
        </PageContent>
      </Desktop>
      <Mobile>
        <PageContent>
          <ContentWrap>
            <Overview reservation={overviewType === 'reservation'} />
            <MenuWrap>
              {withFilter && <MainFilter onChange={onChange} value={value} />}
              <Menu />
            </MenuWrap>
            {children}
          </ContentWrap>
        </PageContent>
      </Mobile>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
  isMasquerading: selectIsMasquerading(state),
  user: selectUser(state),
});

type Outter = {|
  children: React.Node,
  overviewType: string,
  onChange: (?string) => mixed,
  value: ?string,
  initialValue: ?string,
|};

const enhancer: HOC<*, Outter> = compose(withConnect(mapStateToProps));

export default enhancer(Page);
