// @flow
import theme from 'global/theme';
import styled from 'styled-components';

export const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;

export const Left = styled.div`
  max-width: 50rem;
  display: flex;
  align-items: center;
`;

export const Right = styled.div`
  max-width: 25rem;
`;

export const ImageWrap = styled.div`
  display: flex;
  width: 12.8rem;
  max-height: 12.8rem;
  margin-right: 1rem;
  justify-content: center;
`;

export const AccessoriesWrap = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  flex-shrink: 1;
  margin-top: 0.7rem;
  img {
    flex: 1 0 1;
    flex-shrink: 2;
    width: ${p => (p.smallIcons ? `2.8rem` : `3.8rem`)};
    height: ${p => (p.smallIcons ? `2.8rem` : `3.8rem`)};
    padding: ${p => !p.smallIcons && `0.2rem`};
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

export const Body = styled.div`
  margin: 3rem 0rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  background: ${theme.color.white};
`;
