// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const OverlayWrapperWrap = styled.div`
  position: relative;

  ${p =>
    p.isOpen &&
    css`
      z-index: ${theme.zIndices.overlay};
    `}
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: ${theme.zIndices.overlayOverlay};
  background: ${theme.lighten(theme.color.black, 0.1)};

  ${p =>
    p.open &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`;

export const Content = styled.div`
  z-index: ${theme.zIndices.overlayContent};
  position: relative;
`;
