// @flow
import {
  activeReservationStatuses,
  cancelledReservationStatuses,
  completedReservationStatuses,
  preCheckoutReservationStatuses,
  reservationStatuses,
} from 'data/reservations/constants';

import type {BookingStatus} from './types';

export const bookingStatuses: {
  [BookingStatus]: BookingStatus,
} = reservationStatuses;

export const preCheckoutBookingStatuses: BookingStatus[] = preCheckoutReservationStatuses;

export const activeBookingStatuses: BookingStatus[] = activeReservationStatuses;

export const completedBookingStatuses: BookingStatus[] = completedReservationStatuses;

export const cancelledBookingStatuses: BookingStatus[] = cancelledReservationStatuses;
