// @flow
import Button from 'components/Button';
import CollapsibleParagraph from 'components/CollapsibleParagraph';
import RawHtml from 'components/RawHtml';
import {selectAppConfig} from 'data/app/selectors';
import {updateBookingStatusMutation} from 'data/bookings/graphql/mutations';
import type {Booking} from 'data/bookings/types';
import {reservationStatuses} from 'data/reservations/constants';
import TextInput from 'forms/Input/Text';
import {stringOptional} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withAffiliate from 'hoc/withAffiliate';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose, withState} from 'recompose';

import {Divider} from './styled';

const BookingCheckOutModal = ({close, loading, booking, affiliate}) => {
  const {inStoreFulfilmentInstructions, deliveryFulfilmentInstructions} = affiliate;
  const instructions =
    booking.fulfillmentType === 'DELIVERY'
      ? deliveryFulfilmentInstructions
      : inStoreFulfilmentInstructions;

  return (
    <ModalBody close={close} maxWidth={70}>
      <ModalHeader title="Fulfilment" />
      <ModalContent>
        {instructions && (
          <>
            <CollapsibleParagraph
              showExpandText={false}
              open={true}
              maxHeight="10rem"
              group={'Instructions'}
            >
              <RawHtml html={instructions} />
            </CollapsibleParagraph>
            <Divider />
          </>
        )}
        <TextInput name="comment" label="Comment (optional)" placeholder="Comment" />
      </ModalContent>
      <ModalControls>
        <Button data-cy="fulfil-button" large loading={loading}>
          Fulfil
        </Button>
      </ModalControls>
    </ModalBody>
  );
};

const schema = {
  comment: stringOptional,
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

type Outter = {|
  close: Function,
  booking: Booking,
|};

const enhancer: HOC<*, Outter> = compose(
  withAffiliate(),
  withConnect(mapStateToProps),
  withMutation(updateBookingStatusMutation),
  withSubmit({
    onSuccess: props => props.close,
    redirect: props => urls.upcoming,
    errorText: 'Failed to checkout, please try again.',
    successText: 'Checked out!',
  }),
  withState('hasTermsAndConditions', 'setHasTermsAndConditions', false),
  withForm({
    schema,
    onSubmit: props => values => {
      return props.submit({
        bookingId: props.booking.bookingId,
        input: {
          newStatus:
            props.booking.fulfillmentType === 'DELIVERY'
              ? reservationStatuses.outbound
              : reservationStatuses.checked_out,
          details: {
            comment: values.comment,
          },
        },
      });
    },
  })
);

export default enhancer(BookingCheckOutModal);
