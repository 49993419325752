// @flow
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import React from 'react';

import InventoryOverview from './InventoryOverview';
import ReservationOverview from './ReservationOverview';
import {OverviewContainer, OverviewDetail, OverviewTitle} from './styled';

const Overview = ({reservation}: {reservation: boolean}) => (
  <OverviewContainer>
    <Mobile>
      <OverviewTitle>Overview</OverviewTitle>
      <OverviewDetail>
        {reservation ? <ReservationOverview /> : <InventoryOverview />}
      </OverviewDetail>
    </Mobile>
    <Desktop>
      <OverviewTitle>Overview</OverviewTitle>
      {reservation ? <ReservationOverview /> : <InventoryOverview />}
    </Desktop>
  </OverviewContainer>
);

export default Overview;
