// @flow
import {mq} from 'common/mediaQuery';
import styled from 'styled-components';

export const Space = styled.div`
  margin-bottom: 2.5rem;
`;

export const FooterMargin = styled.div`
  padding-bottom: 5.8rem;

  ${mq.tabletWide} {
    padding-bottom: 7rem;
  }
`;

export const Or = styled.div`
  margin: 2.4rem 0;
  font-size: 1.3rem;
  letter-spacing: 0.05rem;
  color: #7f899e;
  text-transform: uppercase;
  position: relative;
  text-align: center;

  &::before,
  &::after {
    content: '';
    height: 0.1rem;
    background-color: #c8cfde;
    position: absolute;
    width: calc(50% - 2rem);
    top: 50%;
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`;
