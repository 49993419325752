// @flow
import type {ExtractActionTypes} from 'common/redux/types';
import type {DateRangeValue} from 'data/units/date/types';

import * as actions from './actions';
import {activities} from './constants';
import type {AppConfig, Category} from './types';

export type AppState = {|
  appConfig: ?AppConfig,
  activity: ?Category,
  dates: ?DateRangeValue,
  unit: string,
|};

const initialState: AppState = {
  appConfig: undefined,
  activity: activities.ALL,
  dates: undefined,
  unit: 'metric',
};

type Action = ExtractActionTypes<typeof actions>;

function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case 'APP/SET_CONFIG':
      return {
        ...state,
        appConfig: action.payload,
      };
    case 'APP/CHOOSE_ACTIVITY':
      return {
        ...state,
        activity: action.payload,
      };
    case 'APP/CHOOSE_UNIT':
      return {
        ...state,
        unit: action.payload,
      };
    case 'APP/FILTER_DATES':
      return {
        ...state,
        dates: action.payload,
      };
    case 'USER/LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export default appReducer;
