// @flow
import PrintIcon from 'assets/icons/PrintIcon';
import BookingActions from 'components/BookingActions';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import type {Booking} from 'data/bookings/types';
import DeliveryDetail from 'pages/Booking/BookingPreview/DeliveryDetail';
import urls from 'pages/urls';
import React from 'react';

import BookingItemsDetail from './BookingItemsDetail';
import {DesktopWrap, MobileWrap, StyledPackingSlipButton} from './styled';

type Props = {|
  data: Booking,
|};

const PackingSlipButton = ({booking}: {booking: Booking}) => (
  <StyledPackingSlipButton
    to={urls.bookingPackingSlip(booking.bookingId)}
    target="_blank"
    secondary
  >
    <PrintIcon size={16} /> PRINT PACKING SLIP
  </StyledPackingSlipButton>
);

const BookingPreview = ({data}: Props) => {
  const isDeliveryFulfilment = data.fulfillmentType === 'DELIVERY';

  const deliverySection = isDeliveryFulfilment && (
    <>
      <PackingSlipButton booking={data} />
      <DeliveryDetail booking={data} />
    </>
  );

  return (
    <React.Fragment>
      <Desktop>
        <DesktopWrap>
          {deliverySection}
          <BookingItemsDetail booking={data} />
          <BookingActions booking={data} />
        </DesktopWrap>
      </Desktop>
      <Mobile>
        <MobileWrap>
          {deliverySection}
          <BookingItemsDetail booking={data} />
          <BookingActions booking={data} />
        </MobileWrap>
      </Mobile>
    </React.Fragment>
  );
};

export default BookingPreview;
