// @flow
import {damageTypeFragment} from 'data/damages/graphql/fragments';
import {manufacturerFragment} from 'data/manufacturer/fragments';
import {customerFragment} from 'data/user/graphql/fragments';
import gql from 'graphql-tag';

export const productFragment = gql`
  fragment productFragment on Product {
    id
    name
    description
    images {
      alt
      order
      sizes {
        url
        width
        height
      }
    }
    manufacturer {
      ...manufacturerFragment
    }
    categories {
      categoryId
      name
    }
    ratingAverage
    ratingCount
    variantVariables
  }
  ${manufacturerFragment}
`;

export const productVariantFragment = gql`
  fragment productVariantFragment on ProductVariant {
    id
    name
    shelfLocation
    parameters {
      ... on ParameterFloatValue {
        id
        name
        label
        type
        floatValue
        unit
        displayedUnit
      }
      ... on ParameterEnumFlattenedValue {
        id
        name
        label
        type
        enumValues
      }
      ... on ParameterStringValue {
        id
        name
        label
        type
        stringValue
      }
    }
    product {
      ...productFragment
    }
  }
  ${productFragment}
`;

export const productItemFragment = gql`
  fragment productItemFragment on ProductItem {
    id
    status
    code
    productVariant {
      ...productVariantFragment
    }
  }
  ${productVariantFragment}
`;
export const productItemInventoryFragment = gql`
  fragment productItemInventoryFragment on ProductItemInventory {
    id
    code
    productVariant {
      ...productVariantFragment
    }
    futureReservationsCount
    damages {
      fixed
      damageType {
        ...damageTypeFragment
      }
    }
    damagesCount
    status
    isDeleted
  }
  ${damageTypeFragment}
  ${productVariantFragment}
`;

export const productReservationsFragment = gql`
  fragment productReservationsFragment on Reservation {
    id
    booking {
      bookingId
      start
      end
      returnedAt
      externalId
      order {
        customer {
          ...customerFragment
        }
      }
    }
    externalId
    status
  }
  ${customerFragment}
`;

export const productItemDetailFragment = gql`
  fragment productItemDetailFragment on ProductItem {
    id
    code
    isDeleted
    damages {
      fixed
    }
    productVariant {
      ...productVariantFragment
    }
    offlineStates {
      reason
    }
    reservations(withAllReservationStates: true) {
      ...productReservationsFragment
    }
    damages {
      id
      damageType {
        ...damageTypeFragment
      }
      description
      fixed
    }
    status
  }
  ${damageTypeFragment}
  ${productVariantFragment}
  ${productReservationsFragment}
`;
