// @flow
import QuiverItem from 'components/QuiverItem';
import Tile from 'components/Tile';
import type {ProductItem} from 'data/product/types';
import * as React from 'react';

type Props = {|
  product: ProductItem,
|};

const QuiverTile = ({product}: Props) => (
  <Tile>
    <QuiverItem product={product} />
  </Tile>
);

export default QuiverTile;
