import styled from 'styled-components';

import {Text} from '../../../componentsStyled/Typography/Texts';

export const ReturnStatus = styled.div`
  margin-top: 44px;
`;

export const SuggestedCharge = styled(Text)`
  margin-top: 24px;
`;
