import theme from 'global/theme';
import styled from 'styled-components';

export const ReturnDatePickerValue = styled.div`
  padding: 8px;
  width: 144px;
  border: solid 0.2rem ${theme.color.lightGrey};
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ReturnDatePickerText = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #${theme.color.primaryDark};
  margin-left: 6.5px;
`;

export const DatePickerWrapper = styled.div`
  width: 428px;
`;

export const ReturnDatePickerWrap = styled.div`
  position: absolute;
`;
