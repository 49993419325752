// @flow
import {mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import styled from 'styled-components';

export const TileItemLink = styled(StyledLink)`
  text-decoration: none;
  height: 36rem;
  transition: box-shadow 0.25s ease, transform 0.25s ease;
`;

export const TileListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mqMax.tablet} {
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  ${mqMax.tabletWide} {
    justify-content: space-around;
  }
`;
