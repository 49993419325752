// @flow
import ShopIcon from 'assets/icons/ShopIcon';
import TruckIcon from 'assets/icons/TruckIcon';
import {FulfilmentIndicatorWrapper} from 'components/BookingItem/FulfilmentIndicator/styled';
import type {FulfillmentType} from 'data/bookings/types';
import React from 'react';

type Props = {
  fulfilmentType?: FulfillmentType,
};

const FulfilmentIndicator = ({fulfilmentType}: Props) => {
  switch (fulfilmentType) {
    case 'DELIVERY':
      return (
        <FulfilmentIndicatorWrapper>
          <TruckIcon /> Delivery
        </FulfilmentIndicatorWrapper>
      );
    case 'IN_STORE':
    default:
      return (
        <FulfilmentIndicatorWrapper>
          <ShopIcon /> In-store
        </FulfilmentIndicatorWrapper>
      );
  }
};

export default FulfilmentIndicator;
