// @flow
import Loader from 'components/Loader';
import * as React from 'react';

import {ButtonContent, ButtonLabel, CenterWrap, StyledButton, StyledLinkButton} from './styled';

type Props = {|
  children: React.Node,
  onClick?: (event: SyntheticInputEvent<*>) => mixed,
  loading?: boolean,
  done?: boolean,
  // styled props
  fullwidth?: boolean,
  disabled?: boolean,
  small?: boolean,
  large?: boolean,
  secondary?: boolean,
  type?: string,
  banner?: boolean,
  'data-cy'?: string,
  to?: string,
  target?: string,
|};

const getStatusIcon = (props: Props) => {
  if (props.loading) return <Loader white={!props.secondary} small />;
  return null;
};

const Button = (props: Props) => {
  const ButtonComponent = props.to ? StyledLinkButton : StyledButton;

  return (
    <ButtonComponent {...props}>
      <ButtonContent>
        <CenterWrap>{getStatusIcon(props)}</CenterWrap>
        <ButtonLabel isHidden={props.done || props.loading}>{props.children}</ButtonLabel>
      </ButtonContent>
    </ButtonComponent>
  );
};

export default Button;
