// @flow
import React from 'react';

import {momentFromDateString} from '../../data/units/date/helpers';
import type {DateString} from '../../data/units/date/types';
import {DateWrap, Day, IntervalWrap, Month} from './styled';

type Props = {|
  start: DateString,
  end: DateString,
  small?: boolean,
|};

const ReservationInterval = ({start, end, small}: Props) => {
  const startMonth = momentFromDateString(start).format('MMM');
  const startDay = momentFromDateString(start).format('D');
  const endMonth = momentFromDateString(end).format('MMM');
  const endDay = momentFromDateString(end).format('D');

  return (
    <IntervalWrap small={small}>
      <DateWrap>
        <Day small={small}>{startDay}</Day>
        <Month small={small}>{startMonth}</Month>
      </DateWrap>
      <Day small={small}>-</Day>
      <DateWrap>
        <Day small={small}>{endDay}</Day>
        <Month small={small}>{endMonth}</Month>
      </DateWrap>
    </IntervalWrap>
  );
};

export default ReservationInterval;
