// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const ActionPreviewWrap = styled.div`
  margin-top: 5rem;
  background: ${theme.color.white};
  display: flex;
  width: 750px;
  height: 600px;

  ${mqMax.tabletWide} {
    width: 100%;
    margin-top: 1rem;
  }
`;
export const ImageWrap = styled.div`
  padding: 2rem;
  background: ${theme.color.white};
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Half = styled.div`
  width: 50%;
  padding-left: 2.5rem;

  ${p =>
    p.white &&
    css`
      position: relative;

      &::after {
        position: fixed;
        left: 0;
        top: -20rem;
        height: 150vh;
        width: 100vw;
        content: '';
        z-index: -1;
      }
    `}
`;
export const StyledImage = styled.img`
  width: 100%;
  background: ${theme.color.white};
  display: flex;
  margin: auto;
`;
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
