// @flow
import InServiceToggle from 'components/ProductItemPreview/ProductItemActions/InServiceToggle';
import SetInStock from 'components/ProductItemPreview/ProductItemActions/SetInStock';
import QuiverStatus from 'components/Status/QuiverStatus';
import type {ProductItem} from 'data/product/types';
import React from 'react';

import {ActionWrap, StatusSection} from './styled';

type Props = {|
  productItem: ProductItem,
|};

const ProductItemActions = ({productItem}: Props) => {
  const isPostProcessing = productItem.status === 'post_processing';

  return (
    <ActionWrap>
      <StatusSection>
        <QuiverStatus status={productItem.status} />
      </StatusSection>
      {isPostProcessing && <SetInStock productItem={productItem} />}
      <InServiceToggle productItem={productItem} />
    </ActionWrap>
  );
};

export default ProductItemActions;
