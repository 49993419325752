// @flow
import type {ReservationStatus} from './types';

export const reservationStatuses: {
  [ReservationStatus]: ReservationStatus,
} = {
  confirmed: 'confirmed',
  pre_processing: 'pre_processing',
  outbound: 'outbound',
  checked_out: 'checked_out',
  inbound: 'inbound',
  delayed: 'delayed',
  overdue: 'overdue',
  post_processing: 'post_processing',
  finished: 'finished',
  cancelled: 'cancelled',
  passed_to_finalization_worker: 'passed_to_finalization_worker',
};

export const preCheckoutReservationStatuses: ReservationStatus[] = [
  reservationStatuses.pre_processing,
  reservationStatuses.confirmed,
];

export const activeReservationStatuses: ReservationStatus[] = [
  reservationStatuses.outbound,
  reservationStatuses.checked_out,
  reservationStatuses.inbound,
  reservationStatuses.delayed,
  reservationStatuses.overdue,
];

export const completedReservationStatuses: ReservationStatus[] = [
  reservationStatuses.post_processing,
  reservationStatuses.finished,
  reservationStatuses.passed_to_finalization_worker,
];

export const cancelledReservationStatuses: ReservationStatus[] = [reservationStatuses.cancelled];
