// @flow
import {mq} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const ContextMenuWrap = styled.div`
  background: ${theme.color.white};
  position: absolute;
  top: 100%;
  min-width: 20rem;
  z-index: ${theme.zIndices.contextMenu};
  top: calc(100% + 1rem);
  max-height: 50rem;
  overflow-y: scroll;
  border-radius: 0.4rem;
  box-shadow: 0 3rem 5rem 0 ${theme.lighten(theme.color.black, 0.1)};
  border: solid 0.2rem ${theme.color.lightGrey};

  ${p =>
    !p.isOpen &&
    css`
      display: none;
    `}

  /* This will look out of place and produces a lot of extra code, but nothing can be done here pretty much, requirements... */
  ${p =>
    p.autocompleteStyle &&
    css`
      position: fixed;
      top: 6.4rem;
      left: 0;
      right: 0;
      height: calc(100% - 6rem);
      border: 0;
      border-radius: 0;

      ${mq.tabletWide} {
        position: absolute;
        top: calc(100% + 1rem);
        height: auto;
        border-radius: 0.4rem;
        border: solid 0.2rem ${theme.color.lightGrey};
      }
    `}

  ${p =>
    p.left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`;
