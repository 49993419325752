// @flow
import {stopPropagation} from 'common/events';
import getErrorMessage from 'common/graphql/getErrorMessage';
import errorMap from 'common/graphql/getErrorMessage/errorMap';
import Button from 'components/Button';
import {Text} from 'componentsStyled/Typography/Texts';
import {
  chargeLateFeeMutation,
  refundBookingMutation,
  updateBookingStatusMutation,
} from 'data/bookings/graphql/mutations';
import {openModal} from 'data/modals/actions';
import {notificationError} from 'data/notifications/actions';
import {reservationStatuses} from 'data/reservations/constants';
import TextInput from 'forms/Input/Text';
import TextArea from 'forms/Input/TextArea';
import withConnect from 'hoc/withConnect';
import {withCustomMutation} from 'hoc/withMutation';
import withRouter from 'hoc/withRouter';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import ChargeFailed from 'modals/ChargeFailed';
import moment from 'moment';
import urls from 'pages/urls';
//$ReactHooks
import React, {useCallback, useEffect, useMemo} from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import Checkbox from '../../components/Checkbox';
import Loader from '../../components/Loader';
import type {Booking} from '../../data/bookings/types';
import type {Reservation} from '../../data/reservations/types';
import {momentFromDateString} from '../../data/units/date/helpers';
import type {DateString} from '../../data/units/date/types';
import {numberOptional} from '../../forms/validators/common';
import {stringOptional} from '../../forms/validators/string';
import withForm from '../../forms/withForm';
import SingleButtonModal from '../SingleButton';
import ReturnDatePicker from './ReturnDatePicker/return-date-picker';
import ReturnNote from './ReturnNote/return-note';
import {
  AmountInputWrap,
  AmountLabelWrap,
  CancelButtonWrap,
  ChargeRow,
  DateWrap,
  Divider,
  DollarSign,
} from './styled';

type FormData = {|
  comment?: string,
  returnDate?: DateString,
  amount?: number,
|};

export type Outter = {|
  close: Function,
  booking: Booking,
  chargeLateFee(any): Promise<void>,
  returnBooking(any): Promise<void>,
  refundBooking(any): Promise<void>,
  formData: FormData,
  handleAsDelivery?: boolean,
|};

type Inner = {|
  ...Outter,
  close: Function,
  loading: boolean,
  error?: string,
  values: FormData,
  setFieldValue: (field: string, value: any) => void,
  isAfterToday: moment => boolean,
  isBeforeCheckout: moment => boolean,
  enableInputAmount: boolean,
  setEnableInputAmount: (enable: boolean) => any,
|};

export type BookingStatus = 'onTime' | 'late' | 'early';

function getSuggestedLateFee(reservations: [Reservation], days: number): number {
  return reservations.reduce(
    (total, reservation) => total + reservation.pricingDetail.lateFee * days,
    0
  );
}

function getSuggestedEarlyFee(reservations: [Reservation], days: number): number {
  // NOTE(Barry): This implementation of getting the suggested refund probably needs a bit of work as it just suggests
  // to the user that the refund based on the minimum pricing amount multiplied by the number of days that it's early.
  // There are more complicating factors, like if the user checked out the item early or late. But this simple refund
  // logic will do for a beta version and we'll adjust it per client feedback
  const actualPrice: number = reservations.reduce((total, reservation) => {
    const refundPerDay =
      reservation.pricingDetail.prices[reservation.pricingDetail.prices.length - 1];
    return total + refundPerDay * days;
  }, 0);

  return actualPrice;
}

const BookingReturnModal = ({
  handleAsDelivery,
  close,
  loading,
  error,
  values,
  booking,
  setFieldValue,
  isAfterToday,
  isBeforeCheckout,
  enableInputAmount,
  setEnableInputAmount,
}: Inner) => {
  const onCancel = (e: SyntheticInputEvent<*>) => {
    e.preventDefault();
    close();
  };

  const returnDateMoment = values.returnDate ? momentFromDateString(values.returnDate) : undefined;
  const bookingEndMoment = momentFromDateString(booking.end);
  let status: BookingStatus;
  if (!returnDateMoment || returnDateMoment.isSame(bookingEndMoment)) {
    status = 'onTime';
  } else if (returnDateMoment.isAfter(bookingEndMoment)) {
    status = 'late';
  } else {
    status = 'early';
  }

  const isInvalidReturnDate = (date: moment) => {
    return isBeforeCheckout(date) || isAfterToday(date);
  };

  const canReturn = !enableInputAmount || (values.amount && values.amount > 0);

  const onClose = useCallback(() => {
    // Disregard calls to close while some op is in flight
    if (loading) {
      return;
    }

    close();
  }, [close, loading]);

  //Number of days from the booking's intended return date
  const days =
    returnDateMoment &&
    Math.floor(Math.abs(moment.duration(returnDateMoment.diff(bookingEndMoment)).as('days')));

  const recommendedAmount = useMemo(() => {
    if (days === undefined || days === null) {
      return undefined;
    }
    return status === 'late'
      ? getSuggestedLateFee(booking.reservations, days)
      : getSuggestedEarlyFee(booking.reservations, days);
  }, [booking.reservations, status, days]);

  useEffect(() => {
    if (!enableInputAmount) {
      //If the recommended refund is more than max refund then use max refund.
      if (status === 'early' && recommendedAmount > booking.totalPaid) {
        setFieldValue('amount', booking.totalPaid / 100);
      } else {
        setFieldValue('amount', recommendedAmount / 100);
      }
    }
    // [Declan]: ES Lint wants `enableInputAmount` in the deps array as it is used in the hook.
    // However including it actually creates some unwanted UX (value will be changed when checkbox
    // unchacked). So we will ignore ES lint :).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedAmount, setFieldValue, booking.totalPaid, status]);

  //If changed to on time then clear the charge/refund amount and uncheck checkbox.
  useEffect(() => {
    if (status === 'onTime') {
      setFieldValue('amount', 0);
      setEnableInputAmount(false);
    }
  }, [status, setFieldValue, setEnableInputAmount]);

  return (
    <ModalBody close={onClose} maxWidth={70} showOverflow renderCross={false}>
      {loading && <Loader />}
      {!loading && (
        <>
          <ModalHeader title="Return" />
          <ModalContent>
            <DateWrap>
              <Text>Date {handleAsDelivery ? 'dispatched' : 'returned'}</Text>
              <ReturnDatePicker
                name={'returnDate'}
                onClick={stopPropagation}
                shouldDisableDate={isInvalidReturnDate}
                datesToOutline={[booking.end]}
              />
              <ReturnNote
                handleAsDelivery={handleAsDelivery}
                reservations={booking.reservations}
                status={status}
                days={days}
                recommendedAmount={recommendedAmount}
                totalPaid={booking.totalPaid}
              />
            </DateWrap>
            {(status === 'late' || status === 'early') && (
              <ChargeRow>
                <Checkbox value={enableInputAmount} onChange={setEnableInputAmount} />
                <AmountLabelWrap>
                  <Text>{status === 'late' ? 'Charge' : 'Refund'} customer</Text>
                </AmountLabelWrap>
                <AmountInputWrap disabled={!enableInputAmount} hasError={!canReturn}>
                  <DollarSign hasError={!canReturn}>$</DollarSign>
                  <TextInput
                    type={'number'}
                    name={'amount'}
                    disabled={!enableInputAmount}
                    hasError={!canReturn}
                  />
                </AmountInputWrap>
              </ChargeRow>
            )}
            <Divider />
            <Text>Comment (optional)</Text>
            <TextArea name={'comment'} placeholder="Add a comment.." />
            {error && <Text danger>{error}</Text>}
          </ModalContent>
          <ModalControls>
            <CancelButtonWrap>
              <Button onClick={onCancel} type="button" secondary>
                CANCEL
              </Button>
            </CancelButtonWrap>
            <Button data-cy={'return-modal-return-button'} disabled={!canReturn}>
              RETURN
            </Button>
          </ModalControls>
        </>
      )}
    </ModalBody>
  );
};

const mapDispatchToProps = {
  openModal,
  notificationError,
};

const schema = {
  comment: stringOptional,
  returnDate: stringOptional,
  amount: numberOptional,
};

const enhancer: HOC<*, Outter> = compose(
  withRouter,
  withConnect(() => ({}), mapDispatchToProps),
  withStateHandlers(
    {enableInputAmount: false},
    {setEnableInputAmount: () => v => ({enableInputAmount: v})}
  ),
  withSubmit({
    submit: props => async values => {
      const isLate = moment(values.returnDate).isAfter(momentFromDateString(props.booking.end));
      //Only attempt a refund/charge if there is an amount given and the input is enabled
      if (values.amount && props.enableInputAmount) {
        if (isLate) {
          try {
            await props.chargeLateFee({
              bookingId: props.booking.bookingId,
              chargeAmount: values.amount * 100,
            });
          } catch (error) {
            if (
              error.graphQLErrors.some(
                graphQLError => graphQLError.data.code === 'E_EXCEED_ALLOWED_AMOUNT'
              )
            ) {
              throw error;
            }
            // If card payment failure occurs, close this modal and move customer
            // to the charges failed modal, carrying over their form inputs.
            props.close();
            props.openModal(ChargeFailed, {
              bookingId: props.booking.bookingId,
              amount: values.amount,
              returnDate: values.returnDate,
              comment: values.comment,
              errorMessage: getErrorMessage(error, 'Error returned from payment processor'),
            });
            error.ignoreMessage = true;
            throw error;
          }
        } else {
          try {
            await props.refundBooking({
              bookingId: props.booking.bookingId,
              refundAmount: values.amount * 100,
            });
          } catch (error) {
            // Stop return process if error is due to user input
            if (getErrorMessage(error) === errorMap.E_INVALID_REFUND_AMOUNT) {
              throw error;
            }
            // NOTE(Jude): All other errors returned are due to stripe api failure. In this case
            // continue with the return process and notify the shop keeper that a problem has
            // occured.
            props.notificationError('Booking could not be fully refunded');
          }
        }
      }

      const returnResult = await props.returnBooking({
        bookingId: props.booking.bookingId,
        input: {
          newStatus: reservationStatuses.passed_to_finalization_worker,
          details: {
            comment: values.comment,
            returnDate: values.returnDate,
          },
        },
      });

      props.close();
      //Will only haved charged if the input is enabled and an amount is provided
      if (values.amount && props.enableInputAmount && isLate) {
        const amount = values.amount;
        await new Promise(resolve => {
          props.openModal(
            SingleButtonModal,
            {
              title: 'Charge Successful',
              buttonText: 'Done',
              onConfirm: () => {
                resolve();
                return Promise.resolve();
              },
              message: `Successfully charged $${amount} to card ****`,
            },
            {
              onClose: () => {
                resolve();
              },
            }
          );
        });
      }

      return returnResult;
    },
    redirect: props => urls.returns,
    successText: 'Returned',
    errorText: 'Failed to return, please try again.',
  }),
  withHandlers({
    isBeforeCheckout: props => (date: moment) => {
      const checkedOutAt = props.booking.reservations[0].checkedOutAt;
      return date.isBefore(checkedOutAt, 'day');
    },
    isAfterToday: props => (date: moment) => date.isAfter(moment(), 'day'),
  }),
  withForm({
    schema,
    onSubmit: props => values => {
      const {returnDate} = values;

      const beforeCheckoutDateError =
        returnDate &&
        props.isBeforeCheckout(moment(returnDate)) &&
        'Return date must be after checkout date';

      const futureDateError =
        returnDate &&
        props.isAfterToday(moment(returnDate)) &&
        'Return date cannot be a future date';

      const errorMessage = beforeCheckoutDateError || futureDateError;
      // NOTE(Barry): Disgusting hack.
      // The correct place to put validation would be in the schema; however, the forms widget is hard coded to put the
      // error message directly under the input element, which is going to break the widget because of the positioning
      // used to get the calendar to overflow.
      // We pretend to throw a graphql error here so that the error becomes a generic form error and then we can put it
      // wherever we want
      if (errorMessage) {
        return Promise.reject({
          graphQLErrors: [
            {
              message: errorMessage,
            },
          ],
        });
      }
      return props.submit(values);
    },
  })
);

/**
 * A version of this component that should be used exclusively in storybook. The difference between this component and
 * the default is that it requires specification of the submitMutation function.
 */
export const BookingReturnModalStory = enhancer(BookingReturnModal);

/**
 * A modal that renders a form to the user that allows specification of return date and comment
 */
export default compose(
  withCustomMutation(updateBookingStatusMutation, 'returnBooking'),
  withCustomMutation(chargeLateFeeMutation, 'chargeLateFee'),
  withCustomMutation(refundBookingMutation, 'refundBooking')
)(BookingReturnModalStory);
