// @flow
import {BookingStatusWrap} from 'components/Status/BookingStatus/styled';
import {bookingStatuses} from 'data/bookings/constants';
import type {Booking} from 'data/bookings/types';
import React from 'react';

import {getDateToDisplay, getStatusColour, relative} from './helpers';

type Props = {|
  booking: Booking,
  small?: boolean,
|};

const BookingStatus = ({booking, small}: Props) => {
  const {status} = booking;
  const date = getDateToDisplay(booking);
  const colour = getStatusColour(date);

  if (!date || !status) {
    return null;
  }

  const relativeDate = relative(date);

  const text = [bookingStatuses.delayed, bookingStatuses.overdue].includes(status)
    ? `${status} - ${relativeDate}`
    : String(relativeDate);

  return (
    <BookingStatusWrap color={colour} small={small}>
      {text}
    </BookingStatusWrap>
  );
};

export default BookingStatus;
