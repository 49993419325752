// @flow
import TruckIcon from 'assets/icons/TruckIcon';
import {bookingStatuses, preCheckoutBookingStatuses} from 'data/bookings/constants';
import type {Booking} from 'data/bookings/types';
import type {DateString} from 'data/units/date/types';
import * as momentTz from 'moment-timezone';
import MarkInbound from 'pages/Booking/BookingPreview/DeliveryDetail/MarkInbound';
import {
  DeliveryBody,
  DeliveryHeader,
  Wrapper,
} from 'pages/Booking/BookingPreview/DeliveryDetail/styled';
import {path} from 'ramda';
import React from 'react';

export type Props = {
  booking: Booking,
};

const markAsInboundStatuses = [
  bookingStatuses.checked_out,
  bookingStatuses.overdue,
  bookingStatuses.inbound,
  bookingStatuses.delayed,
];

const disabledMarkAsInboundStatuses = [bookingStatuses.inbound, bookingStatuses.delayed];

/**
 * Convert a date string in ISO format to the day and month of the timezone specfiied
 * @param {string} date - A date-time string represented using the ISO standard
 * @param {string} timezone - The timezone that we want to convert the ISO string to
 * @returns {string} The day and month the ISO string falls under in the local
 * timezone of the browser this function is called in
 */
const formatToTimezone = (date: DateString, timezone: string) =>
  // $ExpectError
  momentTz(date).tz(timezone).format('D MMMM');

const DeliveryDetail = ({booking}: Props) => {
  const {dispatchDate, expectedReturnDate, status, deliveryDestination, order} = booking;
  const {name, address, additionalContact, instructions} = deliveryDestination || {};
  const {email: additionalEmail, phone: additionalPhone} = additionalContact || {};
  const contactEmail = path(['customer', 'user', 'email'], order);
  const contactPhone = path(['customer', 'user', 'profiles', 0, 'phoneNumber'], order);

  //If in a 'pre-checkout' status, then show the dispatch date, else show the return date.
  const showDispatchDate = preCheckoutBookingStatuses.includes(status);
  const showMarkAsInbound = markAsInboundStatuses.includes(status);
  const disableMarkAsInbound = disabledMarkAsInboundStatuses.includes(status);

  const timezone = booking.affiliate.inTimezone;
  const deliveryText = showDispatchDate
    ? !!dispatchDate && `Dispatch on ${formatToTimezone(dispatchDate, timezone)}`
    : !!expectedReturnDate &&
      `Expected return by ${formatToTimezone(expectedReturnDate, timezone)}`;

  return (
    <Wrapper>
      <DeliveryHeader>
        <h3>
          {deliveryText && (
            <>
              <TruckIcon />
              {deliveryText}
            </>
          )}
        </h3>
        {showMarkAsInbound && <MarkInbound disabled={disableMarkAsInbound} booking={booking} />}
      </DeliveryHeader>
      <DeliveryBody>
        <div>
          <h4>Delivery Address</h4>
          <p>{name}</p>
          <p>{address}</p>
          {instructions && <p>{instructions}</p>}
        </div>
        <div>
          <h4>Contact Details</h4>
          <p>{contactEmail}</p>
          <p>{contactPhone}</p>
        </div>
        {(additionalPhone || additionalEmail) && (
          <div>
            <h4>Additional Contact</h4>
            {additionalPhone && <p>{additionalPhone}</p>}
            {additionalEmail && <p>{additionalEmail}</p>}
          </div>
        )}
      </DeliveryBody>
    </Wrapper>
  );
};

export default DeliveryDetail;
