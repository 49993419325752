// @flow
import {Text} from 'componentsStyled/Typography/Texts';
import withFormField, {type ReqOutter} from 'forms/withFormField';
import theme from 'global/theme';
import * as React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import {ClearTextWrap, PadWrap, TextWrap} from './styled';

const SignatureBlock = ({text, onChange, setPad, pad, handleClear, handleChange}) => (
  <React.Fragment>
    <TextWrap>
      <Text book black>
        {text}
      </Text>
      <ClearTextWrap>
        <Text bold primary onClick={handleClear}>
          Clear
        </Text>
      </ClearTextWrap>
    </TextWrap>
    <PadWrap>
      <SignaturePad
        ref={ref => setPad(ref)}
        options={{
          penColor: theme.color.primary,
          backgroundColor: theme.color.ultralight,
          throttle: 8,
          onEnd: handleChange,
        }}
      />
    </PadWrap>
  </React.Fragment>
);

type Outter = {|
  ...$Exact<ReqOutter>,
  text: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withFormField,
  withStateHandlers(
    {
      pad: null,
    },
    {
      setPad: () => pad => ({pad}),
    }
  ),
  withHandlers({
    handleChange: props => () => props.onChange(props.pad && props.pad.toDataURL()),
    handleClear: props => () => {
      props.pad && props.pad.clear();
      return props.onChange(null);
    },
  })
);

export default enhancer(SignatureBlock);
