// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const CalendarWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 20rem);
  min-width: 27.2rem;
  justify-content: flex-start;
  align-items: center;
  outline: none;

  ${mqMax.tabletWide} {
    max-height: 50rem;
    padding: 1rem;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;
