import styled, {css} from 'styled-components';

import theme from '../../global/theme';

export const CancelButtonWrap = styled.div`
  margin-right: 24px;
`;

export const DateWrap = styled.div`
  margin-bottom: 24px;
`;

export const ChargeRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AmountLabelWrap = styled.div`
  margin-left: 8px;
  margin-right: 24px;
`;

export const AmountInputWrap = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  height: 40px;
  border: solid 0.2rem ${theme.color.lightGrey};
  border-radius: 0.4rem;
  background: ${theme.color.white};

  ${p =>
    p.disabled &&
    css`
      background: #f7fafc;
    `}

  ${p =>
    p.hasError &&
    css`
      background-color: #fff5f5;
      border: 2px solid ${theme.color.error};
    `}

  label {
    margin-bottom: 0;
  }

  input {
    height: 36px;
    border: 0;
    padding: 0;
    padding-left: 2px;
  }
`;

export const Divider = styled.hr`
  margin: 24px 0;
  border: 1px solid #e2e8f0;
`;

export const DollarSign = styled.div`
  line-height: 40px;
  color: ${theme.color.darkGrey};
  padding-left: 11px;
  ${p =>
    p.hasError &&
    css`
      color: ${theme.color.error};
    `}
`;
