// @flow
import type {Mutation} from 'common/graphql/types';
import gql from 'graphql-tag';

export const setProductItemStatusMutation: Mutation<{|id: number|}> = {
  gql: gql`
    mutation setProductItemStatusMutation($input: ProductItemStatusInput!) {
      product {
        setProductItemStatus(input: $input) {
          id
          isDeleted
          status
        }
      }
    }
  `,
  //Optimistically set the value of isDeleted based isOnline while waiting for actual response.
  optimisticResponse: ({variables}) => {
    const {id, isOnline} = variables.input;
    return {
      product: {
        setProductItemStatus: {
          id,
          isDeleted: !isOnline,
          __typename: 'ProductItem',
        },
        __typename: 'ProductMutation',
      },
    };
  },
  selector: ['product', 'setProductItemStatus'],
};

export const setProductItemInStock: Mutation<{|id: number|}> = {
  gql: gql`
    mutation setProductItemInStockMutation($id: Int!) {
      product {
        setProductItemInStock(id: $id) {
          id
        }
      }
    }
  `,
};
