// @flow
import Button from 'components/Button';
import {Text} from 'componentsStyled/Typography/Texts';
import withSubmit from 'hoc/withSubmit';
import ModalBody from 'modals/_Body';
import ModalContent from 'modals/_Content';
import ModalControls from 'modals/_Controls';
import ModalHeader from 'modals/_Header';
import React from 'react';
import type {Component, HOC} from 'recompose';

const SingleButtonModal = ({title, message, submit, buttonText, close, loading, component}) => (
  <ModalBody close={close} maxWidth={70}>
    <ModalHeader title={title} />
    <ModalContent>{component ? component : <Text center>{message}</Text>}</ModalContent>
    <ModalControls>
      <Button small loading={loading} onClick={() => submit()}>
        {buttonText}
      </Button>
    </ModalControls>
  </ModalBody>
);

type Outter = {|
  close: Function,
  onConfirm: void => Promise<mixed>,
  title: string,
  message?: string,
  buttonText: string,
  component?: Component,
|};

const enhancer: HOC<*, Outter> = withSubmit({
  submit: props => props.onConfirm,
  onSuccess: props => props.close,
  errorText: 'Something went wrong!',
});

export default enhancer(SingleButtonModal);
