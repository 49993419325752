// @flow
import {ApolloLink} from 'apollo-link';

import {getAccessToken} from './crypto';

// adds access token header and product group header
const linkSetHeaders = new ApolloLink((operation, forward) => {
  const token = getAccessToken();

  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      ...(token ? {authorization: `Bearer ${token}`} : {}),
    },
  }));

  return forward(operation);
});

export default linkSetHeaders;
