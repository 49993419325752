// @flow
import {getAffiliateQuery} from 'data/user/graphql/queries';
import type {Affiliate} from 'data/user/types';
import {getAffiliateIdFromAccessToken} from 'global/apolloClient/crypto';
import withQuery from 'hoc/withQuery';
import {omit} from 'ramda';
import {type HOC, compose, mapProps, withProps} from 'recompose';

type Added = {|
  affiliate: Affiliate,
|};

function withAffiliate<T>(): HOC<
  // $ExpectError
  {...$Exact<Added>, ...$Exact<T>},
  T
> {
  return compose(
    withQuery(getAffiliateQuery, {
      variables: () => ({
        id: getAffiliateIdFromAccessToken(),
      }),
    }),
    withProps(props => {
      const affiliate: ?Affiliate = props.data;
      return {affiliate};
    }),
    // remove the default prop data from withQuery, and only carry this information in the user prop
    mapProps(omit(['data']))
  );
}

export default withAffiliate;
