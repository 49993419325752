// @flow
import styled from 'styled-components';

export const MainNavWrap = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6.4rem;
  position: relative;
`;
