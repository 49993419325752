// @flow
import BookingList from 'components/BookingList';
import {selectDates} from 'data/app/selectors';
import {bookingsByAffiliate} from 'data/bookings/graphql/queries';
import {activeReservationStatuses} from 'data/reservations/constants';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {path} from 'ramda';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

const CheckedOutBookings = ({selectedDates, data}) => (
  <BookingList bookings={data} action="return" />
);

const mapStateToProps = state => ({
  selectedDates: selectDates(state),
});

type Outter = {|
  search: ?string,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withQuery(bookingsByAffiliate, {
    variables: ({search, selectedDates}) => ({
      filter: {
        search: search,
        status: activeReservationStatuses,
        orderBy: {
          field: 'start',
          direction: 'ASC',
        },
        start: path(['startDate'], selectedDates),
        end: path(['endDate'], selectedDates),
      },
    }),
  })
);

export default enhancer(CheckedOutBookings);
