// @flow
import ArrowIcon from 'assets/icons/ArrowIcon';
import Desktop from 'components/Media/Desktop';
import Mobile from 'components/Media/Mobile';
import * as React from 'react';

import {DatePickerController, DatePickerControllersWrap, DatePickerTitle} from './styled';

type Props = {
  prev: number => number,
  next: number => number,
  month: moment$Moment,
  nextMonth: moment$Moment,
};

const MobileDatePickerController = ({prev, next, month}: Props) => (
  <Mobile>
    <DatePickerControllersWrap>
      <DatePickerController onClick={prev} type="button">
        <ArrowIcon rotation={'left'} />
      </DatePickerController>
      <DatePickerTitle>{month.format('MMMM YYYY')}</DatePickerTitle>
      <DatePickerController onClick={next} type="button">
        <ArrowIcon rotation={'right'} />
      </DatePickerController>
    </DatePickerControllersWrap>
  </Mobile>
);

export const SingleMonthDatePickerController = (props: Props) => {
  const {prev, next, month} = props;
  return (
    <>
      <Desktop>
        <DatePickerController onClick={prev} type={'button'}>
          <ArrowIcon rotation={'left'} />
        </DatePickerController>
        <DatePickerTitle>{month.format('MMMM YYYY')}</DatePickerTitle>
        <DatePickerController onClick={next} type={'button'}>
          <ArrowIcon rotation={'right'} />
        </DatePickerController>
      </Desktop>
      <MobileDatePickerController {...props} />
    </>
  );
};

const DatePickerControllers = (props: Props) => {
  const {prev, next, month, nextMonth} = props;
  return (
    <React.Fragment>
      <Desktop>
        <DatePickerController onClick={prev} type={'button'}>
          <ArrowIcon rotation={'left'} />
          <DatePickerTitle>{month.format('MMMM YYYY')}</DatePickerTitle>
        </DatePickerController>
        <DatePickerController onClick={next} type={'button'}>
          <DatePickerTitle>{nextMonth.format('MMMM YYYY')}</DatePickerTitle>
          <ArrowIcon rotation={'right'} />
        </DatePickerController>
      </Desktop>
      <MobileDatePickerController {...props} />
    </React.Fragment>
  );
};

export default DatePickerControllers;
