// @flow
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import {StyledSwitch, StyledSwitchWrap} from './styled';

const Switch = ({value, handleChange, small, ...rest}) => (
  <StyledSwitchWrap>
    <StyledSwitch value={value} {...rest} onClick={handleChange} isSmall={small} />
  </StyledSwitchWrap>
);

type Enhancer = {
  value: boolean,
  onChange: Function,
  small?: boolean,
};

const enhancer: HOC<*, Enhancer> = withHandlers({
  handleChange: props => () => props.onChange(!props.value),
});

export default enhancer(Switch);
