// @flow
import styled from 'styled-components';

export const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 492px;
`;
export const ClearTextWrap = styled.div`
  cursor: pointer;
`;

export const PadWrap = styled.div`
  canvas {
    width: 49.2rem;
    height: 10rem;
  }
`;
