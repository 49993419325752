// @flow
import type {Selector} from 'common/redux/types';
import {compose, prop} from 'ramda';

import type {User} from './types';

// selects the appropriate substore from root store
const base = prop('user');

export const selectLoggedIn: Selector<boolean> = compose(prop('loggedIn'), base);

export const selectLoggingOut: Selector<boolean> = compose(prop('loggingOut'), base);

export const selectUser: Selector<?User> = compose(prop('user'), base);

export const selectIsMasquerading: Selector<boolean> = compose(prop('isMasquerading'), base);
