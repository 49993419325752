// @flow
import moment from 'moment';

import type {DateRange, DateRangeValue, DateString} from './types';

// $ExpectError
export const fromDateString = (s: DateString): string => s;
// $ExpectError
export const toDateString = (s: string): DateString => s;

export const momentFromDateString = (v: DateString): moment =>
  // $ExpectError - unable to work around this without altering moment definitions
  moment(v, 'YYYY-MM-DD');

export const dateStringFromMoment = (v: moment): DateString =>
  // $ExpectError
  v.format('YYYY-MM-DD');

export const dateRangeFromValue = (v: ?DateRangeValue): ?DateRange => {
  if (!v) {
    return null;
  }

  // we have to pack it and unpack it for flow to understand
  const {startDate, endDate} = v;
  if (startDate && endDate) {
    return {
      startDate,
      endDate,
    };
  }

  return null;
};

export const safelyDestructureDate = (v: ?DateRangeValue): DateRangeValue => {
  if (!v)
    return {
      startDate: undefined,
      endDate: undefined,
    };

  return v;
};
