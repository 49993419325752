// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const DatePickerMonthWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  margin-bottom: 2.5rem;

  ${mqMax.tabletWide} {
    height: 100%;
    padding: 0;
    width: 35rem;
  }
  ${mqMax.phoneWide} {
    width: 25rem;
  }
`;

export const DatePickerWeekdaysWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.5rem;
`;

export const DatePickerWeekday = styled.div`
  display: flex;
  width: calc(100% / 7);
  flex-direction: row;
  align-self: center;
  justify-content: center;
  font-size: 1.4rem;
  align-items: center;
  height: 2.8rem;
  padding: 1rem 0;
`;

export const DatePickerWeek = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${mqMax.tabletWide} {
    height: 5rem;
  }
`;

export const DatePickerDayWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 7);
  height: 4rem;
  color: ${theme.color.darkGrey};
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
  border-top: none;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s all;

  ${p =>
    p.isBeforeToday &&
    p.greyOutPastDates &&
    css`
      opacity: 0.5;
    `}

  ${p =>
    p.isEmpty &&
    css`
      margin-top: 0.1rem;
      cursor: default;
    `}

  ${p =>
    p.isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      > * {
        text-decoration: line-through;
      }
    `}

  ${mqMax.tabletWide} {
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  ${p =>
    !p.isDisabled &&
    css`
      &:hover {
        opacity: 1;
        font-weight: 900;
        font-size: 1.6rem;
      }
    `}
`;

export const DatePickerDay = styled.div`
  position: absolute;
  display: flex;

  ${p =>
    p.selected &&
    css`
      background: ${theme.color.primary};
      border-radius: 15rem;
      padding: 1rem;
      color: white;
    `}

  ${p =>
    p.outlineDate &&
    css`
      border: 1px solid ${theme.color.darkGrey};
      border-radius: 15rem;
      padding: 1rem;
    `}

  ${mqMax.tabletWide} {
    font-size: 1.4rem;

    ${p =>
      p.outlineDate &&
      css`
        padding: 0.5rem 1rem;
      `}

    ${p =>
      p.selected &&
      css`
        background: ${theme.color.primary};
        padding: 0.5rem 1rem;
        color: white;
      `}
  }
`;
