// @flow
import styled from 'styled-components';

export const LogoWrap = styled.div`
  width: 18.5rem;
  margin: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
