// @flow
import ProductVariantNameFormat from 'components/ProductVariantNameFormat';
import OutOfServiceStatus from 'components/Status/OutOfServiceStatus';
import QuiverStatus from 'components/Status/QuiverStatus';
import {
  Body,
  Bottom,
  Col,
  Content,
  ContentWrap,
  Hr,
  ImageWrap,
  ProductImage,
  StatusLabel,
  StyledText,
} from 'components/Tile/styled';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {ProductItem} from 'data/product/types';
import React from 'react';

import ItemActions from './ItemActions';

type Props = {|
  product: ProductItem,
|};

const QuiverItem = ({product}: Props) => {
  const indexImage = selectIndexImage(product.productVariant.product.images);
  const imageSize = minHeightImg(indexImage, 160);

  const item = {
    name: product.productVariant.product.name,
    manufacturer: product.productVariant.product.manufacturer.name,
    code: product.code,
  };

  return (
    <React.Fragment>
      <Body>
        <StatusLabel small>
          <QuiverStatus status={product.status} small />
          {product.isDeleted && <OutOfServiceStatus small />}
        </StatusLabel>
        <ContentWrap>
          <Content>
            <Col justify="center">
              <ImageWrap>
                <ProductImage>
                  <img src={imageSize.url} alt={indexImage.alt} />
                </ProductImage>
              </ImageWrap>
            </Col>
          </Content>
          <Content>
            <Col marginTop={3}>
              <StyledText black book>
                {product.futureReservationsCount} Future Reservations
              </StyledText>
              <StyledText black book>
                {product.damagesCount} Previous Damages
              </StyledText>
              <Hr border bottom />
              <ProductVariantNameFormat productVariant={product.productVariant} compact />
            </Col>
          </Content>
        </ContentWrap>
      </Body>
      <Bottom>
        <ItemActions item={item} />
      </Bottom>
    </React.Fragment>
  );
};

export default QuiverItem;
