// @flow
import type {Reservation} from 'data/reservations/types';
import React from 'react';

import {momentFromDateString} from '../../../../data/units/date/helpers';
import {DateTileWrap, DateWrap, Day, Month} from './styled';

type Props = {|
  reservation: Reservation,
|};

const DateTile = ({reservation}: Props) => {
  const {start, end} = reservation.booking;

  const momentStart = momentFromDateString(start);
  const momentEnd = momentFromDateString(end);

  return (
    <DateTileWrap>
      <DateWrap>
        <Day>{momentStart.format('DD')}</Day>
        <Month>{momentStart.format('MMM')}</Month>
      </DateWrap>
      <Day>-</Day>
      <DateWrap>
        <Day>{momentEnd.format('DD')}</Day>
        <Month>{momentEnd.format('MMM')}</Month>
      </DateWrap>
    </DateTileWrap>
  );
};

export default DateTile;
