// @flow
import Avatar from 'components/Avatar';
import LogoLink from 'components/LogoLink';
import UnitSelector from 'components/UnitSelector';
import {logout} from 'data/user/actions';
import {selectUser} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import urls from 'pages/urls';
import * as React from 'react';

import {Link, LogoWrap, SidebarWrap} from './styled';

const Sidebar = ({isOpen, close, logout, user}) => (
  <SidebarWrap isOpen={isOpen}>
    <LogoWrap>
      <LogoLink type="default" />
    </LogoWrap>
    <Avatar big />
    <UnitSelector group="Unit" close={close} />
    <Link to={urls.customers}>Customers</Link>
    <Link onClick={logout}>Logout</Link>
  </SidebarWrap>
);

const mapStateToProps = state => ({
  user: selectUser(state),
});

export default withConnect(mapStateToProps, {logout})(Sidebar);
