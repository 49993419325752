// @flow
import {mq, mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const ModalControlsWrap = styled.div`
  flex-shrink: 0;
  background: ${theme.color.white};
  padding: 1.5rem 2.4rem;
  display: flex;
  justify-content: center;

  ${p =>
    p.column &&
    css`
      flex-direction: column;
      align-items: center;
    `}
  ${mqMax.tabletWide} {
    align-items: center;
    padding: 0;
    flex-direction: column;
  }
`;

export const ButtonSpread = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: wrap;

  ${mqMax.tablet} {
    flex-direction: column;
    width: 100%;
  }

  //Override default spacing for buttons
  button {
    margin: 0;

    ${mq.tablet} {
      min-width: initial;
    }
  }
`;
