// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const ImageWrap = styled.div`
  padding: 2rem;
  background: ${theme.color.white};
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopWrap = styled.div`
  display: flex;
`;

export const Half = styled.div`
  width: 50%;
  padding-left 2rem;

  ${p =>
    p.white &&
    css`
      position: relative;

      &::after {
        position: fixed;
        left: 0;
        top: -20rem;
        height: 150vh;
        width: 100vw;
        background: #fff;
        content: '';
        z-index: -1;
      }
    `}
`;
export const StyledImage = styled.img`
  display: flex;
  max-width: 55%;
  max-height: 55%;
  margin: auto;
`;

export const DetailWrap = styled.div`
  padding-top: 8rem;
`;
