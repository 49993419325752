import theme from 'global/theme';
import styled from 'styled-components';

export const FulfilmentIndicatorWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
`;
