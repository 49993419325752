// @flow
import Mobile from 'components/Media/Mobile';
import {getWeeks} from 'data/calendar/helpers';
import type {DateRangeValue, DateString} from 'data/units/date/types';
import moment from 'moment';
import {path} from 'ramda';
import * as React from 'react';
import {type HOC, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';

import DatePickerControllers, {SingleMonthDatePickerController} from './DatePickerControllers';
import {DatePickerTitle} from './DatePickerControllers/styled';
import DatePickerMonth from './DatePickerMonth';
import {DatePickerHeaderWrap, DatePickerMonthsWrap, DatePickerWrap} from './styled';

export type DateSelectionType = 'single' | 'start-and-end';

type Outter = {
  /**
   * Sets the selected date range in the calendar
   */
  value: DateRangeValue,

  /**
   * Change handler called when the apply button is clicked
   */
  onChange(dateRange?: DateRangeValue): void,

  /**
   * Defines whether the picker shows a single or multiple months
   */
  singleMonth?: boolean,

  /**
   * Specifies the desired value to obtain from this picker, either a single date or a start/end range
   */
  selectionType?: DateSelectionType,

  /**
   * Function to determine whether a specific date should be disabled
   */
  shouldDisableDate?: (date: moment) => boolean,

  /**
   * Whether or not past dates should appear 'greyed-out'
   */
  greyOutPastDates?: boolean,

  /**
   * A list of dates that will have a border applied to them
   */
  datesToOutline?: (DateString | moment)[],
};

type Props = Outter & {
  /**
   * The number of months to offset from the current month for the start month
   */
  offset: number,
  /**
   * Moves the month back the specified number of months
   */
  prev: number => number,
  /**
   * Moves the month forward the specified number of months
   */
  next: number => number,
  /**
   * Key handler
   */
  handleKeyDown: Function,
};

const DatePicker = ({
  offset,
  prev,
  next,
  handleKeyDown,
  value,
  onChange,
  shouldDisableDate,
  greyOutPastDates,
  datesToOutline,
  singleMonth = false,
  selectionType = 'start-and-end',
}: Props) => {
  const month = moment().add(offset, 'months');
  const currentMonthWeeks = getWeeks(month);
  const nextMonth = month.clone().add(1, 'months');
  const nextMonthWeeks = getWeeks(nextMonth);

  const ControllerComponent = singleMonth ? SingleMonthDatePickerController : DatePickerControllers;

  return (
    <DatePickerWrap singleMonth={singleMonth}>
      <DatePickerHeaderWrap>
        <ControllerComponent month={month} nextMonth={nextMonth} prev={prev} next={next} />
      </DatePickerHeaderWrap>

      <DatePickerMonthsWrap>
        <DatePickerMonth
          weeks={currentMonthWeeks}
          selectedDates={value}
          filterDates={onChange}
          selectionType={selectionType}
          shouldDisableDate={shouldDisableDate}
          greyOutPastDates={greyOutPastDates}
          datesToOutline={datesToOutline}
        />
        {!singleMonth && (
          <>
            <Mobile>
              <DatePickerTitle>{nextMonth.format('MMMM YYYY')}</DatePickerTitle>
            </Mobile>
            <DatePickerMonth
              weeks={nextMonthWeeks}
              selectedDates={value}
              filterDates={onChange}
              selectionType={selectionType}
              shouldDisableDate={shouldDisableDate}
              greyOutPastDates={greyOutPastDates}
              datesToOutline={datesToOutline}
            />
          </>
        )}
      </DatePickerMonthsWrap>
    </DatePickerWrap>
  );
};

const enhancer: HOC<*, Outter> = compose(
  withStateHandlers(
    {
      offset: 0,
    },
    {
      prev:
        ({offset}) =>
        () => ({offset: offset - 1}),
      next:
        ({offset}) =>
        () => ({offset: offset + 1}),
      setOffset:
        ({offset}) =>
        v => ({offset: v}),
    }
  ),
  withHandlers({
    handleKeyDown: props => e => {
      const key = e ? e.keyCode : null;
      if (!key) return;

      const {prev, next} = props;

      if (key === 37) prev();
      if (key === 39) next();
    },
  }),
  lifecycle({
    componentDidMount() {
      const {value, setOffset, selectionType} = this.props;

      const offsetDate =
        selectionType === 'single' ? path(['startDate'], value) : path(['endDate'], value);
      if (offsetDate) {
        const getTotalMonths = (date: moment) => date.month() + date.year() * 12;
        const offsetMonths = getTotalMonths(moment.utc(offsetDate));
        const currentMonths = getTotalMonths(moment.utc());
        setOffset(offsetMonths - currentMonths);
      }
    },
    componentWillMount() {
      document.addEventListener('keydown', this.props.handleKeyDown);
    },
    componentWillUnmount() {
      document.removeEventListener('keydown', this.props.handleKeyDown);
    },
  })
);

/**
 * A component that allows for the specification of either a date range or a single date using a calendar
 */
export default enhancer(DatePicker);
