// @flow
import {mq} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled from 'styled-components';

export const ModalHeaderWrap = styled.div`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${theme.zIndices.header};
  background: ${theme.color.white};
`;

export const LeftIconWrap = styled(StyledLink)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }

  ${mq.tabletWide} {
    top: 1.25rem;
    left: 0.5rem;
  }
`;

export const RightIconWrap = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  ${mq.tabletWide} {
    top: 1.25rem;
    right: 0.5rem;
  }
`;
