// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const Select = styled.select`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid #00bed0;
  width: ${p => p.width || 49.2}rem;
  margin-top: 0.5em;

  ${mqMax.tablet} {
    width: 100%;
  }
`;
