// @flow
import {getWeeks} from 'data/calendar/helpers';
import moment from 'moment';
import * as React from 'react';
import {type HOC, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';

import Controllers from './Controllers';
import Month from './Month';
import {CalendarWrap, HeaderWrap} from './styled';

const Calendar = ({offset, prev, next, handleKeyDown}) => {
  const month = moment.utc().add(offset, 'months');
  const prevMonth = month.clone().subtract(1, 'months');
  const nextMonth = month.clone().add(1, 'months');

  const weeks = getWeeks(month);

  return (
    <CalendarWrap>
      <HeaderWrap>
        <Controllers
          prev={prev}
          prevMonth={prevMonth}
          month={month}
          next={next}
          nextMonth={nextMonth}
        />
      </HeaderWrap>
      <Month month={month} weeks={weeks} />
    </CalendarWrap>
  );
};

const enhancer: HOC<*, {||}> = compose(
  withStateHandlers(
    {
      offset: 0,
    },
    {
      prev:
        ({offset}) =>
        () => ({offset: offset - 1}),
      next:
        ({offset}) =>
        () => ({offset: offset + 1}),
    }
  ),
  withHandlers({
    handleKeyDown: props => e => {
      const key = e ? e.keyCode : null;
      if (!key) return;

      const {prev, next} = props;

      if (key === 37) prev();
      if (key === 39) next();
    },
  }),
  lifecycle({
    componentWillMount() {
      document.addEventListener('keydown', this.props.handleKeyDown);
    },
    componentWillUnmount() {
      document.removeEventListener('keydown', this.props.handleKeyDown);
    },
  })
);

export default enhancer(Calendar);
