// @flow
import Table from 'components/Table';
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const Title = styled.h1`
  font-weight: bold;
  font-size: var(--title-font-sie);
  margin: 0;
  line-height: 1.25;
  text-align: center;
  color: ${theme.color.dark};
`;

export const Logo = styled.img`
  max-height: var(--max-image-height);
  max-width: 100%;
`;

export const Label = styled.span`
  font-size: var(--text-font-size);
  font-weight: bold;
  line-height: 2;
  color: ${theme.color.darkerGrey};
`;

export const Container = styled.div`
  --text-font-size: 1.2rem;
  --title-font-size: 2.4rem;
  --max-image-height: 5.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem;

  //Enable background colours in print mode
  -webkit-print-color-adjust: exact !important;

  //Remove default print margins
  @media print {
    margin: 0;

    //Hide the print header/footer that include page numbers/date/page title/etc...
    @page {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  max-width: 60rem;
  width: 100%;
  padding: 3rem 2.4rem;
  background: white;

  p {
    font-weight: 400;
    font-size: var(--text-font-size);
    line-height: 2;
    margin: 0;
    word-break: break-word;
    color: ${theme.color.darkerGrey};
  }

  @media print {
    max-width: 100%;
    height: 100vh;
  }
`;

export const StyledTable = styled(Table)`
  margin-top: 2.4rem;

  tr th {
    color: ${theme.color.darkGrey};
    letter-spacing: 0.8px;
    height: 3.2rem;
  }

  tr td {
    color: ${theme.color.dark};
    height: 4rem;
  }

  tr th,
  tr td {
    font-size: var(--text-font-size);
    padding: 0.8rem;
  }

  thead tr,
  tbody tr:nth-child(even) {
    background: ${theme.color.ultralight};
  }
`;

export const TitleSection = styled.div`
  display: grid;
  grid-template-rows: var(--max-image-height);
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  align-items: center;
`;

export const BookingDetailSection = styled.div`
  display: grid;
  grid-template-columns: 8rem 5fr;
  grid-column-gap: 0.8rem;
  margin-top: 3rem;
`;

export const DeliveryDetailSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;

  ${Label} {
    margin-top: 0.8rem;
  }
`;

const detailsColumnStyle = css`
  display: flex;
  flex-direction: column;
`;

export const AddressDetails = styled.div`
  ${detailsColumnStyle}
`;

export const ContactDetails = styled.div`
  ${detailsColumnStyle}
`;
