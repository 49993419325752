// @flow
const errorMap = {
  // Generic
  E_INTERNAL_SERVER_ERROR: 'Internal Server Error',
  E_CONFLICT: 'Record already exists',
  E_NOT_FOUND: 'Not found',
  E_AUTHORIZATION: 'You are not allowed to access',
  E_AUTHENTICATION: 'Authentication failed',
  E_RATE_LIMIT: 'Too many requests',

  // Product
  E_PRODUCT_PRODUCT_ITEM_NOT_FOUND: 'Product not found',

  // Auth
  E_AUTH_REFRESH_TOKEN_NOT_VALID: 'Refresh token is not valid',
  E_AUTH_RESET_PASSWORD_CODE_NOT_FOUND: 'No reset code was found',

  // User
  E_USER_NOT_FOUND: 'Incorrect email or password',
  E_USER_PASSWORD_NOT_STRONG: 'Password is not strong enough',

  // Profile
  E_PROFILE_INVALID_OPTION_VALUE: 'Selected option is invalid',

  // Email
  E_EMAIL_ALREADY_REGISTERED: 'Email is already registered',

  // Customer
  E_CUSTOMER_NOT_FOUND: 'Customer not found',

  // Payment
  E_PAYMENT_API_CONNECTION: 'Payment API connection failed',
  E_PAYMENT_API: 'Payment API failed',
  E_PAYMENT_CARD: 'Provided card is not valid. Please update your card.',
  E_PAYMENT_INVALID_REQUEST: 'Invalid payment request',
  E_PAYMENT_RATE_LIMIT: 'Too many payment requests',
  E_PAYMENT_NO_TOKEN: 'Missing payment token',
  E_PAYMENT_PREPAID_CARD: 'Prepaid cards are not allowed',
  E_PAYMENT_CUSTOMER_EXISTS: 'Customer already exists',
  E_PAYMENT_CUSTOMER_DOES_NOT_EXISTS: 'Customer does not exist',
  E_PAYMENT_ERROR:
    "The customer's payment was declined. They will need to update their payment method from their account",

  // Refund
  E_INVALID_REFUND_AMOUNT: 'Unable to refund more than the amount paid for the booking',

  // Reservations
  E_RESERVATION_NOT_FOUND: 'Reservation not found',
  E_RESERVATION_DATES_CONFLICT: 'Reservation cannot be done at selected dates',
  E_RESERVATION_PERIOD_TOO_LONG: 'Reservation period is too long.',
  E_RESERVATION_VALIDATION_DATE: 'Wrong reservation date format',
  E_RESERVATION_PAST_DATE: 'Selected store is already closed for selected date',
  E_RESERVATION_PRODUCT_CONFLICT: 'You have already reserved a product for these dates',
  E_RESERVATION_SHOP_CLOSED:
    'Unfortunately the shop is closed on your start and / or end date. Please select different dates.',
  E_RESERVATION_EMPLOYEE_NOT_FOUND: 'Employee not found',
  E_RESERVATION_CHECKOUT_RESERVATION_NOT_ALLOWED:
    'An item in this booking is still post-processing and cannot be checked out. You can manage item status from your Inventory.',

  // Affiliate
  E_AFFILIATE_NOT_FOUND: 'Affiliate not found',
  E_AFFILIATE_CLOSED:
    'Unfortunately the shop is closed on your start and / or end date. Please select different dates.',
  E_AFFILIATE_CLOSED_EXCEPTION:
    'Unfortunately the shop is closed on your start and / or end date. Please select different dates.',

  // Promocode
  E_PROMOCODE_NOT_FOUND: 'Invalid promo code',
};

export default errorMap;
