// @flow
import {mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import theme from 'global/theme';
import styled from 'styled-components';

export const Link = styled(StyledLink)`
  font-size: 1.5rem;
  font-weight: ${theme.fontWeights.book};
  text-align: center;
  margin-left: 1.5rem;
  border: 0;
  color: ${theme.color.black};
  opacity: 0.5;
  position: relative;

  &.active {
    opacity: 1;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      height: 0.4rem;
      width: 100%;
      background: ${theme.color.primary};
      transform: translateX(-50%);
    }
  }

  ${mqMax.tabletWide} {
    margin: 0.5rem;
  }
`;

export const MainMenu = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 3rem;
  padding-bottom: 3rem;
  width: 50%;

  ${mqMax.tabletWide} {
    width: 100%;
    flex-wrap: wrap;
  }
`;
