// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const StyledTh = styled.th`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.3rem;
  height: 4rem;
  color: ${theme.color.midGrey};

  ${props =>
    props.sortable &&
    css`
      transition: color 0.25s ease;
      cursor: pointer;

      &:hover {
        color: ${theme.color.darkGrey};
      }
    `}
`;

export const Indicator = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  margin-left: 1.5rem;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    transition: border 0.25s ease;
  }

  &::before {
    border-bottom: 0.7rem solid
      ${props => (props.active && !props.desc ? theme.color.primary : theme.color.lightGrey)};

    margin-bottom: 0.4rem;
  }

  &::after {
    border-top: 0.7rem solid
      ${props => (props.active && props.desc ? theme.color.primary : theme.color.lightGrey)};
  }
`;
