// @flow
import withValue from 'hoc/withValue';
import Page from 'pages/_Page';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import QuiverData from './QuiverData';

const Quiver = ({onQueryChange, value}) => (
  <Page onChange={onQueryChange} value={value} initialValue="" overviewType="inventory">
    <QuiverData search={value} />
  </Page>
);

const enhancer: HOC<*, {||}> = compose(
  withValue,
  withHandlers({
    onQueryChange: props => props.setValue,
  })
);

export default enhancer(Quiver);
