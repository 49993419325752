// @flow
import * as yup from 'yup';

import {requiredText} from './shared';
import type {Validator} from './types';

export const stringOptional: Validator<?string> = yup.string().nullable();

export const stringRequired: Validator<string> = stringOptional
  .required(requiredText)
  .test('is-not-whitespace', 'Must contain non-empty characters', v => /\S/.test(v));

export const emailRequired: Validator<string> = stringRequired.email(
  'Must be correct email address'
);
