// @flow
import {mq, mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled from 'styled-components';

import {color} from './enhancers';

export const FieldTitle = styled.h4`
  font-size: 1.3rem;
  text-transform: uppercase;
  color: ${theme.color.black};
  margin: 0;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  ${color};
`;

export const Header = styled.div`
  display: inline-block;
  width: 100%;
  align-items: flex-start;
  color: ${theme.color.black};
  font-weight: ${theme.fontWeights.book};
  margin: 1rem 0 0 0;
`;

export const SubTitle = styled.h4`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: ${theme.color.black};
  line-height: 1.2;
  margin: 0.5rem 0;

  ${color};
`;

export const Title = styled.h3`
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: ${theme.fontWeights.ultra};
  color: ${theme.color.black};
  line-height: 1;
  margin: 1rem 0;
  letter-spacing: 0.08rem;

  ${mq.tabletWide} {
    font-size: 4.8rem;
  }
`;

export const PageTitle = styled(Title)`
  position: relative;
  text-align: center;
  margin-top: 0;
  margin-bottom: 4rem;

  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 50%;
    height: 0.4rem;
    width: 4rem;
    background: ${theme.color.primary};
    transform: translateX(-50%);

    ${mq.tabletWide} {
      width: 8rem;
    }

    ${mqMax.tabletWide} {
      height: 0;
    }
  }
`;

export const ModalTitle = styled(PageTitle)`
  font-size: 2.8rem;

  ${mqMax.tabletWide} {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;
