// @flow
import type {Selector} from 'common/redux/types';
import {compose, head, prop} from 'ramda';

import type {GrowlsState} from './reducer';
import type {Growl} from './types';

const base: Selector<GrowlsState> = prop('growls');

export const selectTopmostGrowl: Selector<?Growl> = compose(head, base);
