// @flow

import React from 'react';

import {Text} from '../../../componentsStyled/Typography/Texts';
import type {Reservation} from '../../../data/reservations/types';
import type {BookingStatus} from '../index';
import {ReturnStatus, SuggestedCharge} from './return-note-styled';

type ReturnNoteProps = $Exact<{
  status: BookingStatus,
  days?: number,
  recommendedAmount: number,
  reservations: [Reservation],
  totalPaid: number,
  handleAsDelivery?: boolean,
}>;

const ReturnNote = ({
  status,
  days,
  reservations,
  handleAsDelivery,
  totalPaid,
  recommendedAmount,
}: ReturnNoteProps) => {
  if (days === undefined || days === null) {
    return;
  }
  const prependedText = handleAsDelivery ? 'This was dispatched ' : 'This return is ';

  if (status === 'onTime') {
    return (
      <ReturnStatus>
        <Text>
          {prependedText}
          <strong>on time.</strong>
        </Text>
      </ReturnStatus>
    );
  } else if (status === 'late' || status === 'early') {
    const dayString = days > 1 ? 'days' : 'day';
    return (
      <ReturnStatus>
        <Text>
          {prependedText}
          <strong>
            {days} {dayString} {status}
          </strong>
        </Text>
        {(status === 'late' || status === 'early') && (
          <SuggestedCharge>
            The suggested {status === 'late' ? 'charge' : 'refund'} is{' '}
            <strong>
              $
              {status === 'early' && totalPaid <= recommendedAmount
                ? totalPaid / 100
                : recommendedAmount / 100}
            </strong>
            . {status === 'early' && `The maximum refund is $${totalPaid / 100}.`}
          </SuggestedCharge>
        )}
      </ReturnStatus>
    );
  } else {
    throw new Error(`Unhandled status ${status}`);
  }
};

export default ReturnNote;
