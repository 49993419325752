// @flow
import {WidthContainer} from 'componentsStyled/Layout/Containers';
import {keys} from 'ramda';
import * as React from 'react';
import {type HOC, withStateHandlers} from 'recompose';

import {TabSwitch, TabSwitchLink} from './styled';

const Tabs = ({setActiveTabIndex, activeTabIndex, tabs, limitSwitchWidth, centerSwitch}) => {
  const SwitchWrap = limitSwitchWidth ? WidthContainer : React.Fragment;

  return (
    <React.Fragment>
      <SwitchWrap>
        <TabSwitch centerSwitch={centerSwitch}>
          {keys(tabs).map((k, i) => (
            <TabSwitchLink
              key={k}
              active={activeTabIndex === i}
              onClick={() => setActiveTabIndex(i)}
            >
              {k}
            </TabSwitchLink>
          ))}
        </TabSwitch>
      </SwitchWrap>
      {tabs[keys(tabs)[activeTabIndex]]}
    </React.Fragment>
  );
};

type Outter = {|
  tabs: {[string]: React.Node},
  startingIndex?: number,
  limitSwitchWidth?: boolean,
  centerSwitch?: boolean,
|};

const enhancer: HOC<*, Outter> = withStateHandlers(
  props => ({
    activeTabIndex: props.startingIndex || 0,
  }),
  {
    setActiveTabIndex: () => v => ({activeTabIndex: v}),
  }
);

export default enhancer(Tabs);
