// @flow
import {chooseActivity, filterDates} from 'data/app/actions';
import {activities} from 'data/app/constants';
import {selectAppConfig} from 'data/app/selectors';
import {toDateString} from 'data/units/date/helpers';
import withConnect from 'hoc/withConnect';
import withRouter from 'hoc/withRouter';
import moment from 'moment';
import urls from 'pages/urls';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import {EventBar, EventWrap, Text} from './styled';

type Props = {
  event: {
    day: string,
    events: {
      checkouts: number,
      returns: number,
    },
  },
};

const Events = ({event, handleAction, appConfig}) => {
  const {checkouts, returns} = event.events;

  let checkoutsText = `${checkouts} checkouts`;
  let returnsText = `${returns} returns`;

  const eventDay = toDateString(event.day);
  const isLate = moment.utc(eventDay).isBefore(moment.utc(), 'day');

  if (isLate) {
    checkoutsText = `${checkouts} pending checkouts`;
    returnsText = `${returns} pending returns`;
  }

  return (
    <EventWrap>
      {checkouts > 0 && (
        <EventBar checkouts isLate={isLate} onClick={() => handleAction('checkouts')}>
          <Text title={checkoutsText}>{checkoutsText}</Text>
        </EventBar>
      )}
      {returns > 0 && (
        <EventBar returns isLate={isLate} onClick={() => handleAction('returns')}>
          <Text title={returnsText}>{returnsText}</Text>
        </EventBar>
      )}
    </EventWrap>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const mapDispatchToProps = {
  chooseActivity,
  filterDates,
};

const enhancer: HOC<*, Props> = compose(
  withRouter,
  withConnect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleAction: props => (action: string) => {
      const date = toDateString(props.event.day);

      props.filterDates({startDate: date, endDate: date});
      props.chooseActivity(activities.ALL);

      if (action === 'checkouts') return props.history.push(urls.upcoming);
      if (action === 'returns') return props.history.push(urls.returns);
    },
  })
);

export default enhancer(Events);
