// @flow
import * as yup from 'yup';

import {requiredText} from './shared';
import type {Validator} from './types';

export const optional = yup.mixed().nullable();
export const required = yup.mixed().required(requiredText);

export const numberOptional: Validator<?number> = yup.number().nullable();

export const numberRequired: Validator<number> = numberOptional.required(requiredText);

export const rangeRequired = (
  min: number,
  max: number,
  transform?: (n: number) => number
): Validator<?number> =>
  numberRequired
    .min(min, `Must be greater or equal to ${transform === undefined ? min : transform(min)}`)
    .max(max, `Must be less or equal to ${transform === undefined ? max : transform(max)}`);

export const dinRequired = (min: number, max: number): Validator<?number> =>
  numberRequired
    .min(min, `Must be greater or equal to ${min}`)
    .max(max, `Must be less or equal to ${max}`)
    .test(``, `Not a valid DIN. Check tooltip for help.`, din => din % 0.25 === 0);
