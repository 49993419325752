// @flow
import React from 'react';

export type Props = {colour?: string, size?: number};

const PrintIcon = ({size = 14, colour = 'currentColor'}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7499 7.2085C10.4049 7.2085 10.1249 7.48818 10.1249 7.8335C10.1249 8.17855 10.4049 8.4585 10.7499 8.4585C11.095 8.4585 11.3749 8.17855 11.3749 7.8335C11.3749 7.48818 11.095 7.2085 10.7499 7.2085ZM11.5833 4.91683V2.93454C11.5833 2.60303 11.4515 2.28506 11.2171 2.05068L9.86607 0.699642C9.63169 0.465267 9.31372 0.333496 8.98221 0.333496H3.21034C2.77179 0.333496 2.41659 0.706673 2.41659 1.16683V4.91683C1.26606 4.91683 0.333252 5.84964 0.333252 7.00016V10.3335C0.333252 10.5637 0.51971 10.7502 0.749919 10.7502H2.41659V13.2502C2.41659 13.4804 2.60304 13.6668 2.83325 13.6668H11.1666C11.3968 13.6668 11.5833 13.4804 11.5833 13.2502V10.7502H13.2499C13.4801 10.7502 13.6666 10.5637 13.6666 10.3335V7.00016C13.6666 5.84964 12.7338 4.91683 11.5833 4.91683ZM3.66659 1.5835H8.66659V2.8335C8.66659 3.0637 8.85304 3.25016 9.08325 3.25016H10.3333V4.91683H3.66659V1.5835ZM10.3333 12.4168H3.66659V10.7502H10.3333V12.4168ZM12.4166 9.50016H1.58325V7.00016C1.58325 6.54079 1.95721 6.16683 2.41659 6.16683H11.5833C12.0426 6.16683 12.4166 6.54079 12.4166 7.00016V9.50016Z"
      fill={colour}
    />
  </svg>
);

export default PrintIcon;
