// @flow
import CrossIcon from 'assets/icons/CrossIcon';
// $ReactHooks
import React, {Node, useCallback, useEffect} from 'react';

import {stopPropagation} from '../../common/events';
import {Body, Cross, ModalBodyWrapper, StyledOverlay} from './styled';

type Outter = {|
  close?: Function,
  children: Node,
  renderCross?: boolean,
  maxWidth?: number,
  showOverflow?: boolean,
|};

const ModalBody = ({
  close,
  children,
  maxWidth,
  renderCross = true,
  showOverflow = false,
}: Outter) => {
  const escapeListener = useCallback(
    // NOTE(Barry): Kind of gross, but it looks like our version of flow does not have the type that we need for the
    // keyboard input event
    (e: any) => {
      if (e.code === 'Escape' && close) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    window.addEventListener('keydown', escapeListener);
    const body = document.body;
    if (body) {
      body.classList.add('overflowHidden');
    }

    return function cleanup() {
      window.removeEventListener('keydown', escapeListener);
      const body = document.body;
      if (body) {
        body.classList.remove('overflowHidden');
      }
    };
  }, [escapeListener]);

  return (
    <ModalBodyWrapper>
      <StyledOverlay open dark onClick={close} />
      <Body onClick={stopPropagation} maxWidth={maxWidth} showOverflow={showOverflow}>
        {close && renderCross && (
          <Cross onClick={close}>
            <CrossIcon />
          </Cross>
        )}
        {children}
      </Body>
    </ModalBodyWrapper>
  );
};

export default ModalBody;
