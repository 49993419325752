// @flow
import type {ExtractActionTypes} from 'common/redux/types';

import * as actions from './actions';
import type {Growl} from './types';

export type GrowlsState = Growl[];

const initialState: GrowlsState = [];

type Action = ExtractActionTypes<typeof actions>;

function growlReducer(state: GrowlsState = initialState, action: Action): GrowlsState {
  switch (action.type) {
    case 'GROWL/SHOW':
      return state.concat(action.payload);
    case 'GROWL/CLOSE':
      return state.slice(1);
    case 'USER/LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export default growlReducer;
