// @flow
import {
  Col,
  Content,
  ManufacturerText,
  ProductText,
  ResCodeText,
  StyledText,
} from 'components/Tile/Shared/Actions';
import React from 'react';

type Props = {|
  item: {
    name: string,
    manufacturer: string,
    code: string,
  },
|};

const ItemActions = ({item}: Props) => (
  <Content>
    <Col width={100}>
      <StyledText>
        <ProductText>{item.name}</ProductText>
        <ManufacturerText>{item.manufacturer}</ManufacturerText>
        <ResCodeText>Asset ID: {item.code}</ResCodeText>
      </StyledText>
    </Col>
  </Content>
);

export default ItemActions;
