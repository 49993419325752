// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

export const AvatarWrap = styled.div`
  width: 4rem;
  height: 4rem;
  letter-spacing: 0.04rem;
  line-height: 4rem;
  text-align: center;
  border-radius: 100%;
  background-color: ${theme.color.lightGrey};
  color: ${theme.color.white};
  font-weight: ${theme.fontWeights.bold};
  text-transform: capitalize;
  margin: 0 auto;

  ${p =>
    p.big &&
    css`
      width: 8rem;
      height: 8rem;
      letter-spacing: 0.08rem;
      line-height: 8rem;
      font-size: 2.6rem;
      margin-bottom: 2rem;
    `}
`;
