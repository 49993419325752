// @flow
import {activities} from './constants';
import type {Category} from './types';

export const isSki = (a: Category) => a.name === activities.SKI.name;

export const isSurf = (a: Category) => a.name === activities.SURF.name;

export const isSnb = (a: Category) => a.name === activities.SNOWBOARD.name;

export const isSplit = (a: Category) => a.name === activities.SPLITBOARD.name;

export const isSup = (a: Category) => a.name === activities.SUP.name;

export const isRoadBike = (a: Category) => a.name === activities.ROAD_BIKE.name;

export const isMountainBike = (a: Category) => a.name === activities.MOUNTAIN_BIKE.name;

export const isTechnicalApparel = (a: Category) => a.name === activities.TECHNICAL_APPAREL.name;

export const isBike = (a: Category) => isRoadBike(a) || isMountainBike(a);

export const isSnow = (a: Category) => isSki(a) || isSnb(a) || isSplit(a);
