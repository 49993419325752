// @flow
import {updateUser} from 'data/user/actions';
import {selectLoggedIn, selectUser} from 'data/user/selectors';
import withConnect from 'hoc/withConnect';
import withIsNearClosingHours from 'hoc/withIsNearClosingHours';
import urls from 'pages/urls';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {type Component, type HOC, compose} from 'recompose';

const LoggedInRoute = ({affiliate, component, loggedIn, user, path, updateUser, ...rest}) => {
  if (!loggedIn) {
    return <Redirect to={urls.home} />;
  }

  return <Route {...rest} component={component} />;
};

const mapStateToProps = state => ({
  loggedIn: selectLoggedIn(state),
  user: selectUser(state),
});

const mapDispatchToProps = {
  updateUser,
};

type Outter = {|
  component: Component<mixed>,
  path: string,
  exact?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps, mapDispatchToProps),
  withIsNearClosingHours()
);

export default enhancer(LoggedInRoute);
