// @flow
import {createCsvFromData} from 'common/csv';
import * as React from 'react';
import {withHandlers} from 'recompose';

import {StyledButton as Button} from './styled';

const CSVExport = ({data, handleCSVExport}) =>
  data && data.length ? (
    <Button onClick={handleCSVExport}>Export to CSV</Button>
  ) : (
    <Button disabled>No data to export</Button>
  );

type Props = {
  data: Array<Object>,
};

export default withHandlers({
  handleCSVExport: (props: Props) => () =>
    props.data && props.data.length && window.location.assign(createCsvFromData(props.data)),
})(CSVExport);
