// @flow
import {mqMax} from 'common/mediaQuery';
import StyledLink from 'components/StyledLink';
import {PageTitle} from 'componentsStyled/Typography/Titles';
import theme from 'global/theme';
import styled from 'styled-components';

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18.5rem;
  height: 100%;
  margin: 1.2rem;
  background: #fff;

  ${mqMax.tabletWide} {
    display: block;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
`;

export const OverviewTitle = styled(PageTitle)`
  font-size: 2.6rem;
  margin-top: 2rem;

  ${mqMax.tabletWide} {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
`;
export const OverviewDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2.6rem;
  justify-content: space-around;
  margin-bottom: 1rem;

  ${mqMax.tabletWide} {
    padding: 0 2rem 0 2rem;
    margin: 0;
  }
`;
export const Number = styled.div`
  font-size: 3.8rem;
  color: ${p => (p.alert ? theme.color.negative : theme.color.primary)};

  ${mqMax.tabletWide} {
    font-size: 2.4rem;
  }
`;

export const Line = styled.div`
  width: 13rem;
  height: 0.1rem;
  border-top: 0.1rem solid ${theme.color.ultralight};
  margin-top: 2.5rem;

  ${mqMax.tabletWide} {
    position: relative;
    width: 0.1rem;
    height: 5rem;
    margin-bottom: 1.5rem;
    border-right: 0.1rem solid ${theme.color.ultralight};
    font-size: 3rem;
  }
`;

export const Link = styled(StyledLink)`
  text-align: center;
`;
