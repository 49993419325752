// @flow
import type {ColumnDef} from './types';

export const defaultSort = (column: ColumnDef<*>) => (x: any) => {
  const value = column.value(x);

  if (!value) {
    return '';
  }

  // $Dunno
  return typeof value === 'string' ? value.toLowerCase() : value;
};
