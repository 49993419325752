// @flow
import ModalBody from 'modals/_Body';
import ModalHeader from 'modals/_Header';
import React from 'react';
import {type HOC, withStateHandlers} from 'recompose';

import ForgotPasswordForm from './ForgotPasswordForm';
import Success from './Success';

const ForgotPasswordModal = ({emailSent, setEmailSent, close}) => (
  <ModalBody close={close}>
    <ModalHeader title="Forgot password" />
    {emailSent ? <Success close={close} /> : <ForgotPasswordForm setEmailSent={setEmailSent} />}
  </ModalBody>
);

type Outter = {|
  close: Function,
|};

const enhancer: HOC<*, Outter> = withStateHandlers(
  {emailSent: false},
  {setEmailSent: () => v => ({emailSent: v})}
);

export default enhancer(ForgotPasswordModal);
