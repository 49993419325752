// @flow
import type {FloatParam, ProductVariant, VariantParam} from 'data/product/types';
import {formatMmToFeetInch, formatMmToInchFractions} from 'data/units/length/formatters';

const conversionTable = {
  mm: 1,
  cm: 10,
  m: 100,
};

const convertUnit = (p: FloatParam) => {
  const from = conversionTable[p.unit];
  const to = conversionTable[p.displayedUnit];
  if (from && to) {
    const ratio = from / to;
    return `${(p.floatValue * ratio).toFixed(1)} ${p.displayedUnit}`;
  }
  if (p.displayedUnit === 'ft_in') {
    return formatMmToFeetInch(p.floatValue);
  }
  if (p.displayedUnit === 'in_frac') {
    return formatMmToInchFractions(p.floatValue);
  }
  return `${p.floatValue}${p.displayedUnit}`;
};

export const formatVariantParam =
  (name: string) =>
  (params: VariantParam[]): string => {
    // can be undefined due to some error on the backed, so this is best kept here
    const param = params.find(p => p.name === name);
    if (!param) {
      return '';
    }

    if (param.type === 'string_value') return param.stringValue;
    if (param.type === 'enum_value') return param.enumValues.join(', ');

    if (param.type === 'float_value') {
      return convertUnit(param) || '';
    }

    return '';
  };

export const formatBoardVariantDimensions = (p: ProductVariant): Object => ({
  length: formatVariantParam('length')(p.parameters),
  width: formatVariantParam('width')(p.parameters),
  thickness: formatVariantParam('thickness')(p.parameters),
  volume: formatVariantParam('volume')(p.parameters),
  waist_width: formatVariantParam('waist_width')(p.parameters),
});

export const formatSkiVariantDimensions = (p: ProductVariant): Object => ({
  length: formatVariantParam('length')(p.parameters),
  width: formatVariantParam('width')(p.parameters),
  thickness: formatVariantParam('thickness')(p.parameters),
  volume: formatVariantParam('volume')(p.parameters),
  waist_width: formatVariantParam('waist_width')(p.parameters),
  binding: formatVariantParam('binding')(p.parameters),
});

export const formatBikeVariantDimensions = (p: ProductVariant): Object => ({
  frame_size: formatVariantParam('bike_frame_size')(p.parameters),
  wheel_size: formatVariantParam('bike_wheel_size')(p.parameters),
  year: formatVariantParam('year')(p.parameters),
});

const NotAvailableIndicator = 'N/A';

/**
 * Returns the formatted string for apparel sizes
 * @param {string} apparel_numeric_size The numeric apparel size parameter
 * @param {string} apparel_descriptive_size The descriptive apparel size parameter
 */
const formatApparelSizes = (apparel_numeric_size, apparel_descriptive_size) => {
  const sizes = [];

  if (apparel_numeric_size !== NotAvailableIndicator) {
    sizes.push(apparel_numeric_size);
  }

  if (apparel_descriptive_size !== NotAvailableIndicator) {
    sizes.push(apparel_descriptive_size);
  }

  const sizePart = sizes.length > 0 ? sizes.join('-') : 'N/A';

  return sizePart;
};

/**
 * Returns an object containing the technical apparel product variant's dimensions
 * @param {ProductVariant} productVariant - The product variant we extract the parameters from
 */
export const formatTechnicalApparelVariantDimensions = (productVariant: ProductVariant): Object => {
  const apparel_numeric_size = formatVariantParam('apparel_numeric_size')(
    productVariant.parameters
  );
  const apparel_descriptive_size = formatVariantParam('apparel_descriptive_size')(
    productVariant.parameters
  );

  const size = formatApparelSizes(apparel_numeric_size, apparel_descriptive_size);

  return {
    gender: formatVariantParam('gender')(productVariant.parameters),
    size,
  };
};
