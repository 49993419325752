// @flow
import {activeBookingStatuses, preCheckoutBookingStatuses} from 'data/bookings/constants';
import type {Booking} from 'data/bookings/types';
import React from 'react';

import CancelButton from './CancelButton';
import CheckoutButton from './CheckoutButton';
import ReturnButton from './ReturnButton';
import {ActionWrap} from './styled';

type Props = {|
  booking: Booking,
|};

const BookingActions = ({booking}: Props) => {
  const {status} = booking;

  if (preCheckoutBookingStatuses.includes(status)) {
    return (
      <ActionWrap>
        <CheckoutButton booking={booking} />
        <CancelButton booking={booking} />
      </ActionWrap>
    );
  }

  if (activeBookingStatuses.includes(status)) {
    return (
      <ActionWrap>
        <ReturnButton booking={booking} />
      </ActionWrap>
    );
  }

  return null;
};

export default BookingActions;
