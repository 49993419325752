// @flow
import appReducer, {type AppState} from 'data/app/reducer';
import growlReducer, {type GrowlsState} from 'data/growls/reducer';
import modalsReducer, {type ModalState} from 'data/modals/reducer';
import notificationReducer, {type NotificationsState} from 'data/notifications/reducer';
import {type RouterState} from 'data/router/reducer';
import userReducer, {type UserState} from 'data/user/reducer';
import {combineReducers} from 'redux';

import persistReducer from './persistReducer';

export type StoreState = {|
  app: AppState,
  modals: ModalState,
  growls: GrowlsState,
  notifications: NotificationsState,
  user: UserState,
  // this is added via connected-react-router package
  router: RouterState,
|};

export default combineReducers({
  app: persistReducer(appReducer, {
    key: 'app',
    whitelist: ['activity', 'unit'],
  }),
  growls: growlReducer,
  user: persistReducer(userReducer, {
    key: 'user',
    whitelist: ['loggedIn', 'user', 'isMasquerading'],
  }),
  modals: modalsReducer,
  notifications: notificationReducer,
});
