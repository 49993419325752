// @flow
import type {User} from 'data/user/types';
import React from 'react';

import {MasqueradeBannerWrap} from './styled';

type Props = {|
  isMasquerading: boolean,
  user: ?User,
|};

const MasqueradeBanner = ({isMasquerading, user}: Props) => {
  if (isMasquerading) {
    return (
      <MasqueradeBannerWrap>
        <p>
          You are currently masquerading as <em>{user ? user.email : 'unknown'}</em>
        </p>
      </MasqueradeBannerWrap>
    );
  }
  return false;
};

export default MasqueradeBanner;
