// @flow
import * as React from 'react';

import {ContextMenuWrap} from './styled';

type Props = {|
  children: React.Node,
  isOpen: boolean,
  close: Function,
  left?: boolean,
  autocompleteStyle?: boolean,
|};

const ContextMenu = ({children, isOpen, close, left, autocompleteStyle}: Props) => (
  <ContextMenuWrap left={left} autocompleteStyle={autocompleteStyle} isOpen={isOpen}>
    {isOpen && children}
  </ContextMenuWrap>
);

export default ContextMenu;
