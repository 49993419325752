// @flow
import type {Mutation} from 'common/graphql/types';
import type {ID} from 'data/enums/types';
import {getProductItemDetailQuery} from 'data/product/graphql/queries';
import gql from 'graphql-tag';

export const reportDamageMutation: Mutation<{|id: number|}[]> = {
  gql: gql`
    mutation reportDamageMutation($reservationId: Int!, $reportedDamages: [DamageReport!]!) {
      damage {
        create(reservationId: $reservationId, reportedDamages: $reportedDamages) {
          id
        }
      }
    }
  `,
};

export const fixDamagesMutation: Mutation<{}, ID> = {
  gql: gql`
    mutation fixDamagesMutation($input: ListOfIds!) {
      damage {
        setDamagesToFixed(input: $input) {
          id
          fixed
        }
      }
    }
  `,
  refetch: [getProductItemDetailQuery],
  refetchVariables: [
    props => ({
      id: props.productId || props.productItem.id,
    }),
  ],
  refetchOptions: [{fetchPolicy: 'network-only'}],
};
