// @flow
import * as Sentry from '@sentry/react';
import config from 'global/config';
import withRouter from 'hoc/withRouter';
import React, {Component} from 'react';
import {type HOC} from 'recompose';

import Content from './Content';

class App<P: *> extends Component<P, {error: ?string}> {
  state = {
    error: null,
  };

  constructor() {
    super();
    if (config.sentryDsn) {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.environment,
      });
    }
  }

  componentDidUpdate(prev: P) {
    if (prev.location !== this.props.location) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({error});
    Sentry.captureException(error);
  }

  render() {
    return <Content error={this.state.error} />;
  }
}

// so apparently using connect will break updating this top level component, so withRouter has to be used
const enhancer: HOC<*, {||}> = withRouter;

export default enhancer(App);
