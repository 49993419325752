// @flow
import * as React from 'react';

import {TileListWrap} from './styled';

type Props = {|
  children: React.Node,
|};

const TileList = ({children}: Props) => <TileListWrap>{children}</TileListWrap>;

export default TileList;
