// @flow
import QuiverTile from 'components/QuiverTile';
import TileList from 'components/TileList';
import {TileItemLink} from 'components/TileList/styled';
import type {ProductItem} from 'data/product/types';
import urls from 'pages/urls';
import * as React from 'react';

type Props = {|
  products: ProductItem[],
|};

const QuiverList = ({products}: Props) => (
  <TileList>
    {products.map((k, i) => (
      <TileItemLink key={i} to={urls.productItem(k.id)}>
        <QuiverTile product={k} />
      </TileItemLink>
    ))}
  </TileList>
);

export default QuiverList;
