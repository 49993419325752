// @flow
import type {DateRangeValue} from 'data/units/date/types';

import type {AppConfig, Category} from './types';

export const setAppConfig = (appConfig: AppConfig) => ({
  type: 'APP/SET_CONFIG',
  payload: appConfig,
});

export type ChooseActivity = {|
  type: 'APP/CHOOSE_ACTIVITY',
  payload: Category,
|};
export const chooseActivity = (a: Category): ChooseActivity => ({
  type: 'APP/CHOOSE_ACTIVITY',
  payload: a,
});

export type ChooseUnit = {|
  type: 'APP/CHOOSE_UNIT',
  payload: string,
|};
export const chooseUnit = (u: string): ChooseUnit => {
  return {
    type: 'APP/CHOOSE_UNIT',
    payload: u,
  };
};

export type FilterDates = {|
  type: 'APP/FILTER_DATES',
  payload: ?DateRangeValue,
|};
export const filterDates = (d: ?DateRangeValue): FilterDates => ({
  type: 'APP/FILTER_DATES',
  payload: d,
});
