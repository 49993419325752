// @flow
import Button from 'components/Button';
import {openModal} from 'data/modals/actions';
import {setProductItemInStock} from 'data/product/graphql/mutations';
import type {ProductItem} from 'data/product/types';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import withSubmit from 'hoc/withSubmit';
import MarkInStockModal from 'modals/MarkInStock';
import urls from 'pages/urls';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

const SetInStock = ({onClick}) => (
  <Button onClick={onClick} large>
    MARK IN STOCK
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  productItem: ProductItem,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(setProductItemInStock),
  withSubmit({
    redirect: props => urls.productAction(props.productItem.id),
    errorText: 'Failed to mark in stock, please try again.',
  }),
  withHandlers({
    onClick:
      ({productItem, submit, openModal}) =>
      () => {
        const {id} = productItem;

        return openModal(MarkInStockModal, {
          productItemId: id,
          onConfirm: () => submit({id}),
        });
      },
  })
);
export default enhancer(SetInStock);
