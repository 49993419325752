// @flow
import {mqMax} from 'common/mediaQuery';
import styled from 'styled-components';

export const DatePickerController = styled.button`
  display: flex;
  padding: 1rem;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  outline: none;
  transition: 0.2s all;
  margin-bottom: 1rem;

  &:hover {
    opacity: 1;
  }

  ${mqMax.tablet} {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;

export const DatePickerControllersWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const DatePickerTitle = styled.div`
  margin: 0 5rem;

  ${mqMax.tabletWide} {
    margin: 1rem 5rem;
  }

  ${mqMax.phoneWide} {
    margin: 1rem 2rem;
  }
`;
