// @flow
import {bookingStatuses} from 'data/bookings/constants';
import type {Booking} from 'data/bookings/types';
import type {DateString} from 'data/units/date/types';
import theme from 'global/theme';
import moment from 'moment';

export const relative = (date: DateString) =>
  moment(String(date)).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: 'DD MMM YYYY',
    sameElse: 'DD MMM YYYY',
  });

export const getDateToDisplay = (booking: Booking) => {
  const {status, start, end, dispatchDate, expectedReturnDate} = booking;

  switch (status) {
    //Pre-checkout (Upcoming Tab)
    case bookingStatuses.confirmed:
    case bookingStatuses.pre_processing:
      return dispatchDate || start; //Use delivery dispatch date if delivery fulfilment else use booking start date

    //Post-checkout (Returns Tab)
    //For both fulfilment types show the booking end date while the booking is 'active'
    case bookingStatuses.outbound:
    case bookingStatuses.checked_out:
    case bookingStatuses.overdue:
      return end;
    //If the user has sent the item (i.e. inbound or delayed) then show expected receive date
    case bookingStatuses.inbound:
    case bookingStatuses.delayed:
      return expectedReturnDate;

    //Should not end up in a state where bookings in other statuses are displayed
    default:
      return null;
  }
};

export const getStatusColour = (date: ?DateString) => {
  if (!date) {
    return null;
  }

  const dateString = String(date);

  //Future dates => grey
  if (moment(dateString).isAfter(moment(), 'day')) {
    return `${theme.color.darkGrey}`;
  }

  //Past dates => red
  if (moment(dateString).isBefore(moment(), 'day')) {
    return `${theme.color.negative}`;
  }

  //Else is today => green
  return `${theme.color.positive}`;
};
