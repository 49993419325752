// @flow
import Button from 'components/Button';
import StyledLink from 'components/StyledLink';
import {Space} from 'componentsStyled/Layout/Spacers';
import {Note} from 'componentsStyled/Typography/Other';
import {chooseUnit} from 'data/app/actions';
import {login} from 'data/user/actions';
import {loginMutation} from 'data/user/graphql/mutations';
import Alert from 'forms/Alert';
import TextInput from 'forms/Input/Text';
import {emailRequired, stringRequired} from 'forms/validators/string';
import withForm from 'forms/withForm';
import withConnect from 'hoc/withConnect';
import withMutation from 'hoc/withMutation';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

const LoginForm = ({error = '', isSubmitting, openForgotPassword}) => (
  <React.Fragment>
    <Alert>{error}</Alert>
    <TextInput name="email" type="email" label="Email" placeholder="john.doe@example.com" />
    <TextInput name="password" label="Password" type="password" placeholder="••••••••" />
    <Note>
      <StyledLink onClick={openForgotPassword}>Forgot password?</StyledLink>
    </Note>
    <Space />
    <Button fullwidth loading={isSubmitting} data-cy={'login-button'}>
      Sign in
    </Button>
  </React.Fragment>
);

const mapDispatchToProps = {
  login,
  chooseUnit,
};

const schema = {
  email: emailRequired,
  password: stringRequired,
};

type Outter = {|
  openForgotPassword: Function,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(() => ({}), mapDispatchToProps),
  withMutation(loginMutation),
  withForm({
    schema,
    onSubmit: props => props.submitMutation,
    onSuccess: props => result => {
      // Set unit in App State after querying for user
      const unit = result.profiles[0] && result.profiles[0].units;
      if (unit) {
        props.chooseUnit(unit);
      }
      console.log(result, unit);
      var x = props.login(result);
      console.log(x);

      return x;
    },
  })
);

export default enhancer(LoginForm);
