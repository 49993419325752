// @flow
import ContextMenu from 'components/ContextMenu';
import OverlayWrapper from 'components/OverlayWrapper';
import withOpen from 'hoc/withOpen';
import * as React from 'react';
import {type Component, type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import FilterInput from '../FilterInput';
import DesktopFilterControls from './DesktopFilterControls';
import {DesktopFilterContent} from './styled';

const DesktopFilter = ({
  isOpen,
  open,
  handleClose,
  value,
  formatter,
  handleChange,
  handleClear,
  localValue,
  handleApply,
  component: Component,
  title,
  noXPadding,
  minWidth,
  maxHeight,
  withControls = false,
}) => (
  <OverlayWrapper isOpen={isOpen} close={handleClose}>
    <FilterInput placeholder={title} value={value} formatter={formatter} onClick={open} />
    <ContextMenu isOpen={isOpen} close={handleClose}>
      <DesktopFilterContent noXPadding={noXPadding}>
        <Component value={withControls ? localValue : value} onChange={handleChange} />
      </DesktopFilterContent>

      {withControls && (
        <DesktopFilterControls
          hasValue={!!localValue || !!value}
          clear={handleClear}
          apply={handleApply}
        />
      )}
    </ContextMenu>
  </OverlayWrapper>
);

type Outter = {|
  onChange: Function,
  formatter?: Function,
  component: Component<{|
    value: any,
    onChange: Function,
  |}>,
  value: any,
  title: string,
  left?: boolean,
  noXPadding?: boolean,
  withControls?: boolean,
  closeOnChange?: boolean,
|};

const enhancer: HOC<*, Outter> = compose(
  withOpen,
  withStateHandlers(
    props => ({
      localValue: props.value,
    }),
    {
      setLocalValue: () => v => ({localValue: v}),
    }
  ),
  withHandlers({
    handleChange: props => v => {
      if (props.closeOnChange) {
        props.close();
      }

      if (props.withControls) {
        return props.setLocalValue(v);
      }

      return props.onChange(v);
    },
    handleClear: props => () => {
      props.onChange(undefined);
      props.setLocalValue(undefined);
    },
    handleApply: props => () => {
      props.onChange(props.localValue);
      props.close();
    },
    handleClose: props => v => {
      props.close();
      props.setLocalValue(props.value);
    },
  })
);

export default enhancer(DesktopFilter);
