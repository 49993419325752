// @flow
import SubPage from 'pages/_Page/SubPage';
import React from 'react';

import ProductDetail from './ProductDetail';

const ProductAction = (props: any) => (
  <SubPage title="Status Changed">
    <ProductDetail {...props} />
  </SubPage>
);
export default ProductAction;
