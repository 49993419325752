// @flow
import gql from 'graphql-tag';

export const damageTypeFragment = gql`
  fragment damageTypeFragment on DamageType {
    id
    type
    description
    damagePrices {
      id
      price
      currency
    }
    isReportable
    isRepairable
    isDescriptionRequired
    name
  }
`;
