// @flow
import Button from 'components/Button';
import {Container} from 'componentsStyled/Layout/Containers';
import {Flex} from 'componentsStyled/Layout/Flex';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import type {ProductItem} from 'data/product/types';
import withRouter from 'hoc/withRouter';
import urls from 'pages/urls';
import React from 'react';
import {compose, withHandlers} from 'recompose';

import Detail from './Detail';
import {ActionPreviewWrap, ButtonWrap, Half, StyledImage} from './styled';

type Props = {
  data: ProductItem,
  handleDoneClick: Function,
};

const ProductItemActionPreview = ({data, handleDoneClick}: Props) => {
  const indexImage = selectIndexImage(data.productVariant.product.images);
  const imageSize = minHeightImg(indexImage, 250);

  return (
    <ActionPreviewWrap>
      <Container>
        <Flex>
          <Half white>
            <StyledImage src={imageSize.url} />
          </Half>
          <Half white>
            <Detail productItem={data} />
          </Half>
        </Flex>
        <ButtonWrap>
          <Button onClick={handleDoneClick}>DONE</Button>
        </ButtonWrap>
      </Container>
    </ActionPreviewWrap>
  );
};

const enhancer = compose(
  withRouter,
  withHandlers({
    handleDoneClick: props => () => {
      props.history.push(urls.inventory);
    },
  })
);

export default enhancer(ProductItemActionPreview);
