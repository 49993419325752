// @flow strict-local
import ProductDimension from 'components/ProductDimension';
import VariantVariablesDisplay from 'components/VariantVariablesDisplay';
import type {ProductVariant} from 'data/product/types';
import React from 'react';

type Props = {
  productVariant: ProductVariant,
  tile?: boolean,
  compact?: boolean,
  center?: boolean,
};

/**
 * takes in a product variant and formats its name accordingly - depending on whether or
 * not it contains variant variables
 */
const ProductVariantNameFormat = ({productVariant, tile, compact, center}: Props) =>
  // Product preview does not have/show variant name for upcoming reservations
  productVariant.product.variantVariables && productVariant.name ? (
    <VariantVariablesDisplay
      variantVariablesLabels={productVariant.product.variantVariables}
      variantVariablesOptions={productVariant.name}
      tile={tile}
      compact={compact}
      center={center}
    />
  ) : (
    // ProductDimension returns null if no productVariant.name
    <ProductDimension
      productVariant={productVariant}
      tile={tile}
      compact={compact}
      center={center}
    />
  );

export default ProductVariantNameFormat;
