// @flow
import theme from 'global/theme';
import styled from 'styled-components';

export const AlertWrap = styled.div`
  background: ${theme.color.negative};
  color: ${theme.color.white};
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
`;
