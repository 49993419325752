// @flow
import {mqMax} from 'common/mediaQuery';
import theme from 'global/theme';
import styled from 'styled-components';

export const Controller = styled.button`
  display: flex;
  padding: 1rem;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  outline: none;
  transition: 0.2s all;
  &:hover {
    opacity: 1;
  }

  ${mqMax.tablet} {
    font-size: 1.2rem;
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const ActiveMonth = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem 0;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: ${theme.fontWeights.bold};
  text-transform: capitalize;
  text-align: center;

  ${mqMax.tablet} {
    font-size: 1.6rem;
  }
`;

export const Divisor = styled.div`
  display: flex;
  background: ${theme.color.primary};
  align-self: center;
  width: 10rem;
  height: 0.5rem;
`;
