// @flow strict-local
import {DimensionText} from 'components/Tile/Shared/Actions';
import {Text} from 'componentsStyled/Typography/Texts';
import React from 'react';

import {VariantVariableLabel, VariantVariableOption, VariantVariableWrap} from './styled';

type Props = {
  variantVariablesLabels: string,
  variantVariablesOptions: string,
  tile?: boolean,
  compact?: boolean,
  center?: boolean,
};

// Style text in the same pattern as ProductDimension (the no variant variable case)
const style = (text, compact, tile, center) => {
  if (tile) {
    return <DimensionText>{text}</DimensionText>;
  }

  if (compact) {
    return (
      <Text center={center} book small>
        {text}
      </Text>
    );
  }

  return (
    <Text center={center} bigOnDesktop>
      {text}
    </Text>
  );
};

const VariantVariablesDisplay = ({
  variantVariablesLabels,
  variantVariablesOptions,
  compact,
  tile,
  center = false,
}: Props) => {
  const variantVariablesLabelList = variantVariablesLabels.split(' | ');
  const variantVariableOptionsList = variantVariablesOptions.split(' | ');
  const labelLength = variantVariablesLabelList.length;
  const variantLength = variantVariableOptionsList.length;
  const maxLengthArray = Array(Math.max(labelLength, variantLength)).fill('');

  const blank = style('-', compact, tile, center);

  return (
    <React.Fragment>
      {maxLengthArray.map((_, index) => {
        return (
          <VariantVariableWrap key={index}>
            <VariantVariableLabel>
              {index < labelLength
                ? style(`${variantVariablesLabelList[index]}:`, compact, tile, center)
                : blank}
            </VariantVariableLabel>
            <VariantVariableOption>
              {index < variantLength
                ? style(variantVariableOptionsList[index], compact, tile, center)
                : blank}
            </VariantVariableOption>
          </VariantVariableWrap>
        );
      })}
    </React.Fragment>
  );
};

export default VariantVariablesDisplay;
