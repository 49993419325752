// @flow
import LogoLink from 'components/LogoLink';
import * as React from 'react';

import {LogoWrap} from './styled';

const DesktopLoggedOutNav = () => (
  <LogoWrap>
    <LogoLink type="default" />
  </LogoWrap>
);

export default DesktopLoggedOutNav;
