// @flow
import CrossIcon from 'assets/icons/CrossIcon';
import React from 'react';
import {type HOC, withHandlers} from 'recompose';

import SearchIcon from './SearchIcon';
import {InputStyled, StyledForm} from './styled';

const Input = ({value, onChange, handleSubmit, handleClear}) => (
  <StyledForm onSubmit={handleSubmit}>
    <InputStyled
      type="search"
      placeholder="Search"
      value={value || ''}
      onChange={e => onChange(e.target.value)}
    />
    {value ? <CrossIcon onClick={handleClear} /> : <SearchIcon />}
  </StyledForm>
);

type Outter = {|
  value: ?string,
  onChange: (?string) => mixed,
|};

const enhancer: HOC<*, Outter> = withHandlers({
  handleClear: props => () => {
    props.onChange('');
  },
  handleSubmit: props => e => {
    e.preventDefault();
  },
});

export default enhancer(Input);
