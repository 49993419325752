// @flow
import {breakpoints} from 'common/mediaQuery';
import {values} from 'ramda';

const primary = '#14a990';
const primaryDark = '2C2C65';
const secondary = '#14A88F';
const secondaryDark = '0C6455';
const tone = '#f8fafc';
const black = '#29293D';

const lighten = (hex: string, alpha: number) =>
  `rgba(${parseInt(hex.substring(1, 3), 16)},${parseInt(hex.substring(3, 5), 16)},${parseInt(
    hex.substring(5, 7),
    16
  )},${alpha})`;

const theme = {
  lighten,
  color: {
    // Main theme colors
    primary,
    primaryDark,
    secondary,
    secondaryDark,
    tone,
    black,
    dark: '#1A202C',

    // Various Midtones
    white: '#FFFFFF',
    ultralight: '#F6F8F9',
    lightGrey: '#D9DEE6',
    midGrey: '#ADB5C4',
    darkGrey: '#71798a',
    darkerGrey: '#4A5568',

    error: '#E53E3E',

    // Colors intended to have connotations attatched
    positive: secondary,
    positiveDark: secondaryDark,
    neutral: '#F9BA4E',
    neutralDark: '#F6A313',
    negative: '#ED6A5E',
    negativeDark: '#E94435',
  },
  zIndices: {
    minimal: 1,
    overlayMinimal: 10,
    overlayOverlay: 98,
    overlayContent: 99,
    overlay: 100,
    header: 100,
    contextMenu: 500,
    modal: 1000,
    notification: 10000,
  },
  breakpoints: values(breakpoints),
  fontWeights: {
    normal: 400,
    book: 500,
    medium: 600,
    bold: 700,
    ultra: 1000,
  },
  fontSizes: {
    title: 'calc(1.7rem + 6 * ((100vw - 320px) / 680))',
  },
};

export default theme;
