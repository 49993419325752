// @flow
import type {Query} from 'common/graphql/types';
import gql from 'graphql-tag';

import type {Accessory} from '../types';

export const listAccessories: Query<Accessory[]> = {
  gql: gql`
    query listAccessories {
      accessory {
        list(filter: {limit: 999}) {
          data {
            name
            url
          }
        }
      }
    }
  `,
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  selector: ['accessory', 'list', 'data'],
};
