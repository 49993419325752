// @flow
import theme from 'global/theme';
import styled from 'styled-components';

export const StyledForm = styled.form`
  flex-grow: 1;
  position: relative;

  svg {
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    fill: ${theme.color.midGrey};
  }
`;

export const InputStyled = styled.input`
  border-radius: 0.4rem;
  border: solid 0.2rem ${theme.color.lightGrey};
  height: 4.4rem;
  line-height: 4.4rem;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  width: 100%;
  color: ${theme.color.black};
  -webkit-appearance: none;

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${theme.lighten(theme.color.darkGrey, 0.5)};
  }

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }
`;
