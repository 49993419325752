// @flow
import {withRouter as reactRouterHOC} from 'react-router';
import type {Component} from 'recompose';

type Added = {|
  history: {
    push: string => void,
    location: {
      pathname: string,
      state: any,
    },
  },
  location: {
    pathname: string,
    search: string,
  },
|};

function withRouter<T>(C: Component<{...$Exact<T>, ...$Exact<Added>}>): Component<T> {
  return reactRouterHOC(C);
}

export default withRouter;
