// @flow
import Select from 'components/Select';
import {Text} from 'componentsStyled/Typography/Texts';
import type {EnumValue} from 'data/enums/types';
import {listAvailableProductItemsByReservationIdQuery} from 'data/product/graphql/queries';
import {reservationStatuses} from 'data/reservations/constants';
import {updateProductItemMutation} from 'data/reservations/graphql/mutations';
import type {Reservation} from 'data/reservations/types';
import withMutation from 'hoc/withMutation';
import withQuery from 'hoc/withQuery';
import React from 'react';
import type {HOC} from 'recompose';
import {branch, compose, withHandlers} from 'recompose';

const SkuSelect = ({
  reservation,
  data,
  handleChange,
  'data-cy': dataCy,
}: {
  reservation: Reservation,
  data: EnumValue[],
  handleChange: any,
  'data-cy'?: string,
}) => {
  switch (reservation.status) {
    case reservationStatuses.pre_processing:
    case reservationStatuses.confirmed:
      return (
        <Select
          data-cy={dataCy}
          onChange={handleChange}
          placeholder="Available Assets"
          options={data || [{id: reservation.productItem.id, name: reservation.productItem.code}]}
          value={reservation.productItem.id}
        />
      );
    default:
      return <Text black>{reservation.productItem.code}</Text>;
  }
};

type Outter = {|
  reservation: Reservation,
  'data-cy'?: string,
|};

const enhancer: HOC<*, Outter> = compose(
  withQuery(listAvailableProductItemsByReservationIdQuery, {
    variables: ({reservation: {id}}) => ({
      id,
    }),
  }),
  withMutation(updateProductItemMutation),
  withHandlers({
    handleChange: props => value => {
      return props.submitMutation({
        id: props.reservation.id,
        productItemId: value,
      });
    },
  })
);

export default branch(
  props =>
    props.reservation.status === reservationStatuses.confirmed ||
    props.reservation.status === reservationStatuses.pre_processing,
  enhancer
)(SkuSelect);
