// @flow
import LogoLink from 'components/LogoLink';
import withOpen from 'hoc/withOpen';
import * as React from 'react';
import {type HOC} from 'recompose';

import AvatarMenu from './AvatarMenu';
import {AffiliateName, LogoWrap, RightWrap} from './styled';

type Outter = {
  name: string,
};

const DesktopLoggedInNav = ({name}) => (
  <React.Fragment>
    <LogoWrap>
      <LogoLink type="default" />
    </LogoWrap>
    <AffiliateName>{name}</AffiliateName>
    <RightWrap>
      <AvatarMenu />
    </RightWrap>
  </React.Fragment>
);

const enhancer: HOC<*, Outter> = withOpen;

export default enhancer(DesktopLoggedInNav);
