// @flow
import {isBeforeToday, isToday} from 'data/calendar/helpers';
import {dateStringFromMoment, momentFromDateString} from 'data/units/date/helpers';
import type {DateRangeValue, DateString} from 'data/units/date/types';
import moment from 'moment';
import {path} from 'ramda';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import type {DateSelectionType} from '../index';
import {isActive} from './helpers';
import {
  DatePickerDay,
  DatePickerDayWrap,
  DatePickerMonthWrap,
  DatePickerWeek,
  DatePickerWeekday,
  DatePickerWeekdaysWrap,
} from './styled';

type Props = {|
  weeks: Object,
  selectedDates: DateRangeValue,
  filterDates(range?: DateRangeValue): void,
  selectionType?: DateSelectionType,
  shouldDisableDate?: (date: moment) => boolean,
  greyOutPastDates?: boolean,
  datesToOutline?: (DateString | moment)[],
|};

const DatePickerMonth = ({
  weeks,
  selectedDates,
  handleChange,
  shouldDisableDate,
  greyOutPastDates,
  datesToOutline,
}) => (
  <DatePickerMonthWrap>
    <DatePickerWeekdaysWrap>
      {moment.weekdays().map((weekday, weekdayIndex) => (
        <DatePickerWeekday key={weekdayIndex}>{weekday.substr(0, 3)}</DatePickerWeekday>
      ))}
    </DatePickerWeekdaysWrap>
    {weeks.map((week, weekIndex) => (
      <DatePickerWeek key={weekIndex}>
        {week.map((day, dayIndex) => {
          if (!day) return <DatePickerDayWrap key={dayIndex} isEmpty />;
          const isDisabled = shouldDisableDate && shouldDisableDate(day);
          const shouldOutline =
            datesToOutline && datesToOutline.some(date => day.isSame(date, 'day'));

          return (
            <DatePickerDayWrap
              key={dayIndex}
              isToday={isToday(day)}
              isBeforeToday={isBeforeToday(day)}
              greyOutPastDates={greyOutPastDates}
              onClick={() => !isDisabled && handleChange(day)}
              isDisabled={isDisabled}
            >
              <DatePickerDay
                selected={isActive(day, selectedDates)}
                outlineDate={shouldOutline}
                key={dayIndex}
              >
                {day.date()}
              </DatePickerDay>
            </DatePickerDayWrap>
          );
        })}
      </DatePickerWeek>
    ))}
  </DatePickerMonthWrap>
);

const enhancer: HOC<*, Props> = compose(
  withHandlers({
    handleChange: props => (value: moment$Moment) => {
      const transformedValue = dateStringFromMoment(value);
      const startDate = path(['startDate'], props.selectedDates);
      const endDate = path(['endDate'], props.selectedDates);

      if (props.selectionType === 'start-and-end') {
        if (startDate) {
          const isBefore = momentFromDateString(transformedValue).isBefore(
            momentFromDateString(startDate)
          );

          if (isBefore) {
            return props.filterDates({startDate: transformedValue, endDate: undefined});
          }
        }

        if (!startDate || (startDate && endDate)) {
          props.filterDates({startDate: transformedValue, endDate: undefined});
        } else if (startDate && !endDate) {
          props.filterDates({startDate, endDate: transformedValue});
        }
      } else {
        props.filterDates({startDate: transformedValue, endDate: transformedValue});
      }
    },
  })
);

export default enhancer(DatePickerMonth);
