// @flow
import WrenchIcon from 'assets/icons/WrenchIcon';
import {Text} from 'componentsStyled/Typography/Texts';
import {fixDamagesMutation} from 'data/damages/graphql/mutations';
import type {ProductDamage} from 'data/damages/types';
import {type ID} from 'data/enums/types';
import withMutation from 'hoc/withMutation';
import * as React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import DamageStatus from './DamageStatus';
import {Body, DamageTileWrap, IconWrap} from './styled';

type Outter = {|
  damage: ProductDamage,
  productId: ID,
|};

const DamageTile = ({damage, handleResolveDamage}) => {
  return (
    <DamageTileWrap stack>
      <DamageStatus damage={damage} />
      <Body>
        <Text>
          {damage.description !== null ? damage.description : damage.damageType.description}
        </Text>
      </Body>
      {!damage.fixed && (
        <IconWrap onClick={handleResolveDamage}>
          <WrenchIcon />
        </IconWrap>
      )}
    </DamageTileWrap>
  );
};

const enhancer: HOC<*, Outter> = compose(
  withMutation(fixDamagesMutation),
  withHandlers({
    handleResolveDamage: props => () =>
      props.submitMutation({
        input: {ids: [props.damage.id]},
      }),
  })
);

export default enhancer(DamageTile);
