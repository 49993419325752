// @flow
import Loader from 'components/Loader';
import React from 'react';
import {type HOC} from 'recompose';

function withLoader<T>(predicate: T => boolean): HOC<T, T> {
  return Component => props => {
    if (predicate(props)) {
      return <Loader />;
    }

    return <Component {...props} />;
  };
}

export default withLoader;
