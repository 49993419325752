// @flow
import {mqMax} from 'common/mediaQuery';
import {WidthContainer} from 'componentsStyled/Layout/Containers';
import styled from 'styled-components';

export const MainFilterWrap = styled(WidthContainer)`
  display: flex;
  align-items: center;
  width: 50%;
  padding: 1.8rem 1.6rem 1.5rem 0;
  justify-content: space-between;

  ${mqMax.tabletWide} {
    width: 100%;
    justify-content: space-around;
    padding: 1.25rem;
  }
`;

export const FiltersWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
