// @flow
import Button from 'components/Button';
import {openModal} from 'data/modals/actions';
import {dateStringFromMoment} from 'data/units/date/helpers';
import withAffiliate from 'hoc/withAffiliate';
import withConnect from 'hoc/withConnect';
import BookingReturn from 'modals/BookingReturn';
import moment from 'moment';
import React from 'react';
import {type HOC, compose, withHandlers} from 'recompose';

import type {Booking} from '../../../data/bookings/types';

const ReturnButton = ({user, handleReturn}) => (
  <Button data-cy="return-page-return-button" onClick={handleReturn} large>
    RETURN
  </Button>
);

const mapDispatchToProps = {
  openModal,
};

type Outter = {|
  onClick?: Function,
  booking: Booking,
|};

const enhancer: HOC<*, Outter> = compose(
  withAffiliate(),
  withConnect(() => ({}), mapDispatchToProps),
  withHandlers({
    handleReturn:
      ({booking, affiliate, openModal}) =>
      () => {
        //Only treat the booking as a delivery if it is of the delivery fulfilment type,
        //there is some (enabled) inbound buffer, and it has been marked as inbound.
        const handleAsDelivery =
          booking.fulfillmentType === 'DELIVERY' &&
          affiliate.enableDeliveryBuffers &&
          affiliate.inboundBufferDays > 0 &&
          !!booking.markedInboundAt;

        return openModal(BookingReturn, {
          booking,
          handleAsDelivery,
          formData: {
            returnDate:
              handleAsDelivery && booking.markedInboundAt
                ? dateStringFromMoment(moment(String(booking.markedInboundAt)))
                : dateStringFromMoment(moment()),
          },
        });
      },
  })
);
export default enhancer(ReturnButton);
