// @flow
const prefix = 'REACT_APP_';

const keys = {
  ENVIRONMENT: 'ENVIRONMENT',
  API_URI: 'API_URI',
  SENTRY_SHOP_APP_DSN: 'SENTRY_SHOP_APP_DSN',
  STRIPE_API_PUBLIC_KEY: 'STRIPE_API_PUBLIC_KEY',
  TENANT_TITLE: 'TENANT_TITLE',
};

const getConfig = (key: string, defaultValue: string, hasPrefix = true): string => {
  if (window.APP_CONFIG && window.APP_CONFIG[`${prefix}${key}`]) {
    return window.APP_CONFIG[`${prefix}${key}`];
  } else if (process.env[`${prefix}${key}`] && hasPrefix) {
    return process.env[`${prefix}${key}`] || '';
  } else if (process.env[key] && !hasPrefix) {
    return process.env[key];
  } else {
    return defaultValue;
  }
};

const environment = getConfig(keys.ENVIRONMENT, 'development');
const apiUri = getConfig(keys.API_URI, window.location.origin);
const sentryDsn = getConfig(keys.SENTRY_SHOP_APP_DSN, '');
const stripeApiPublicKey = getConfig(keys.STRIPE_API_PUBLIC_KEY, '');
const tenantTitle = getConfig(keys.TENANT_TITLE, '');

const config = {
  environment,
  api: `${apiUri}/api/graphql`,
  sentryDsn,
  stripeKey: stripeApiPublicKey,
  tenantTitle,
};

window.CONFIG = config;

export default config;
