// @flow
import theme from 'global/theme';
import styled, {css} from 'styled-components';

const wrapperCSS = css`
  display: block;
  width: 100%;
  margin-bottom: 2.4rem;
`;

export const LabelWrap = styled.label(wrapperCSS);

export const DivWrap = styled.div(wrapperCSS);

export const Error = styled.div`
  color: ${theme.color.negative};
  font-size: 1.2rem;
  position: ${p => (p.errorPosition ? p.errorPosition : `relative`)};
  margin-top: ${p => (p.errorPosition ? `0.35rem` : 0)};
`;
