// @flow
import theme from 'global/theme';
import React from 'react';

const IconCheck = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path
      fill={theme.color.white}
      fillRule="evenodd"
      d="M9.29289322 11.1213203L7.4644661 9.29289322 6.05025252 10.7071068l2.5355339 2.5355339.7071068.7071068 5.65685427-5.65685428-1.4142136-1.41421356-4.24264068 4.24264064zM10 20C4.4771525 20 0 15.5228475 0 10S4.4771525 0 10 0s10 4.4771525 10 10-4.4771525 10-10 10z"
    />
  </svg>
);

export default IconCheck;
