// @flow
import {SubTitle, Title} from 'componentsStyled/Typography/Titles';
import styled, {css} from 'styled-components';

export const IntervalWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${p =>
    p.small &&
    css`
      position: absolute;
      bottom: -2rem;
    `}
`;

export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Month = styled(SubTitle)`
  margin-top: -1.3rem;
  ${p =>
    p.small &&
    css`
      font-size: 1.3rem;
    `}
`;

export const Day = styled(Title)`
  ${p =>
    p.small &&
    css`
      font-size: 2.6rem;
    `}
`;
