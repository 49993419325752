// @flow
import theme from 'global/theme';
import {css} from 'styled-components';

const getTheme = (p: Object) => {
  if (p.secondary)
    return css`
      color: ${theme.color.primary};
      border-color: ${theme.color.primary};

      &:active,
      &:hover {
        ${p =>
          !p.disabled &&
          css`
            border-color: ${theme.color.primaryDark};
            color: ${theme.color.primaryDark};
          `}
      }
    `;

  return css`
    background-color: ${theme.color.primary};
    border-color: ${theme.color.primary};
    color: ${theme.color.white};

    &:active,
    &:hover {
      ${p =>
        !p.disabled &&
        css`
          background-color: ${theme.color.primaryDark};
          border-color: ${theme.color.primaryDark};
        `}
    }
    &:focus {
      color: ${theme.color.white};
    }
  `;
};

export default getTheme;
