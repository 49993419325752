// @flow
import theme from 'global/theme';
import React from 'react';

const ArrowIconPrimary = () => (
  <svg width="10" height="16">
    <path
      d="M1 1l7 7-7 7"
      stroke={theme.color.negative}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowIconPrimary;
