// @flow
import theme from 'global/theme';
import styled, {keyframes} from 'styled-components';

import {getColor} from './helpers';

const appear = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;
const disappear = keyframes`
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 100%);
  }
`;

export const NotificationTileWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  font-size: 1.6rem;
  font-weight: ${theme.fontWeights.book};
  line-height: 2.5rem;
  padding: 1.5rem 3rem;
  width: 63rem;
  max-width: 100%;
  background: ${p => getColor(p.type)};
  color: ${theme.color.white};
  transform: translate(-50%, 0);
  z-index: ${theme.zIndices.notification};
  animation: ${p => (p.closing ? disappear : appear)} 0.25s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 1rem;
  }
`;
