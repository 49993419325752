// @flow
import {transformResponse} from 'common/graphql/helpers';
import {getRouterVariables} from 'common/graphql/routing';
import type {Query} from 'common/graphql/types';
import withEmpty from 'hoc/withEmpty';
import withLoader from 'hoc/withLoader';
import NotFoundPage from 'pages/NotFound';
import {always, pathOr} from 'ramda';
import {graphql} from 'react-apollo';
import {type HOC, branch, compose, renderComponent} from 'recompose';

import {getDebounce} from './debounce';
import {getPageInfo, getPaginationVariables, mergeData} from './pagination';

const notFound = data => {
  const errors = pathOr([], ['error', 'graphQLErrors'], data);

  return errors.some(x => pathOr('', ['data', 'code'], x).endsWith('NOT_FOUND'));
};

type Config<Outter, Variables> = {
  noLoader?: boolean,
  noEmpty?: boolean,
  variables?: Outter => Variables,
  config?: {
    skip: Outter => boolean,
  },
  callOptions?: {
    fetchPolicy?: string,
  },
};

type Added<Data> = {
  data: Data,
  loading: boolean,
  loadingMore: boolean,
  count: number,
  loadMore: void => void,
  notFound: boolean,
  refetch: void => void,
};

function withQuery<Outter, Data, Variables>(
  query: Query<Data, Variables>,
  configuration: Config<Outter, Variables> = {}
  // $ExpectError
): HOC<{...$Exact<Added<Data>>, ...$Exact<Outter>}, Outter> {
  const {gql, selector, transform, pagination, options = always({}), debounceKey} = query;
  const {
    noLoader = false,
    noEmpty = false,
    variables = always({}),
    config = {},
    callOptions = {},
  } = configuration;

  const customGraphqlHoc = graphql(gql, {
    props: props => ({
      data: transformResponse(selector, transform)(props),
      refetch: () => props.data.refetch(),
      // loading | setVariables | refetch
      loading:
        props.data.networkStatus === 1 ||
        props.data.networkStatus === 2 ||
        props.data.networkStatus === 4,
      loadingMore: props.data.networkStatus === 3,
      notFound: notFound(props.data),
      count: pagination && getPageInfo(pagination, props.data).totalCount,
      loadMore: () =>
        pagination &&
        !props.data.loading &&
        getPageInfo(pagination, props.data).hasNextPage &&
        props.data.fetchMore({
          variables: getPaginationVariables(pagination, props.data),
          ...getDebounce(debounceKey),
          updateQuery: (prev, {fetchMoreResult}) => mergeData(prev, fetchMoreResult, pagination),
        }),
    }),
    options: props => ({
      notifyOnNetworkStatusChange: true, // to update loading when fetching more
      variables: {
        ...getRouterVariables(props),
        ...getPaginationVariables(pagination),
        ...variables(props),
      },
      ...getDebounce(debounceKey),
      ...options(callOptions),
    }),
    ...config,
  });

  return compose(
    customGraphqlHoc,
    ...(noLoader ? [] : [withLoader(props => props.loading)]),
    // $ExpectError
    branch(x => x.notFound, renderComponent(NotFoundPage)),
    ...(noEmpty ? [] : [withEmpty()])
  );
}

export default withQuery;
