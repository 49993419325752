// @flow
import {mmToFeetInch, mmToInchFractions} from './converters';

export const formatMmToFeetInch = (mm: ?number): ?string => {
  // including zero intentionally here
  if (!mm) return undefined;

  const x = mmToFeetInch(mm);

  // inches only
  if (x.feet === 0) return `${x.inches}″`;

  // feet only
  if (x.inches === 0) return `${x.feet}′`;

  return `${x.feet}′ ${x.inches}″`;
};

export const formatMmToInchFractions = (mm: ?number): ?string => {
  if (!mm) return undefined;

  const {integer, numerator, denominator} = mmToInchFractions(mm);

  // no fraction
  if (numerator === 0 || denominator === 0) return `${integer}″`;

  // only fraction
  if (integer === 0) return `${numerator}/${denominator}″`;

  // integer and fraction
  return `${integer} ${numerator}/${denominator}″`;
};

export const formatMm = (mm: ?number): ?string => {
  if (!mm) return undefined;

  return `${mm} mm`;
};

export const formatMmToCm = (mm: ?number): ?string => {
  if (!mm) return undefined;
  const rounded = Math.round((mm / 10) * 100) / 100;

  return `${rounded} cm`;
};
