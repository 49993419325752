// @flow
import Button from 'components/Button';
import {ButtonLabel} from 'components/Button/styled';
import {DeliveryBody} from 'pages/Booking/BookingPreview/DeliveryDetail/styled';
import styled from 'styled-components';

export const DesktopWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100rem;
  margin: 3rem 2rem;
`;

export const MobileWrap = styled(DesktopWrap)`
  margin: 3rem 0;

  ${DeliveryBody} {
    grid-template-columns: 1fr;
  }
`;

export const StyledPackingSlipButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-end;

  ${ButtonLabel} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }
`;
